/* eslint no-shadow: 0 */
import React from 'react';
import { string, shape, arrayOf, bool } from 'prop-types';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import _map from 'lodash/map';
import cx from 'classnames';
import { parseSelectedBy, parseGroupSelectedBy } from 'utils';
import { DEFAULT_CAROUSEL_CLICK_ANALYTICS, CAROUSEL } from 'dictionary';
import TextLoader from '../TextLoader';
import ModalTrigger from '../ModalTrigger';
import GroupComponent from '../GroupComponents/GroupComponent';
import {
  WEB_CONFIGURATOR_EAP_FEATURE_CARD, WEB_CONFIGURATOR_FSD_FEATURE_CARD,
  WEB_CONFIGURATOR_INTERIOR_FEATURE_CARD,
  WEB_CONFIGURATOR_TRIM_FEATURE_CARD
} from "../../common/gioStatistics";

/* Call to Action component */
const ActionTarget = props => {
  const {
    field,
    classes,
    parentClasses,
    group,
    parentGroupCode,
    fields,
    options,
    isLayoutMobile,
    isLayoutTablet,
    style,
    selectedOption,
    hideActionField,
  } = props;

  let fieldSource = group;
  if (selectedOption) {
    // grab the source from selected option
    if (!group && !group.currentSelected && !group.currentSelected.length) {
      return null;
    }
    const { currentSelected = [] } = group;
    if (!currentSelected.length) {
      return null;
    }
    fieldSource = currentSelected[0];
  }
  const getGioEventName = code => {
    switch (code) {
      case 'TRIM':
        return WEB_CONFIGURATOR_TRIM_FEATURE_CARD;
      case 'PACKAGES':
        return WEB_CONFIGURATOR_INTERIOR_FEATURE_CARD;
      case '$APPB':
        return WEB_CONFIGURATOR_EAP_FEATURE_CARD;
      case '$APF2':
        return WEB_CONFIGURATOR_FSD_FEATURE_CARD;
      default:
        return undefined;
    }
  };
  const triggerCallToAction = (fieldProps, fieldKey, fieldSource) => {
    const { field, selected_by, optionCodes, dataId = '' } = fieldProps;
    const rn = _get(window.tesla, 'rn', null);
    const isEditDesign = !!rn;
    const { code, name = '', formattedPrice = '', formattedSelected = '' } = fieldSource;
    const modalName = _get(fieldProps, 'props.modal', null);
    const isEnabledForEditDesign = _get(fieldProps, 'props.isEnabledForEditDesign', true);
    const selectedView = _get(fieldProps, 'props.selectedView', null);
    const modalClasses = _get(fieldProps, 'props.modalClasses', '');
    const type = _get(fieldProps, 'props.type', CAROUSEL);
    let isSelected = false;
    let carouselTriggerText;

    if (isEditDesign && !isEnabledForEditDesign) {
      return null;
    }
    if (modalName) {
      carouselTriggerText = field ? _get(fieldSource, field)?.content : [];
    }
    if (selected_by) {
      if (!parseSelectedBy(selected_by, options) || hideActionField.includes(code)) {
        return [];
      }

      // adding checks for selected button state
      if (selectedOption && optionCodes && optionCodes.includes(code)) {
        isSelected = true;
      }
      // If action target button is something that triggers option change,
      // and does not open a modal, then we shouldn't include ModalTrigger wrapper
      return [
        <div data-id={`${code}-add-button`} key={`TextLoader:${code}.${fieldKey}.${field}`}>
          <TextLoader
            data={fieldSource}
            tag={{
              component: 'button',
              props: { 'aria-label': `${formattedSelected} - ${name} - ${formattedPrice}` },
            }}
            field={field}
            className={cx(classes, {
              'tds-o-btn': selectedOption,
              'tds-btn--outline': isSelected,
            })}
          />
        </div>,
      ];
    }

    if (fieldProps?.type === 'components') {
      return [
        <GroupComponent
          group={group}
          components={fieldProps?.content}
          classes="child-group--section tds-text--center"
          key={`childGroup:${code}.${fieldKey}`}
        />,
      ];
    }
    // Action Target which will open a modal (simple or carousel)
    return [
      <ModalTrigger
        options={{ props }}
        type={type}
        groupName={modalName}
        selectedView={selectedView}
        analyticsTriggerText={_get(
          fieldProps,
          'props.interaction',
          DEFAULT_CAROUSEL_CLICK_ANALYTICS
        )}
        carouselTriggerText={carouselTriggerText}
        key={`ModalTrigger:${code}.${fieldKey}.${field}`}
        modalClasses={modalClasses}
        data-gio-eventname={getGioEventName(code)}
      >
        <div data-id={dataId || `${code}-learn-more-button`}>
          <TextLoader
            key={`TextLoader:${code}.${fieldKey}.${field}`}
            data={fieldSource}
            tag={{ overrideComponent: 'span', props: {} }}
            field={field}
            className={classes}
          />
        </div>
      </ModalTrigger>,
    ];
  };
  const fieldKey = fields.length ? _map(fields, 'field').join(':') : field;
  const mappedActions = fields.flatMap(elem => triggerCallToAction(elem, fieldKey, fieldSource));
  const hasMultipleFields = fields.length > 1 && mappedActions.length > 1;
  const isJustifyCenter = classes?.includes('tds-flex--justify-center');
  const { code } = fieldSource;
  return (
    <div
      key={`ActionTarget.${parentGroupCode}.${code}:${fieldKey}`}
      className={cx(
        'tds-flex',
        {
          'tds--vertical_padding--large': !selectedOption,
        },
        {
          'tds-flex--justify-center': isJustifyCenter || (!hasMultipleFields || isLayoutTablet),
        },
        {
          'tds-flex--space-between': !isJustifyCenter && (hasMultipleFields && !isLayoutTablet),
        },
        {
          'cf-action-target__tablet': isLayoutTablet,
        },
        {
          'cf-action-target__mobile': isLayoutMobile,
        },
        `cf-action-target${parentClasses ? ` ${parentClasses}` : ''}`
      )}
      style={style}
    >
      {mappedActions}
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  const { Configuration, App, ReviewDetails, CustomGroups } = state;
  const { content = [] } = ownProps;
  const { isLayoutMobile, isLayoutTablet } = App;
  const { option_codes: options = [] } = Configuration;
  const { hideActionField = [] } = ReviewDetails;
  const selectedGroup =
    content.length &&
    content.reduce((res, obj) => {
      if (parseGroupSelectedBy(obj?.group_selected_by, CustomGroups)) {
        return obj;
      }
      return res;
    }, {});
  return {
    options,
    isLayoutMobile,
    isLayoutTablet,
    hideActionField,
    ...selectedGroup,
  };
}

ActionTarget.propTypes = {
  group: shape({
    code: string,
  }).isRequired,
  field: string,
  classes: string,
  fields: arrayOf(shape({})),
  options: arrayOf(string),
  isLayoutMobile: bool.isRequired,
  isLayoutTablet: bool.isRequired,
  parentGroupCode: string,
  parentClasses: string,
  style: shape({}),
  selectedOption: bool,
  hideActionField: arrayOf(string),
};

ActionTarget.defaultProps = {
  field: 'name',
  classes: '',
  fields: [],
  options: [],
  parentGroupCode: '',
  parentClasses: '',
  style: {},
  selectedOption: false,
  hideActionField: [],
};

export default connect(mapStateToProps)(ActionTarget);
