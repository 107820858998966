import { iconChevronSmall, iconChevronSmall180, } from '@tesla/design-system-icons';
import { Heading, Icon } from '@tesla/design-system-react';
import classnames from 'classnames';
import { bool } from 'prop-types';
import {
  PAYMENT_FIN_TABS
} from 'dictionary';
import React, { useState } from 'react';
import { Collapse } from 'react-collapse';
import {
  i18n,
  ScriptWrapper,
} from 'utils';
import PaymentOverview from '../../containers/PaymentOverview/index.js';
import DepositAmountTable from '../../containers/PaymentOverview/Sections/DepositAmountTable.js';
import SaveDesignLink from '../../containers/PaymentOverview/Sections/SaveDesignLink.js';
import DeliveryDate from '../GroupComponents/DeliveryDate.js';
import FinanceToggle from '../GroupComponents/FinanceToggle.js';
import Alerts from './Sections/Alerts';
import FeesAndDiscounts from './Sections/FeesAndDiscounts.js';
import FinanceTabs from './Sections/FinanceTabs.js';
import Incentives from './Sections/Incentives.js';
import PurchasePrice from './Sections/PurchasePrice.js';
import VehiclePricePlusOptions from './Sections/VehiclePricePlusOptions.js';
import PrintPdfButton from '../SaveDesign/PrintPdfButton.js';
import DeliveryZip from './Sections/DeliveryZip.js';
import CyberGraffiti from './Sections/CyberGraffiti.js';

const PaymentSummaryRepresentation = ({
  isDm,
  enableCyberpunk,
  isMobile,
  isDeliverySelectionEnabled,
  modelName,
  showPaymentOverview,
  ggConf,
  loadGoogleScript,
  showFinanceToggle,
  isSaveDesignLiteEnabled,
  isDownloadPDFEnabled,
  canModifyOrder,
  isEarlyAvailabilityEnabled,
  reviewRefactor,
  estPurchasePrice
}) => {

  if (!showPaymentOverview) {
    return null;
  }

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const pricingSummaryTitle = isDeliverySelectionEnabled
  ? i18n('common.orderYourModel', { MODEL: modelName})
  : i18n('Review.model_label', { MODEL: modelName});

  return (
    <>
    <If condition={loadGoogleScript}>
      <ScriptWrapper {...ggConf} />
    </If>
    <div
      className={classnames('cf-content_container tds-content_container',
        {
          'option-widget--container': isMobile,
          'tds-theme--dk': isDm,
          'tds--horizontal_padding': !enableCyberpunk,
          'tds--no_padding': enableCyberpunk,
          // [classes]: classes
        }
     )}
    >
      <Choose>
          <When condition={isEarlyAvailabilityEnabled}>
            <DeliveryZip />
          </When>
          <Otherwise>
            <Choose>
              <When condition={enableCyberpunk}>
                <CyberGraffiti />
              </When>
              <Otherwise>
                <Heading is="h3" className="cf-h2-title tds-text--center cf-payment-overview__title">
                  {pricingSummaryTitle}
                </Heading>
              </Otherwise>
            </Choose>

            <Choose>
              <When condition={isSaveDesignLiteEnabled}>
                <SaveDesignLink />
              </When>
              <Otherwise>
                <If condition={!canModifyOrder && isDownloadPDFEnabled}>
                  <div className="tds-text--center">
                    <PrintPdfButton label={i18n('share.downloadPDF')} />
                  </div>
                </If>
              </Otherwise>
            </Choose>

            <DeliveryDate
              group="PAYMENT"
              classes="tds-text--regular tds-o-margin-top"
              isCentered={true}
            />
          </Otherwise>
        </Choose>
      <div className="pricing-container tds-o-padding_bottom-24 tds-o-vertical_padding-top--1x">
        <div className="price-block">
            <Collapse isOpened={isOpen}>
              <div className="tds--vertical_padding-bottom--1x">
                <VehiclePricePlusOptions />
                <FeesAndDiscounts />
                <If condition={!reviewRefactor}>
                  <PurchasePrice estPurchasePrice={estPurchasePrice} />
                </If>
                <Incentives estPurchasePrice={estPurchasePrice} />
              </div>
            </Collapse>
          <div className='collapse-container tds-flex tds-flex--center tds-flex--align-inline-center tds-flex-gap--8 tds-o-flex-direction--row'>
            <button
              className='tds-flex--center tds-flex--align-inline-center tds-flex-gap--4 tds-o-flex-direction--row'
              onClick={handleToggle}
            >
              <span className="tds-text--medium tds-text--contrast-medium">
                {i18n(isOpen ? 'common.hidePricingDetails' : 'common.showPricingDetails')}
              </span>
              <Icon data={isOpen ? iconChevronSmall : iconChevronSmall180} />
            </button>
          </div>
        </div>
      </div>

    <If condition={showFinanceToggle}>
      <FinanceToggle
        tabsSource={PAYMENT_FIN_TABS}
        label={i18n('common.Select_Finance_Solutions')}
        className="tds--vertical_padding-bottom"
      />
    </If>

      <Alerts/>
      <FinanceTabs />
      <DepositAmountTable prepayment />

      <PaymentOverview showOnlyPayment={true} />
    </div>
    </>
  )
};

PaymentSummaryRepresentation.prototype = {
  reviewRefactor: bool,
};

PaymentSummaryRepresentation.defaultProps = {
  reviewRefactor: false,
};

export default PaymentSummaryRepresentation;
