import React from 'react';
import { object, string, oneOfType, bool, number } from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import {
  getMiniDisclaimer,
  getSelectedFinanceProduct,
  getDownPayment,
  getFinanceProductId,
} from 'selectors';
import {
  SHOW_FINANCIAL_MODAL_STANDALONE,
  FINANCE_CAROUSEL,
  FINANCE_MODAL_TAB_OPTIONS,
  FINANCE_CONTAINER,
  FinanceTypes,
  PURCHASE_TAX_SAVING_THRESHOLD,
} from 'dictionary';
import ModalTrigger from '../ModalTrigger';
import _get from 'lodash/get';
import { i18n, htmlToReact } from 'utils';
import { formatCurrency } from '@tesla/coin-common-components';
import LegalBanner from '../LegalBanner';

const FinanceDisclaimer = ({
  financeDisclaimer,
  prepend = null,
  className = 'tds-o--vertical_margin--small',
  isCoinReloaded,
  reviewRefactor,
  financeType,
  productId,
  countryCode,
  purchaseTaxAmount,
  pricing,
  legacyFooterDisclaimer,
  showLegacyFooterDisclaimers,
}) => {
  if (reviewRefactor) {
    return (
      <div className="tds--padding_top-16">
        <div className={classNames('tds-text--contrast-low finance-disclaimer', className)}>
          <div className="text-size--20 tds--vertical_padding-bottom--1x">
            {htmlToReact(
              i18n(
                'SummaryPanel.disclaimers.purchaseTaxPolicy__disclaimer_line1',
                {
                  CLASS_NAME: '',
                  PURCHASE_TAX: pricing.purchaseTax,
                  PURCHASE_TAX_DUE: formatCurrency(
                    purchaseTaxAmount > PURCHASE_TAX_SAVING_THRESHOLD
                      ? purchaseTaxAmount - PURCHASE_TAX_SAVING_THRESHOLD
                      : 0
                  ),
                },
                null,
                { returnNullWhenEmpty: true }
              ) || ''
            )}
          </div>
        </div>
        <If condition={financeType === FinanceTypes.LEASE}>
          <div className="tds-text--contrast-low tds-text--caption">
            {htmlToReact(i18n('FinancingOptions.lease__disclaimer'))}
          </div>
        </If>
        <If condition={financeType === FinanceTypes.LOAN}>
          <div className="tds-text--contrast-low tds-text--caption">
            {htmlToReact(i18n('FinancingOptions.loan__disclaimer'))}
          </div>
        </If>
      </div>
    );
  }

  if (showLegacyFooterDisclaimers) {
    return legacyFooterDisclaimer ? (
      <div className={classNames('tds-text--contrast-low finance-disclaimer', className)}>
        {htmlToReact(legacyFooterDisclaimer)}
      </div>
    ) : null;
  }

  if ([prepend, financeDisclaimer].filter(Boolean).length === 0 || countryCode === 'CN') {
    return null;
  }

  return (
    <Choose>
      <When condition={isCoinReloaded}>
        <LegalBanner financeId={productId} />
        <div className={classNames('tds-text--contrast-low finance-disclaimer', className)}>
          {prepend}
          {financeDisclaimer && prepend && <br />}
          {financeDisclaimer}
        </div>
      </When>
      <Otherwise>
        <div className="tds--padding_top-16">
          <ModalTrigger
            type={FINANCE_CAROUSEL}
            selectedView={FINANCE_MODAL_TAB_OPTIONS}
            options={{
              props: {
                componentName: FINANCE_CONTAINER,
              },
            }}
          >
            <span className="tds-link">{financeDisclaimer}</span>
          </ModalTrigger>
        </div>
      </Otherwise>
    </Choose>
  );
};

const mapStateToProps = (state, ownProps) => {
  const productId = getFinanceProductId(state);

  return {
    legacyFooterDisclaimer: i18n(
      `SummaryPanel.footerDisclaimer__${productId}`,
      { DOWN_PAYMENT: formatCurrency(getDownPayment(state, { context: 'footer' })) },
      null,
      { returnNullWhenEmpty: true }
    ),
    productId: getSelectedFinanceProduct(state),
    financeDisclaimer: getMiniDisclaimer(state, {
      variables: {
        TRIGGER: SHOW_FINANCIAL_MODAL_STANDALONE,
      },
    }),
    isCoinReloaded: state?.App?.isCoinReloaded,
    reviewRefactor: ownProps?.reviewRefactor,
    financeType: ownProps?.financeType,
    countryCode: _get(state, 'OMS.oms_params.market'),
    purchaseTaxAmount: _get(state, 'Financial.fees.current.purchase_tax[0].purchaseTaxAmount', 0),
    pricing: _get(state, 'SummaryPanel.formattedPrices'),
  };
};

FinanceDisclaimer.propTypes = {
  financeDisclaimer: oneOfType([object, string]),
  isCoinReloaded: bool,
  reviewRefactor: bool,
  className: string,
  financeType: string,
  countryCode: string,
  purchaseTaxAmount: number,
  pricing: object,
};

FinanceDisclaimer.defaultProps = {
  financeDisclaimer: '',
  isCoinReloaded: false,
  reviewRefactor: false,
  className: '',
  financeType: '',
  countryCode: '',
  purchaseTaxAmount: 0,
  pricing: {},
  showLegacyFooterDisclaimers: false,
};

export default connect(mapStateToProps)(FinanceDisclaimer);
