import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import {
  string,
  number,
  func,
  object as objectType,
  bool,
  oneOfType,
  objectOf,
  shape,
  arrayOf,
} from 'prop-types';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { isEmail } from '@tesla/coin-common-components';
import {
  i18n,
  firstLastNameValidator,
  getZipWithExtension,
  isPaymentAccepted,
  validateShippingAddress,
  getTaxData,
  determineEnvironment,
} from 'utils';
import {
  PAYMENT_TYPE_REDIRECT,
  NAME_ON_CARD,
  CREDIT_CARD,
  EXPIRATION_MONTH,
  EXPIRATION_YEAR,
  CVV,
  BILLING_ZIPCODE,
  FIELD_COUNTRY_CODE,
  FIELD_ADDRESS1,
  FIELD_ADDRESS2,
  FIELD_CITY,
  FIELD_STATE,
  FIELD_COUNTY,
  FIELD_COMPANY_ADDRESS_1,
  FIELD_COMPANY_ADDRESS_2,
  FIELD_COMPANY_CITY,
  FIELD_COMPANY_POSTAL_CODE,
  FIELD_COMPANY_STATE,
  FIELD_COMPANY_COUNTRY_CODE,
  FIELD_COMPANY_COUNTY,
  FIELD_EMAIL,
  PAYMENT_TYPE_APPLEPAY,
  //PAYMENT_TYPE_CRYPTO,
  PAYMENT_TYPE_WECHAT,
  PAYMENT_TYPE_ALIPAY,
  PAYMENT_TYPE_SMARTPOS,
  ENTITY_ID,
  ERROR_INVALID_SHIPPING_CONTACT,
  ERROR_INVALID_BILLING_ADDRESS,
  PAYX_BILLING_ADDRESS_FULL,
  REGISTRATION_TYPE_BUSINESS,
  UI_DATA_IDS,
  PAYX_PAYMENT_METHOD_MAPPING,
  ERROR_INVALID_SHIPPING_ADDRESS,
} from 'dictionary';
import { validatePhone } from '@tesla/intl-phone';
import { Payment } from '@tesla/payment-react';
import { Button } from '@tesla/design-system-react';
import Alert from '../Alert/lazy';
import Analytics from 'analytics';
import AccountSection from '../../containers/PaymentOverview/Sections/AccountSection';
import DisclaimerSection from '../../containers/PaymentOverview/Sections/DisclaimerSection';
import PaymentFinishComponent from '../../components/PaymentFinishComponent';
import { clearAndAddAlert } from '../../reducers';
import PartialSpinner from '../Loaders/PartialSpinner';
import { isEsignFlowEnabled } from '../../common/selectors';

const Payx = ({
  payment,
  locale,
  depositAmount,
  currencyCode,
  setValidFlag,
  redirectPaymentDetail,
  orderDisclaimerSource,
  enableCyberpunk,
  showVat,
  showOptinDisclaimer,
  orderButtonDisabled,
  ap_enabled,
  hasAlerts,
  updatePaymentDetails,
  updateBillingInfo,
  updateAccountDetails,
  onPlacePublicOrder,
  onPlaceOrder,
  onPlacePaymentSignOrder,
  setSummaryPanelRegion,
  useProvinceFromBillingInfo,
  onCompleteRedirectFlow,
  dispatchUserHasApplePay,
  clearServerError,
  billingInfo,
  stopLoader,
  updateInteractionRequired,
  market,
  invitedOrderButtonDisabled,
  isRnHolderFlow,
  isWeChatMiniEnv,
  isWeChatBrowserEnv,
  billingAddressDetailsPayX,
  isCryptoAllowed,
  isDm,
  userHasApplePay,
  accountType,
  accountDetail,
  isBusinessVerificationEnabled,
  routes,
  rn,
  hasAccessories,
  updateShipToAddress,
  taxUrl,
  taxPayload,
  updateTaxData,
  shipToAddress,
  isTaxInclusive,
  isNoPostalCodeMarket,
  isCountryWithStateCode,
  billingDistrict,
  billingProvince,
  orderAgreementUrl,
  paymentAmount,
  accessoriesAmount,
  isEarlyDesignSelected,
  totalSwapConfig,
  interactionTypeForSwap,
  isInventory,
  addCustomerAlert,
  isPayxPaymentOptionSelected,
  paymentTypes,
  isPaymentInProgress,
  skipPaymentInProgressCheck,
  isReservationToOrderFlow,
  isTaxApplicableForAccessories,
  pluginStyle,
  isEsignFlowEnabled,
  setPamentType,
}) => {
  const { token: paymentToken, returnUrl: redirectUrl } = redirectPaymentDetail || {};
  const { isPaymentInteractionRequired } = payment;

  const fireAnalytics = interaction => {
    Analytics.fireTagEvent({
      event: Analytics.event,
      'cfg-type': Analytics.cfgType,
      interaction,
      ...(totalSwapConfig ? { 'inventorySwap-configuration': totalSwapConfig } : {}),
    });
  };

  const [customForm, isCustomForm] = useState(true);
  const [hasTaxError, isTaxError] = useState(false);
  //const [displayDueAmount, updateDisplayDueAmount] = useState(true);
  const [paymentObj, setPaymentObj] = useState(null);
  const [currentToken, setCurrentToken] = useState(null);
  const [currentRedirectUrl, setCurrentRedirectUrl] = useState(null);
  const [showSubmitChangePaymentType, setShowSubmitChangePaymentType] = useState(false);
  const [isPayxUiLoading, setIsPayxUiLoading] = useState(true);
  const [isCurrentOrderExpired, setCurrentOrderExpired] = useState(false);
  const { PaymentDetail = {} } = payment;
  const { PayorName = '', BillingInfoDetail = {} } = PaymentDetail;
  const { EntityId = 0 } = BillingInfoDetail;
  const [applePayEnabled, setApplePayEnabled] = useState(false);
  const [isUiReady, setIsUiReady] = useState(false);
  const [isBusinessOrder, setIsBusinessOrder] = useState(false);

  const BillingAddress = _get(window.tesla, 'reviewDetails.BillingAddress', {});
  let billingAddressCheckbox =
    market !== 'TH' && // COIN-9762: disable until TH payx side is fixed
    Object.keys(BillingAddress).length === 0 && // no billing address from backend
    isBusinessVerificationEnabled && // market has company search
    accountType === REGISTRATION_TYPE_BUSINESS // account type is business
      ? i18n('CompanySearch.same_address')
      : undefined;

  if (Object.keys(shipToAddress).length > 0) {
    billingAddressCheckbox = i18n('Accessories.same_address');
  }

  const getDefaultValues = () => {
    const values = {
      [NAME_ON_CARD]: String(_get(payment, 'PaymentDetail.PayorName', '') || ''),
      [CREDIT_CARD]: payment.PaymentDetail.CreditCardDetail.AccountNumber,
      [EXPIRATION_MONTH]: payment.PaymentDetail.CreditCardDetail.ExpirationMonth,
      [EXPIRATION_YEAR]: payment.PaymentDetail.CreditCardDetail.ExpirationYear,
      [CVV]: payment.PaymentDetail.CreditCardDetail.VerificationNumber,
      [BILLING_ZIPCODE]:
        _get(billingInfo, 'ZipCode', '') || payment.PaymentDetail.BillingZipCode || null,
      [FIELD_COUNTRY_CODE]: String(
        _get(billingInfo, 'CountryCode', '') || _get(payment, 'PaymentDetail.CountryCode', '')
      ),
      [FIELD_STATE]: String(_get(billingInfo, 'StateProvince', '') || ''),
      [FIELD_CITY]: String(_get(billingInfo, 'City', '') || ''),
      [FIELD_COUNTY]: String(_get(billingInfo, 'County', '') || ''),
      [FIELD_ADDRESS1]: String(_get(billingInfo, 'Street', '') || ''),
      [FIELD_ADDRESS2]: String(_get(billingInfo, 'Address2', '') || ''),
      [ENTITY_ID]: String(EntityId || 2062),
    };
    return values;
  };

  const [defaultValues, updateDefaultValues] = useState(getDefaultValues());

  const startPayment = (paymentToken, redirectUrl) => {
    if (paymentObj.start !== null) {
      paymentObj.start(paymentToken, { redirectUrl });
    }
  };

  const createOrder = () => {
    const { data = {} } = paymentObj;
    const { billingAddress = {}, payment: paymentData = {}, vat, shippingAddress } = data;
    const { type: paymentType, nameOnTheInstrument = '' } = paymentData;
    let { address2, street, city, countryCode, zipCode, stateProvince, county } = billingAddress;
    let billingInfoObject = {
      Address2: address2,
      City: city,
      CompanyName: vat && vat.companyName,
      CountryCode: countryCode,
      IsFromSavedProfile: false,
      Street: street,
      VatId: vat && vat.id,
      ZipCode: zipCode,
      StateProvince: stateProvince,
      County: county,
    };
    let payorName = nameOnTheInstrument;
    let normalizedShippingAddress = null;
    if (hasTaxError) {
      return paymentObj.cancel(ERROR_INVALID_SHIPPING_ADDRESS);
    }
    if (paymentType === PAYMENT_TYPE_APPLEPAY) {
      normalizedShippingAddress = shippingAddress
        ? validateShippingAddress({
            address: shippingAddress,
            countryCode: market,
            isNoPostalCodeMarket,
            isCountryWithStateCode,
          })
        : null;
      const { error: shippingAddressError } = normalizedShippingAddress || {};
      if (shippingAddressError) {
        return paymentObj.cancel(shippingAddressError);
      }
      // Process one touch
      const { account = {} } = data;
      const {
        firstName: accountFirstName,
        lastName: accountLastName,
        phone: accountPhone,
        email: accountEmail,
      } = account;

      // Validate first / last name
      if (
        !isReservationToOrderFlow &&
        (!firstLastNameValidator(accountFirstName) || !firstLastNameValidator(accountLastName))
      ) {
        return paymentObj.cancel(ERROR_INVALID_SHIPPING_CONTACT);
      }
      const normalizedState = (stateProvince || '').toUpperCase();
      const normalizedCountryCode = (countryCode || '').toUpperCase();
      const normalizedPostalCodeParts = getZipWithExtension({
        postalCode: zipCode,
        countryCode: normalizedCountryCode,
      });
      const { postalCode: normalizedPostalCode } = normalizedPostalCodeParts;
      // Validate billing postal code / state
      if ((isCountryWithStateCode && !normalizedState) || !normalizedPostalCode) {
        return paymentObj.cancel(ERROR_INVALID_BILLING_ADDRESS);
      }
      const normalizedAccountPhoneNumber = accountPhone ? accountPhone.replace(/[ -]/g, '') : '';
      billingInfoObject = {
        ...billingInfoObject,
        ZipCode: normalizedPostalCode,
        StateProvince: normalizedState,
        CountryCode: normalizedCountryCode,
      };
      payorName = `${accountFirstName} ${accountLastName}`;
      if (!isReservationToOrderFlow) {
        updateAccountDetails({
          Email: accountEmail,
          FirstName: accountFirstName,
          LastName: accountLastName,
          PhoneNumber: normalizedAccountPhoneNumber,
          IsPhoneNumberValidated: validatePhone(
            normalizedAccountPhoneNumber,
            normalizedCountryCode
          ),
          ...(accountType ? { AccountType: accountType } : {}),
        });
      }
    }

    if (paymentType === PAYMENT_TYPE_ALIPAY || paymentType === PAYMENT_TYPE_WECHAT) {
      const { FirstName, LastName } = accountDetail;
      payorName = `${FirstName} ${LastName}`;
    }

    if (!_isEmpty(billingAddress)) {
      updateBillingInfo(billingInfoObject);
    }

    if (normalizedShippingAddress) {
      updateShipToAddress(normalizedShippingAddress);
    }

    if (!_isEmpty(stateProvince) && useProvinceFromBillingInfo) {
      setSummaryPanelRegion(stateProvince);
    }

    updatePaymentDetails({
      PaymentType: PAYMENT_TYPE_REDIRECT,
      RedirectPaymentName: paymentType,
      PayorName: payorName,
      IsOffline: true,
    });
    if (market === 'CN' && isPaymentInteractionRequired) {
      onPlacePaymentSignOrder(paymentObj); // Get payment token then start payment
    } else if (isRnHolderFlow) {
      return onPlaceOrder(paymentObj);
    } else {
      return onPlacePublicOrder(paymentObj); // Pass paymentObj to create order action
    }
  };

  // NON-redirect flow
  const cancelOfflineOrder = async params => {
    const { transactionNumber, errorStatusCode = null } = params;
    if (market === 'CN') {
      if (isInventory && isCurrentOrderExpired) {
        addCustomerAlert(
          i18n('common.errors__orderExpired', null, null, { returnNullWhenEmpty: true })
        );
        return null;
      }

      const isAccepted = await isPaymentAccepted({ rn, transactionNumber, routes });
      onCompleteRedirectFlow(!!isAccepted, transactionNumber);
      return null;
    }
    // if transactionNumber is empty, redirect to success
    if ([408, 504].includes(errorStatusCode) && paymentToken) {
      onCompleteRedirectFlow(true, transactionNumber);
    } else {
      // Check payment status against core for inventory re-listing
      const isAccepted = await isPaymentAccepted({ rn, transactionNumber, routes });
      if (isAccepted) {
        onCompleteRedirectFlow(true, transactionNumber);
      } else {
        onCompleteRedirectFlow(false, transactionNumber);
      }
    }
  };

  const completeOfflineOrder = params => {
    const { transactionNumber } = params;
    onCompleteRedirectFlow(true, transactionNumber);
  };

  /**
   * Triggered when the selected payment method changes.
   */
  const onPaymentTypeSelected = (data) => {
    const { paymentType } = data || {};
    setPamentType(paymentType);
    isPayxPaymentOptionSelected(true);
  };

  /**
   * Triggered when payment readiness changes.
   * @param payment Defined if the payment is ready to be submitted. Null
   * if the payment is not ready to be submitted. This condition should
   * be used for enabling or disabling a 'Pay' or 'Submit' button.
   */
  const onPaymentTypeChanged = payment => {
    if (!payment) {
      setValidFlag(false);
      return;
    }
    const { data = {} } = payment;
    const { type: paymentType } = data;
    setValidFlag(true);
    setPaymentObj(payment);
    if (paymentType === PAYMENT_TYPE_APPLEPAY) {
      isCustomForm(false);
    } else {
      isCustomForm(market !== 'CN');
    }
    clearServerError();
    //const isCrypto = paymentType === PAYMENT_TYPE_CRYPTO;
    //updateDisplayDueAmount(!isCrypto);

    // Accessibility hackey solution
    const orderButton = document.querySelector(
      '.payment-type--container .payment-options > button'
    );
    if (orderButton) {
      orderButton.tabIndex = -1;
    }
    if (interactionTypeForSwap) {
      const value = isEarlyDesignSelected ? 'inventory' : 'custom';
      fireAnalytics(`${interactionTypeForSwap}:continue-to-payment-${value}`);
    }
    if (market === 'CN' && isPaymentInteractionRequired) {
      setShowSubmitChangePaymentType(true);
    }
  };

  useEffect(() => {
    if (market === 'CN' && !isInventory) {
      isPayxPaymentOptionSelected(false);
      setIsPayxUiLoading(true);
    }
  }, [accountType]);


  useEffect(() => {
    if (isUiReady && ap_enabled) {
      setIsBusinessOrder(accountType === 'business');
    }
  }, [accountType, isUiReady]);

  /* Start payment when we get token for CN flow only */
  useEffect(() => {
    if (paymentToken) {
      if (paymentObj.start !== null) {
        if (isEsignFlowEnabled) {
          paymentObj.start(paymentToken, { redirectUrl: redirectUrl });
          return;
        }
        const paymentType = paymentObj?.data?.payment?.type ?? null;
        if (
          paymentType === PAYMENT_TYPE_WECHAT ||
          paymentType === PAYMENT_TYPE_ALIPAY ||
          paymentType === PAYMENT_TYPE_SMARTPOS
        ) {
          if (!currentToken) {
            setCurrentToken(paymentToken);
          }
          if (!currentRedirectUrl) {
            setCurrentRedirectUrl(redirectUrl);
          }
          stopLoader();
          updateInteractionRequired(true);
        }
      } else {
        // this is wire transfer, no transactionNumber available
        onCompleteRedirectFlow(true, null);
      }
    }
  }, [paymentToken, redirectUrl]);

  useEffect(() => {
    updateDefaultValues(getDefaultValues());
    if (market === 'CN') {
      isPayxPaymentOptionSelected(false);
      setIsPayxUiLoading(true);
    }
  }, [EntityId]);

  useEffect(() => {
    if (market === 'CN') {
      isCustomForm(false);
    }
  }, [market]);

  /* Create backend order */
  const validateAndPlaceOrder = async () => {
    if (payment == null) {
      return;
    }
    setShowSubmitChangePaymentType(false);
    if (isCurrentOrderExpired) {
      addCustomerAlert(
        i18n('common.errors__orderExpired', null, null, { returnNullWhenEmpty: true })
      );
      return;
    }
    if (isPaymentInProgress && !skipPaymentInProgressCheck) {
      return;
    }
    createOrder();
  };

  /* Apple pay etc.*/
  const onShouldStartPayment = validateAndPlaceOrder;

  const oneTouchShippingAddressSelected = async address => {
    const shippingAddress = validateShippingAddress({
      address,
      countryCode: market,
      isNoPostalCodeMarket,
      isCountryWithStateCode,
    });
    const { error, street1: address1, street2: address2 } = shippingAddress || {};
    let taxTotal = 0;
    isTaxError(false);
    if (error) {
      isTaxError(true);
    } else {
      updateShipToAddress(shippingAddress);
      if (!isTaxInclusive) {
        const taxData = await getTaxData(null, {
          taxUrl,
          taxPayload: {
            ...taxPayload,
            deliveryAddress: {
              ...shippingAddress,
              address1,
              address2,
            },
          },
          isTaxInclusive,
        });
        // TODO: clean up the function remove the tax update call here
        updateTaxData(taxData);
        const { taxAmount, signature } = taxData || {};
        if (!taxAmount && !signature) {
          isTaxError(true);
        } else {
          taxTotal = taxAmount;
        }
      }
    }
    // Return line items + tax data
    const amountTotal = isTaxInclusive
      ? paymentAmount
      : Number(parseFloat(depositAmount + accessoriesAmount + taxTotal).toFixed(2)) || 0;
    return {
      lineItems: isTaxInclusive
        ? []
        : [
            {
              label: i18n('common.salesTax'),
              amount: taxTotal,
            },
          ],
      newTotal: {
        label: i18n('SummaryPanel.total'),
        amount: amountTotal,
      },
    };
  };

  const onAvailablePaymentMethodsChanged = paymentTypes => {
    if (ap_enabled) {
      const hasOneTouch = paymentTypes?.some(({ isOneTouch }) => isOneTouch);
      dispatchUserHasApplePay(hasOneTouch);
      if (hasOneTouch) {
        setApplePayEnabled(true);
      }
    }
  };

  const onUiReady = () => {
    if (market === 'CN') {
      setIsPayxUiLoading(false);
    }
    setIsUiReady(true);
  };

  const onQrCodeExpired = async transactionNumber => {
    if (market !== 'CN') {
      return;
    }

    //for configurator scenario redirect to confirmation page when QrCode expired
    if (!isInventory) {
      const isAccepted = await isPaymentAccepted({ rn, transactionNumber, routes });
      onCompleteRedirectFlow(!!isAccepted, transactionNumber);
      return;
    }

    //for inventory scenario when the QR code expired means the order is expired
    setCurrentOrderExpired(true);
  };

  const environment = determineEnvironment();
  const rnLoaded = _get(window.tesla, 'rn', '') !== '';
  const vatType = showVat ? 'business' : 'none';

  const postProps = {
    orderAgreementUrl,
    orderDisclaimerSource,
    orderButtonDisabled,
    invitedOrderButtonDisabled,
    validateAndPlaceOrder,
    rnLoaded,
    market,
  };

  useEffect(() => {
    updateDefaultValues(getDefaultValues());
  }, [PayorName]);

  let paymentMethodObject = {};
  const paymentMethods = paymentTypes
    ?.map(paymentType => {
      return PAYX_PAYMENT_METHOD_MAPPING[paymentType] || undefined;
    })
    ?.filter(paymentType => paymentType);
  paymentMethods?.map(paymentMethod => {
    Object.assign(paymentMethodObject, {
      [paymentMethod]: {
        entityId: defaultValues[ENTITY_ID],
      },
    });
  });
  const payxKey = applePayEnabled && accessoriesAmount ? `PayxKey:${depositAmount}:${accessoriesAmount}` : 'PayxKeyStatic';
  const uiTheme = enableCyberpunk ? 'dark' : 'white';
  return (
    <div className="payment-type--container">
      <If condition={hasAlerts}>
        <div className="alerts-container">
          <Alert />
        </div>
      </If>
      <div
        className={classnames(market === 'CN' && isPayxUiLoading ? 'tds--is_visually_hidden' : '')}
      >
        <Payment
          defaultValues={{
            wireInstruction: {
              allowUserToCheckPaymentStatus: true,
            },
            qrCode: {
              allowUserToCheckPaymentStatus: true,
              enableRefresh: true,
            },
            billingAddressCheckbox,
            billingAddress: {
              city: billingAddressCheckbox
                ? shipToAddress?.city || accountDetail[FIELD_COMPANY_CITY] || billingDistrict
                : defaultValues[FIELD_CITY],
              countryCode: billingAddressCheckbox
                ? shipToAddress?.country ||
                  accountDetail[FIELD_COMPANY_COUNTRY_CODE] ||
                  defaultValues[FIELD_COUNTRY_CODE]
                : defaultValues[FIELD_COUNTRY_CODE],
              state: billingAddressCheckbox
                ? shipToAddress?.state || accountDetail[FIELD_COMPANY_STATE] || billingProvince
                : defaultValues[FIELD_STATE],
              street: billingAddressCheckbox
                ? shipToAddress?.street1 || accountDetail[FIELD_COMPANY_ADDRESS_1]
                : defaultValues[FIELD_ADDRESS1],
              street2: billingAddressCheckbox
                ? shipToAddress?.street2 || accountDetail[FIELD_COMPANY_ADDRESS_2]
                : defaultValues[FIELD_ADDRESS2],
              zipCode: billingAddressCheckbox
                ? shipToAddress?.postalCode || accountDetail[FIELD_COMPANY_POSTAL_CODE]
                : defaultValues[BILLING_ZIPCODE],
              county: billingAddressCheckbox ? accountDetail[FIELD_COMPANY_COUNTY] : defaultValues[FIELD_COUNTY],
              stateProvince: billingAddressCheckbox ? accountDetail[FIELD_COMPANY_STATE] : undefined,
            },
            billingContact: {
              email: isEmail(accountDetail[FIELD_EMAIL] || '')
                ? accountDetail[FIELD_EMAIL]
                : undefined,
            },
            creditCard: {
              cvv: defaultValues[CVV],
              expirationMonth: defaultValues[EXPIRATION_MONTH],
              expirationYear: defaultValues[EXPIRATION_YEAR],
              holderName: defaultValues[NAME_ON_CARD],
              number: defaultValues[CREDIT_CARD],
            },
            vat: {
              companyName: '',
              id: '',
              type: rnLoaded ? vatType : 'none',
            },
            ...paymentMethodObject,
            crypto: isCryptoAllowed ? {} : undefined,
            applePay: ap_enabled && !isBusinessOrder
              ? {
                  addBillingAddress: true,
                  addEmail: true,
                  addName: true,
                  addPhone: true,
                  ...(hasAccessories && isTaxApplicableForAccessories
                    ? { addShippingAddress: true }
                    : {}),
                }
              : undefined,
          }}
          paymentButtonTitle={market === 'CN' ? '' : 'order'}
          tdsVersion={pluginStyle}
          scrim={isDm && !enableCyberpunk ? 'black' : uiTheme}
          displayType="inline"
          overrideBillingAddress={billingAddressDetailsPayX || undefined}
          // eslint-disable-next-line jsx-control-statements/jsx-use-if-tag
          prePaymentFormComponent={customForm ? <AccountSection /> : undefined}
          // eslint-disable-next-line jsx-control-statements/jsx-use-if-tag
          postPaymentFormComponent={customForm ? <DisclaimerSection {...postProps} /> : undefined}
          openRedirectsIn={isDm ? 'newWindow' : undefined}
          onShippingAddressSelected={oneTouchShippingAddressSelected}
          environment={environment}
          locale={locale}
          paymentSource={payment.PaymentDetail.PaymentSource}
          paymentAmount={paymentAmount}
          paymentCurrencyCode={currencyCode}
          storeToProfile={showOptinDisclaimer}
          usage="pay"
          onPaymentCancel={cancelOfflineOrder}
          onPaymentComplete={completeOfflineOrder}
          onPaymentChange={onPaymentTypeChanged}
          onPaymentTypeSelected={onPaymentTypeSelected}
          onShouldStartPayment={onShouldStartPayment}
          onAvailablePaymentMethodsChanged={onAvailablePaymentMethodsChanged}
          onUiReady={onUiReady}
          onQrCodeExpired={onQrCodeExpired}
          key={payxKey}
        />
      </div>
      <If condition={market === 'CN'}>
        <PartialSpinner active={isPayxUiLoading} />
        <Choose>
          <When condition={isPaymentInteractionRequired}>
            <If condition={showSubmitChangePaymentType}>
              <Button
                variant="secondary"
                height="large"
                width="full"
                disabled={false}
                onClick={validateAndPlaceOrder}
                data-id={UI_DATA_IDS?.paymentPage?.placeOrderBtn}
                data-subtype="btn-order--primary"
                type="button"
                className="pay-x-change-payment-type"
              >
                {i18n('Review.btn_label__change_payment_type')}
              </Button>
            </If>
          </When>
          <Otherwise>
            <PaymentFinishComponent />
            <DisclaimerSection {...postProps} />
          </Otherwise>
        </Choose>
      </If>
    </div>
  );
};

Payx.defaultProps = {
  rn: '',
  redirectPaymentDetail: null,
  billingInfo: {},
  useProvinceFromBillingInfo: false,
  oldOrderAgreementVersion: '',
  enableCyberpunk: false,
  billingAddressDetailsPayX: PAYX_BILLING_ADDRESS_FULL,
  orderDisclaimerSource: '',
  isDm: false,
  routes: {},
  hasAccessories: false,
  countryCode: '',
  taxUrl: '',
  taxPayload: {},
  shipToAddress: null,
  isTaxInclusive: false,
  isNoPostalCodeMarket: false,
  isCountryWithStateCode: false,
  isInventory: false,
  orderAgreementUrl: '',
  billingDistrict: '',
  billingProvince: '',
  isWeChatMiniEnv: false,
  isWeChatBrowserEnv: false,
  addCustomerAlert: () => {},
  skipPaymentInProgressCheck: false,
  isReservationToOrderFlow: false,
};

Payx.propTypes = {
  currencyCode: string.isRequired,
  depositAmount: number.isRequired,
  payment: oneOfType([objectType, bool]).isRequired,
  locale: string.isRequired,
  sibling: string.isRequired,
  market: string.isRequired,
  model: oneOfType([objectType, string]).isRequired,
  modelCode: string.isRequired,
  orderDisclaimerSource: string,
  isPreSale: oneOfType([bool, string]).isRequired,
  updatePaymentDetails: func.isRequired,
  updateAccountDetails: func.isRequired,
  setValidFlag: func.isRequired,
  rn: string,
  onCompleteRedirectFlow: func.isRequired,
  updateBillingInfo: func.isRequired,
  onPlacePublicOrder: func.isRequired,
  onPlacePaymentSignOrder: func.isRequired,
  onPlaceOrder: func.isRequired,
  redirectPaymentDetail: objectOf(string),
  clearServerError: func.isRequired,
  billingInfo: objectOf(oneOfType([string, number, objectType, bool])),
  stopLoader: func.isRequired,
  updateInteractionRequired: func.isRequired,
  setSummaryPanelRegion: func.isRequired,
  useProvinceFromBillingInfo: bool,
  isOldVersionOrderAgreement: oneOfType([bool, string, shape({})]).isRequired,
  oldOrderAgreementVersion: string,
  showOptinDisclaimer: string.isRequired,
  enableCyberpunk: bool,
  showVat: bool.isRequired,
  orderButtonDisabled: bool.isRequired,
  dispatchUserHasApplePay: func.isRequired,
  ap_enabled: bool.isRequired,
  hasAlerts: bool.isRequired,
  invitedOrderButtonDisabled: bool.isRequired,
  validateAndPlaceOrder: func.isRequired,
  isRnHolderFlow: oneOfType([bool, string]).isRequired,
  billingAddressDetailsPayX: string,
  isCryptoAllowed: bool.isRequired,
  userHasApplePay: bool.isRequired,
  accountType: string.isRequired,
  accountDetail: shape({}),
  isBusinessVerificationEnabled: bool,
  isInventory: bool,
  isDm: bool,
  routes: shape({}),
  hasAccessories: bool,
  countryCode: string,
  IsPhoneNumberValidated: bool,
  updateShipToAddress: func.isRequired,
  taxUrl: string,
  taxPayload: shape({}),
  updateTaxData: func.isRequired,
  shipToAddress: shape({}),
  isTaxInclusive: bool,
  isNoPostalCodeMarket: bool,
  isCountryWithStateCode: bool,
  orderAgreementUrl: string,
  billingDistrict: string,
  billingProvince: string,
  paymentAmount: number.isRequired,
  accessoriesAmount: number,
  isWeChatMiniEnv: bool,
  isWeChatBrowserEnv: bool,
  addCustomerAlert: func,
  isPayxPaymentOptionSelected: func,
  paymentTypes: arrayOf(string),
  skipPaymentInProgressCheck: bool,
  isReservationToOrderFlow: bool,
};

const mapDispatchToProps = dispatch => {
  return {
    addCustomerAlert: message => {
      dispatch(clearAndAddAlert({ message }));
    },
  };
};

const mapStateToProps = state => {
  return {
    isEsignFlowEnabled: isEsignFlowEnabled(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Payx);
