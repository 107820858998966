import React from 'react';
import cx from 'classnames';
import { arrayOf, func, shape, bool, string } from 'prop-types';
import { connect } from 'react-redux';
import { StatusMessage, Loader } from '@tesla/design-system-react';
import { openAccessoriesModal } from 'actions';
import {
  REGISTRATION_TYPE_PRIVATE,
  REGISTRATION_TYPE_BUSINESS,
  TYPE_RECOMMENDED,
} from 'dictionary';
import { i18n } from 'utils';
import {
  isEnterpriseOrder,
  isChargingConnectorIncluded,
  isUsedInventory,
  getAccessoriesListByGroup,
} from 'selectors';
import AccessoriesList from './AccessoriesList';

const AccessoriesGroup = ({
  list,
  openModal,
  isEnterprise,
  customDisclaimer,
  chargingConnectorIncluded,
  showHomeChargingDisclaimer,
  nonResident,
  alwaysShowChargingDisclaimer,
  listType,
  productType,
  isRecommended,
  isFetching,
  showAsset = false,
  isCoinReloaded,
  isAccessoriesEnabled,
  productGroup,
  enableCyberpunk,
}) => {
  const filteredList = list.filter(item => {
    if (item.productGroup === 'MOBILE_CONNECTOR' && chargingConnectorIncluded) {
      return false;
    }
    return true;
  });
  
  const accessoriesKeys = filteredList.map(item => item.name);
  if (!alwaysShowChargingDisclaimer && (!isAccessoriesEnabled || nonResident)) {
    return null;
  }
 
  const homeChargingDisclaimer = !isRecommended
    ? i18n(`Accessories.home_charging__disclaimer`, null, null, {
        returnNullWhenEmpty: true,
      })
    : null;

  return (
    <div
      className={cx('group--child-container option-widget--container', {
        'observer-placeholder': isFetching,
      })}
    >
      <Choose>
        <When condition={!!list?.length || customDisclaimer}>
          <h2
            className={cx(
              'tds-text--center text-loader--subtitle',
              { 'tds-text--h4': isCoinReloaded || isEnterprise },
              { 'tds--no_padding coin-group--title': isCoinReloaded }
            )}
          >
            {isRecommended
              ? i18n('Enterprise.orderSummary.accessories')
              : i18n('Accessories.category__home_charging')}
          </h2>
          <If
            condition={
              !isCoinReloaded &&
              showHomeChargingDisclaimer &&
              homeChargingDisclaimer &&
              !chargingConnectorIncluded
            }
          >
            <StatusMessage body={homeChargingDisclaimer} type="info" enclosed={false} />
          </If>
          <If condition={isCoinReloaded && !isRecommended}>
            <p className="tds-text--body tds--vertical_padding--small tds-text--center">
              {i18n('Accessories.charging.caption')}
            </p>
          </If>
          <AccessoriesList
            compact={isCoinReloaded && !isRecommended}
            listType={listType}
            productType={productType}
            showAsset={showAsset}
            chargingConnectorIncluded={chargingConnectorIncluded}
          />
          <If condition={customDisclaimer}>
            <If condition={alwaysShowChargingDisclaimer}>
              <StatusMessage body={customDisclaimer} type="info" enclosed={false} />
            </If>
            <If condition={!alwaysShowChargingDisclaimer}>
              <p className="tds-text--caption tds--vertical_padding tds-text--center">{customDisclaimer}</p>
            </If>
          </If>
          <If condition={!!list?.length}>
            <div className="tds-flex tds-flex--justify-center tds-o-flex-nowrap tds--vertical_padding--large">
              <button
                className={cx('text-loader--content tds-btn tds-btn--tertiary', {'tds-btn--medium': !enableCyberpunk})}
                onClick={() => openModal({ items: accessoriesKeys })}
              >
                {i18n('common.learn_more')}
              </button>
            </div>
          </If>
        </When>
        <Otherwise>
          <Loader show={isFetching} className="inline-loader" />
        </Otherwise>
      </Choose>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  const { ReviewDetails, Accessories } = state;
  const { isCoinReloaded = false, isAccessoriesEnabled = false, enableCyberpunk = false } = state?.App || {};
  const { group, listType = 'category', productType, showAsset } = ownProps;
  const { alwaysShowChargingDisclaimer, isFetching } = Accessories;
  const list = getAccessoriesListByGroup(state, { listType, productType });
  const isEnterprise = isEnterpriseOrder(state);
  const chargingConnectorIncluded = isChargingConnectorIncluded(state);
  const baseDisclaimer = alwaysShowChargingDisclaimer
    ? i18n('Accessories.charging_not_included_disclaimer')
    : '';
  const customDisclaimer = chargingConnectorIncluded
    ? i18n(
        `Accessories.${
          isUsedInventory(state)
            ? 'charging_adapter_included_disclaimer'
            : 'mobile_connector_included_disclaimer'
        }`
      )
    : baseDisclaimer;

  const { showHomeChargingDisclaimer, AccountDetail = {} } = ReviewDetails;
  const accountType = AccountDetail.AccountType;
  const nonResident =
    !!(
      accountType === REGISTRATION_TYPE_PRIVATE &&
      AccountDetail.NonResidentPerson &&
      AccountDetail.PrivateRegistrationCountry
    ) ||
    !!(
      accountType === REGISTRATION_TYPE_BUSINESS &&
      AccountDetail.NonResidentCompany &&
      AccountDetail.BusinessRegistrationCountry
    );
  const isRecommended = !!(listType === TYPE_RECOMMENDED);

  return {
    list,
    group,
    isEnterprise,
    customDisclaimer,
    chargingConnectorIncluded,
    showHomeChargingDisclaimer,
    nonResident,
    alwaysShowChargingDisclaimer: !isRecommended && alwaysShowChargingDisclaimer,
    listType,
    productType,
    isRecommended,
    isFetching,
    showAsset,
    isCoinReloaded,
    isAccessoriesEnabled,
    enableCyberpunk,
  };
}

const mapDispatchToProps = dispatch => ({
  openModal: x => dispatch(openAccessoriesModal(x)),
});

AccessoriesGroup.propTypes = {
  list: arrayOf(shape({})),
  openModal: func,
  isEnterprise: bool.isRequired,
  customDisclaimer: string,
  chargingConnectorIncluded: bool,
  showHomeChargingDisclaimer: bool,
  nonResident: bool,
  alwaysShowChargingDisclaimer: bool,
  isFetching: bool,
  isAccessoriesEnabled: bool,
  enableCyberpunk: bool,
};

AccessoriesGroup.defaultProps = {
  list: [],
  group: '',
  customDisclaimer: '',
  nonResident: false,
  alwaysShowChargingDisclaimer: false,
  isFetching: false,
  isAccessoriesEnabled: false,
  enableCyberpunk: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessoriesGroup);
