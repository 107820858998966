import _get from 'lodash/get';
import { request } from 'utils/requestAgent';
import _isString from 'lodash/isString';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';
import _omit from 'lodash/omit';
import _mapValues from 'lodash/mapValues';
import { Price } from '@web/tesla-rest-ds-services';
import { map, filter } from 'rxjs/operators';

import {
  PAYMENT_PROFILE_CHANGED,
  PAYMENT_DETAILS_CHANGED,
  PAYMENT_DETAILS_VALID_FLAG,
  PAYMENT_CANCEL_EDIT,
  PAYMENT_FORM_TOGGLE,
  PAYMENT_FORM_VALID_FLAG,
  PAYMENT_QRCODE_UPDATE,
  PAYMENT_QRCODE_TRANSACTION_STATUS_UPDATE,
  TRANSACTION_NUMBER_UPDATE,
  PAYMENT_INTERACTION_REQUIRED,
  BILLING_INFO_CHANGED,
  REDIRECT_PAYMENT_DETAILS_CHANGED,
  REDIRECT_PAYMENT_DETAILS_CLEARED,
  BILLING_INFO_VALID_FLAG,
  BILLING_LOCATION_CHANGED,
  LOADER_START,
  LOADER_FINISH,
  CLEAR_SERVER_ERRORS,
  INVOICE_TYPE_CHANGED,
  INVOICE_OPTIONS_VALID_FLAG,
  STATE_LIST,
  UPDATE_PICKUP_LOCATIONS,
  PICKUP_LOCATION_PROVINCE_CHANGED,
  PICKUP_LOCATION_CHANGE,
  BASE_CONFIGURATION_CHANGED,
  USED,
  NEW,
  UPDATE_ORDER_PAYMENT,
  PAYMENT_FORM_INSTRUMENT_TYPE,
  UPDATE_PAYMENT_OVERVIEW_FLAG,
} from 'dictionary';

import { addAlert, removeAlert, showBaseField, hideBaseField } from 'actions';
import { getTerritoryNameList } from 'utils';

import { locationsData } from '../../../common/locationsData';
import { ofType } from 'redux-observable';

/**
 * Flag to set if cc details are valid on Review Page
 * @param  {Boolean} flag
 */
export const togglePaymentForm = isOpen => ({
  type: PAYMENT_FORM_TOGGLE,
  isOpen,
});

export const cancelPaymentEdit = () => ({
  type: PAYMENT_CANCEL_EDIT,
});

export const clearServerError = () => ({
  type: CLEAR_SERVER_ERRORS,
});

/**
 * Update Payment Reducer with injected CC profile data
 * @param  {Object} storedProfile [StoredProfile data injected from call to MyT RetrieveProfile]
 * @return {Object}               [payment data to be changed]
 */
export const updateCreditCardDetails = creditCardDetail => ({
  type: PAYMENT_PROFILE_CHANGED,
  creditCardDetail,
});

/**
 * Flag to set if cc details are valid on Review Page
 * @param  {Boolean} flag
 */
export const setCCDetailsValidFlag = flag => ({
  type: PAYMENT_DETAILS_VALID_FLAG,
  flag,
});

/**
 * Flag to set if billing info is valid on Review Page
 * @param  {Boolean} flag
 */
export const setBillingInfoValidFlag = flag => ({
  type: BILLING_INFO_VALID_FLAG,
  flag,
});
export const setPaymentFormValidFlag = flag => ({
  type: PAYMENT_FORM_VALID_FLAG,
  flag,
});

export const setPaymentInstrumentType = paymentType => ({
  type: PAYMENT_FORM_INSTRUMENT_TYPE,
  paymentType,
});

export const updatePaymentDetails = paymentDetails => ({
  type: PAYMENT_DETAILS_CHANGED,
  paymentDetails,
});

export const updatePaymentQRcode = qrCode => ({
  type: PAYMENT_QRCODE_UPDATE,
  qrCode,
});

export const updateTransactionNumber = transactionNumber => ({
  type: TRANSACTION_NUMBER_UPDATE,
  transactionNumber,
});

export const updateQRcodePaymentTransactionStatus = status => ({
  type: PAYMENT_QRCODE_TRANSACTION_STATUS_UPDATE,
  status,
});

export const updatePaymentInteractionRequired = flag => ({
  type: PAYMENT_INTERACTION_REQUIRED,
  flag,
});

export const updateBillingInfo = billingInfo => ({
  type: BILLING_INFO_CHANGED,
  billingInfo,
});

export const setRedirectDetails = params => {
  const { returnUrl, token } = params;
  return {
    type: REDIRECT_PAYMENT_DETAILS_CHANGED,
    returnUrl,
    token,
  };
};

export const clearRedirectDetails = () => ({
  type: REDIRECT_PAYMENT_DETAILS_CLEARED,
});

export const syncPaymentDetails = (action$, state$) =>
  action$.pipe(
    filter(action => [PAYMENT_PROFILE_CHANGED].includes(action.type)),
    map(action => dispatch => {
      const state = state$.value;

      const { IsProfileExists, CardType, BillingZipCode } = _get(
        state,
        'Payment.CreditCardDetail',
        {}
      );

      const paymentDetails = {
        PayorName: '',
        ExpirationMonth: '',
        ExpirationYear: '',
        CardType,
        ProcessWithProfile: IsProfileExists,
        BillingZipCode,
      };

      dispatch({
        type: PAYMENT_DETAILS_CHANGED,
        paymentDetails,
      });

      // Saved credit cards can also hold billing info we need.
      const { collectAllBillingInfo } = state.Payment;
      const { BillingAddress } = action.creditCardDetail;
      if (collectAllBillingInfo) {
        // For markets that collect billing info, we check if the loaded details are valid.
        const billingFields = _pick(BillingAddress, [
          'CountryCode',
          'Street',
          'Address2',
          'City',
          'ZipCode',
          'StateProvince',
        ]);
        const validateFields = _omit(billingFields, 'Address2');

        let savedInfoValid = true;
        _mapValues(validateFields, billingValue => {
          if (!_isString(billingValue) || _isEmpty(billingValue)) {
            savedInfoValid = false;
          }
        });

        if (savedInfoValid) {
          billingFields.IsFromSavedProfile = true;
        }

        dispatch(updateBillingInfo(billingFields));
      }
    })
  );

export const updateLocationData = (type, payload) => ({
  type: BILLING_LOCATION_CHANGED,
  changedType: type,
  payload,
});

export const updatePickupLocationProvinceData = payload => ({
  type: PICKUP_LOCATION_PROVINCE_CHANGED,
  payload,
});

export const sendVerificationCode = () => (dispatch, getState) => {
  const state = getState();
  dispatch({ type: LOADER_START });
  const ccParams = {
    CallingApplicationName: 'WEBSITE_DEV',
    Metadata: {
      LocationCode: _get(state, 'Payment.PaymentDetail.BillingInfoDetail.PickupLocation', null),
    },
    User: {
      PhoneNumber: _get(state, 'Payment.PaymentDetail.VerificationPhone', null),
      IDType: 'CITIZEN_ID_NO',
      IDNumber: _get(state, 'Payment.PaymentDetail.PayorIDNumber', null),
      SmsChannel: '2',
    },
    payment: {
      PayorName: _get(state, 'Payment.PaymentDetail.PayorName', null),
      PaymentType: _get(state, 'Payment.PaymentDetail.CreditCardDetail.CardType', null),
      CountryCode: _get(state, 'Payment.CountryCode', null),
      CurrencyCode: _get(state, 'Payment.CurrencyCode', null),
      PaymentDetails: {
        AccountNumber: _get(state, 'Payment.PaymentDetail.CreditCardDetail.AccountNumber', null),
        ExpirationMonth: _get(
          state,
          'Payment.PaymentDetail.CreditCardDetail.ExpirationMonth',
          null
        ),
        ExpirationYear: _get(state, 'Payment.PaymentDetail.CreditCardDetail.ExpirationYear', null),
        LastFourDigits: _get(
          state,
          'Payment.PaymentDetail.CreditCardDetail.AccountNumber',
          ''
        ).slice(-4),
        VerificationNumber: _get(
          state,
          'Payment.PaymentDetail.CreditCardDetail.VerificationNumber',
          null
        ),
      },
    },
  };

  request
    .post('/configurator/api/v1/unionpay/get-sms')
    .send(ccParams)
    .set('X-Requested-With', 'XMLHttpRequest')
    .set('Accept', 'application/json')
    .end((err, response) => {
      if (err) {
        switch (err.status) {
          default:
            dispatch({ type: LOADER_FINISH });
            // default generic error
            dispatch(addAlert({ message: `${err.status}: ${response.body.error}` }));
            window.setTimeout(() => {
              dispatch(removeAlert({ message: `${err.status}: ${response.body.error}` }));
            }, 30000);
        }
      } else {
        dispatch({ type: LOADER_FINISH });
      }
    });
};

export const getInvoiceType = invoiceType => ({
  type: INVOICE_TYPE_CHANGED,
  invoiceType,
});

export const setInvoiceDetailsValidFlag = flag => ({
  type: INVOICE_OPTIONS_VALID_FLAG,
  flag,
});

export const pickupLocationChange = pickupLocationId => ({
  type: PICKUP_LOCATION_CHANGE,
  pickup_location_id: pickupLocationId,
});

export const checkBillingInfoStateList = action$ =>
  action$.pipe(
    filter(action => [BILLING_INFO_CHANGED].includes(action.type)),
    map(action => dispatch => {
      const countryCode = _get(action, 'billingInfo.CountryCode', null);
      if (!countryCode) return;

      const regions = getTerritoryNameList(countryCode);

      if (regions.length) {
        dispatch(showBaseField(STATE_LIST));
      } else {
        dispatch(hideBaseField(STATE_LIST));
      }
    })
  );

export function getPickupLocations() {
  return (dispatch, getState) => {
    const state = getState();
    const { App = {} } = state;
    const { countryCode, sibling, isBurstMode } = App;
    let locations = locationsData;
    if (isBurstMode) {
      dispatch({
        type: UPDATE_PICKUP_LOCATIONS,
        data: locations,
      });
    }
    request
      .get(`${sibling}/pickup-locations`)
      .timeout({
        response: 5000,
        deadline: 30000,
      })
      .query({ countrycode: countryCode })
      .end((error, response) => {
        if (!error && response && response.body.length > 1) {
          const { body = [] } = response;
          locations = body;
        }
        dispatch({
          type: UPDATE_PICKUP_LOCATIONS,
          data: locations,
        });
      });
  };
}

export function resetPickupLocationEntityId() {
  return (dispatch, getState) => {
    const state = getState();
    const trigger = _get(state, 'Payment.PaymentDetail.BillingInfoDetail.trigger', false);
    dispatch({
      type: BILLING_INFO_CHANGED,
      billingInfo: {
        PickupLocation: '',
        EntityId: 0,
        trigger: !trigger,
      },
    });
  };
}

export function pickupLocationEntityIdUpdate(pickupLocation) {
  return (dispatch, getState) => {
    const state = getState();
    const { App = {} } = state;
    const { sibling } = App;
    request
      .get(`${sibling}/configurator/api/v3/entity_id`)
      .timeout({
        response: 5000,
        deadline: 30000,
      })
      .query({ pickupLocation: pickupLocation })
      .end((error, response) => {
        if (!error && response && response.body) {
          const { body = [] } = response;
          const { entity_id = 0 } = body;
          (entity_id &&
            dispatch({
              type: BILLING_INFO_CHANGED,
              billingInfo: {
                PickupLocation: pickupLocation,
                EntityId: entity_id,
              },
            })) ||
            dispatch(resetPickupLocationEntityId());
        } else {
          dispatch(resetPickupLocationEntityId());
        }
      });
  };
}

export const pickupLocationChangeEpic = action$ =>
  action$.pipe(
    ofType(PICKUP_LOCATION_CHANGE),
    map(action => dispatch => {
      const pickupLocation = _get(action, 'pickup_location_id', null);
      if (!pickupLocation) return;
      dispatch(pickupLocationEntityIdUpdate(pickupLocation));
    })
  );

export const getOrderPayment = (action$, state$) =>
  action$.pipe(
    filter(action => [BASE_CONFIGURATION_CHANGED].includes(action.type)),
    map(action => async dispatch => {
      const state = state$.value;
      const { options } = action || {};
      const { App, Payment, OMS, ReviewDetails, SummaryPanel = {} } = state;
      const { isOrderFeeAPIEnabled, pricingContext: priceContext, isPreOrder = false } = App;
      const { isUsedInventory } = ReviewDetails?.product || {};
      const orderPayment = Payment?.orderPayment?.amount || 0;
      if (isOrderFeeAPIEnabled && !orderPayment) {
        const { region_code: regionCode } = SummaryPanel || {};
        const orderFee = Price.getOrderFeeDetails(
          { Lexicon: OMS?.lexicon },
          {
            options, priceContext,
            inventory: isUsedInventory ? USED : NEW,
            isPreorder: isPreOrder,
            regionCode,
          }
        );
        dispatch({ type: UPDATE_ORDER_PAYMENT, amount: orderFee?.price || 0 });
      }
      return;
    })
  );

export const setPaymentOverviewFlag = flag => ({
  type: UPDATE_PAYMENT_OVERVIEW_FLAG,
  flag,
});
