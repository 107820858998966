/* eslint no-case-declarations: 0 */
import { request } from 'utils/requestAgent';
import _get from 'lodash/get';
import _isFunction from 'lodash/isFunction';
import _isArray from 'lodash/isArray';
import _isEmpty from 'lodash/isEmpty';
import {
  i18n,
  constructUrl,
  addSubKeyToStorage,
  smoothScrollToError,
  wechatPay,
  parseSelectedBy,
  getInventoryLink,
  getOptionFamilyCode,
  getReferralCookie,
  getQueryParameters,
  isRefererFromGoog,
  getVerifyCookie,
  updateUriWithQueryParams,
} from 'utils';
import Analytics from 'analytics';
import { localizeUrl } from '@tesla/parse-locale';
import { Storage, isRN } from '@tesla/coin-common-components';
import { isGrayMarket } from '@tesla/markets-vehicle';
import Cookie from 'js-cookie';
import {
  openModal,
  addAlert,
  removeAlert,
  postMktConfig,
  postMktConfigWithPayment,
  postOrderWithPayment,
  postPaymentSignWithPayment,
  closeAllModals,
  cancelEditOrder,
  updatePaymentInteractionRequired,
  setRedirectDetails,
  clearRedirectDetails,
  setOptionExtended,
  openFinanceModal,
  deselectAccessories,
  setInvalidPickupLocation,
  navigationSelectKey,
  closeFeatureModal,
  storeUserConfig,
  selectFinanceView,
  selectTab,
} from 'actions';
import {
  LOADER_START,
  LOADER_FINISH,
  PLACE_ORDER_FLOW,
  PLACE_PUBLIC_ORDER_FLOW,
  PLACE_PAYMENT_SIGN_ORDER_FLOW,
  SAVE_CONFIG_FLOW,
  POST_ORDER_SUCCESS,
  POST_SAVE_CONFIG_SUCCESS,
  CANCEL_UPDATE_ORDER,
  POST_ORDER_FAIL,
  POST_PAYMENT_COMPLETE,
  PAYMENT_FAILED,
  MODAL_SMALL,
  MODAL_XSMALL,
  NAVIGATION_SELECT_KEY,
  NAVIGATION_SELECT_NEXT_KEY,
  NAVIGATION_MODALS_PASS,
  ADDRESS_UPDATE,
  NAVIGATION_SECTION_KEY_REGISTRATION,
  SESSION_TIME_OUT,
  CONFIG_SAVE_TIME_OUT,
  SESSION_TIME_OUT_MODAL,
  MODAL_FULLSCREEN,
  MODAL_MEDIUM,
  DASHBOARD_URI,
  LOCAL_STORAGE_KEY_REFERRAL,
  REFERRAL_RN_FLOW_REDIRECT,
  SHOW_SAVINGS_MODAL,
  SHOW_RANGE_MODAL,
  SHOW_REQUESTCALLBACK_MODAL,
  BATTERY_SAVINGS_MODAL,
  RANGE_INFO_MODAL,
  REQUEST_CALLBACK_MODAL,
  SHOW_INTERIOR_MODAL,
  SHOW_FEATURE_LIST_MODAL,
  RECOMMENDED_INFO_MODAL,
  FEATURE_LIST_MODAL,
  SHOW_ACCELERATION_MODAL,
  PROCESS_CONNECT,
  PAYMENT_PENDING,
  GEOIP_LOCATION_CHANGED,
  FIELD_REQUIRED,
  MODEL_FULL_NAME,
  SHOW_FINANCE_MODAL,
  SHOW_FINANCE_MODAL_CN,
  FINANCIAL_MODAL_CN,
  MODAL_LARGE,
  SHOW_BANNER_MODAL,
  BANNER_MODAL,
  SESSION_SET,
  TRIGGER_LOCATION_MODAL,
  TRIGGER_INSPECTION_MODAL,
  USER_LOCATION_MODAL,
  MODAL_REQUIRED,
  APPLE_PAY_VALIDITY,
  TRIGGER_CONFIGURATION_CHANGE,
  ERROR_WHILE_PROCESSING,
  ERROR_VIEW_ONLY,
  INVALID_CONFIG,
  INVALID_HOST_FOR_EMAIL,
  INVALID_FIRST_NAME,
  INVALID_LAST_NAME,
  INVALID_EMAIL,
  INVALID_PHONE_NUMBER,
  SHOW_INCENTIVES_MODAL,
  SHOW_INCENTIVES_SAVINGS_MODAL,
  UPDATE_CONFIG_BY_RULES,
  CLEAR_LOCAL_STORAGE,
  LOCAL_STORAGE_KEY,
  FINANCE_MODAL_TAB_OPTIONS,
  FINANCE_MODAL_TAB_INCENTIVES,
  FINANCE_MODAL_TAB_INCENTIVES_SAVINGS,
  SHOW_INSPECTION_MODAL,
  INSPECTION_MODAL_ANALYTICS,
  INSPECTION_MODAL,
  ACCESSORIES_ORDER_CREATE_ERROR,
  NAVIGATE_INVENTORY_LINK,
  ERROR_VIN_IS_NOT_FOUND,
  POST_PROCESS_SWAP,
  POST_PROCESS_SWAP_COMPLETE,
  POST_PROCESS_SWAP_FAIL,
  TEST_EDD_DATA,
  CITY_OUT_OF_STOCK,
  PICKUP_LOCATION_OUT_OF_STOCK,
  UPDATE_PICKUP_LOCATIONS_BY_AVAILABILITY,
  CONFIRM_CITY_OUT_OF_STOCK_MODAL,
  UPDATE_REFERRAL,
  REFERRAL_MODAL,
  VEHICLE_UNAVAILABLE_MODAL,
  START_SESSION,
  NAVIGATE_GET_UPDATES_LINK,
  SHOW_GAS_SAVINGS_MODAL,
  FINANCE_MODAL_TAB_GAS_SAVINGS,
  LOAD_RESERVATION_FLOW,
  UPDATE_ESIGN_STATUS,
  UPDATE_ESIGN_PENDING,
  ESIGN_STATUS_SENT,
  CHECK_AND_PROCESS_ESIGN_FLOW,
  QUERY_CURRENT_ESIGN_STATUS,
  ESIGN_FLOW_EXCEPTION_MODAL,
  ESIGN_STATUS_SIGNED,
  ESIGN_STATUS_TIMEOUT,
  NON_BRAND_NEW_CONSENT_PENDING_COOKIE_KEY,
  FINANCE_MODAL_TAB_TRADEIN,
  SET_FINANCE_MODAL_TAB_TRADEIN,
  SHOW_COMBINED_SAVINGS_MODAL,
  SHOW_COMBINED_SAVINGS,
  SHOW_FINANCIAL_MODAL_STANDALONE,
  FINANCIAL_MODAL_STANDALONE,
  UPDATE_VERIFICATION_STATUS,
  ACTIONS_POST_SESSION,
  SESSION_FAILED,
  MOBILE_APP_ACTION_NAV_PRODUCT_HOME_SCREEN,
  Models,
} from 'dictionary';
import {
  isReservationOrder,
  isInventory,
  getTrimCode,
  getEstimateDeliveryDate,
  isUsedInventory,
  getProcessEsignFlowPayload,
  getIsFalconDeliverySelectionEnabled,
} from 'selectors';
import { map, filter } from 'rxjs/operators';
import { setConfigurationRN } from '../../Configuration';
import configureStore from '../../../store/configureStore';
import { setTimeOfOrderPlacedSuccess } from '../../ReviewDetails';
import { isEsignFlowEnabled } from '../../../common/selectors';
export const triggerReferralDashboardFlow = () => (dispatch, getState) => {
  const state = getState();
  const version = _get(state, 'App.version', null);
  const referral = _get(state, 'ApplicationFlow.referral', {});
  const sibling = _get(state, 'App.sibling');
  const locale = _get(state, 'App.uiLocale');

  dispatch({ type: LOADER_START });

  if (!_isEmpty(referral)) {
    // Set referral to local storage
    addSubKeyToStorage(LOCAL_STORAGE_KEY_REFERRAL, referral, {
      expires: 1000 * 60 * 15,
      version,
    });
  }
  window.location = constructUrl(DASHBOARD_URI, sibling, locale);
};

const navigationModalPass = () => ({
  type: NAVIGATION_MODALS_PASS,
});

export const triggerRedirectWithReferral = () => ({
  type: REFERRAL_RN_FLOW_REDIRECT,
});

export const placeOrderFlow = (paymentObj = null) => ({
  type: PLACE_ORDER_FLOW,
  paymentObj,
});

export const placePublicOrderFlow = (paymentObj = null) => ({
  type: PLACE_PUBLIC_ORDER_FLOW,
  paymentObj,
});

export const placePaymentSignOrderFlow = (paymentObj = null) => ({
  type: PLACE_PAYMENT_SIGN_ORDER_FLOW,
  paymentObj,
});

export const completeRedirectOrderFlow = (isComplete, transactionNumber) => ({
  type: POST_PAYMENT_COMPLETE,
  isComplete,
  transactionNumber,
});

export const saveConfigFlow = (paymentObj = null) => ({
  type: SAVE_CONFIG_FLOW,
  paymentObj,
});

export const cancelUpdateOrder = (paymentObj = null) => ({
  type: CANCEL_UPDATE_ORDER,
  paymentObj,
});

export const updateESignStatus = (status, link = null) => ({
  type: UPDATE_ESIGN_STATUS,
  status,
  link,
});

export const checkAndProcessEsignFlow = (payload = {}) => ({
  type: CHECK_AND_PROCESS_ESIGN_FLOW,
  payload,
});

export const queryCurrentEsignStatus = (expectedStatus = '', isPooling = false) => ({
  type: QUERY_CURRENT_ESIGN_STATUS,
  expectedStatus,
  isPooling,
});
export const updateESignPending = pending => ({
  type: UPDATE_ESIGN_PENDING,
  pending,
});
export const updateVerificationStatus = statusObj => ({
  type: UPDATE_VERIFICATION_STATUS,
  data: statusObj,
});

export const showBatterySavingsModal = () => {
  const payload = {
    genericWrapper: true,
    header: {
      display: false,
    },
    size: MODAL_SMALL,
  };
  return openModal('BatterySavingsModal', payload);
};

export function showErrorModal(error = {}) {
  // default generic error
  return openModal('SuccessModal', {
    context: 'survey',
    surveyComplete: false,
    error: {
      title: i18n('Survey.errors__surveySaveErrorTitle'),
      message: i18n('Survey.errors__surveySaveErrorMessage'),
      ...error,
    },
  });
}

function redirectToProfilePage(state) {
  const { Configuration, App } = state;
  const {
    routes = {},
    isEnterpriseOrder,
    isEnterprisePurchaseOrder,
    locale,
    base_url: baseUrl,
    countryCode = '',
    isNewOrderProfileUrl = false,
  } = App;
  const {
    fulfillmentReferer = '',
    profileDashboard = '',
    fulfillmentPoUpload = '',
    profileAccountUri = '',
    orderAccountUri = '',
  } = routes;
  const fallbackProfileUri = isNewOrderProfileUrl ? orderAccountUri : profileAccountUri;
  // If enterprise PO edit flow, redirect to fulfillment
  const enterpriseUrl = isEnterprisePurchaseOrder ? fulfillmentPoUpload : fulfillmentReferer;
  const uri = isEnterpriseOrder ? enterpriseUrl : profileDashboard || fallbackProfileUri;
  let normalizedLocale = locale === 'en_US' ? '' : locale;

  if (isGrayMarket(countryCode)) {
    normalizedLocale = 'en_EU';
  }

  const dashboardUrl = localizeUrl(uri, {
    baseUrl,
    locale: normalizedLocale,
    delimiter: '_',
  });
  const { rn = '' } = Configuration;
  const profileUri = isNewOrderProfileUrl
    ? `${dashboardUrl}/${rn}`
    : `${dashboardUrl}?rn=${rn}&redirect=no`;
  window.location.href = isEnterpriseOrder ? dashboardUrl : profileUri;
  return null;
}

function updateLocationCookie(locationObj) {
  return () => {
    document.cookie = `ip_info=${JSON.stringify(locationObj)}; expires=0; path=/`;
  };
}

export function redirectToDeliveryFlow(url = '', usePaymentQRcodeNewFlow = false) {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({ type: LOADER_START });
    const locale = _get(state, 'App.locale', 'en_US');
    const rn = _get(state, 'Configuration.rn', null);
    const selectedPaymentMethod = _get(state, 'Payment.PaymentDetail.PaymentType');
    const trtId = _get(state, 'Payment.PaymentDetail.BillingInfoDetail.PickupLocation');
    const { isDm } = state.App;
    const { model_code: modelCode } = state.Configuration;
    if (url) {
      window.location = url;
      return;
    }
    const isConfigBurst = _get(state, 'App.isConfigBurst', false);
    const inventoryFlow = isInventory(state);
    // Acception: ct for inventory which needs to go to /cybertruck/confirmation (in php8)
    // and not /ct/confirmation (in php7 to support reservation to order flow)
    let modelUrl = MODEL_FULL_NAME[modelCode];
    if (isConfigBurst) {
      if (!inventoryFlow || (inventoryFlow && modelCode !== Models.CYBERTRUCK)) {
        modelUrl = modelCode;
      }
    }
    const isBurstMode = _get(state, 'App.isBurstMode', false);
    const confirmationRN = _isEmpty(rn) ? 'RN' : rn;
    const { transactionNumber = '' } = state.Payment;
    let urlParam = transactionNumber ? `?transactionNumber=${transactionNumber}` : '';
    if (isDm) {
      urlParam = urlParam ? `${urlParam}&isdm=true` : '?isdm=true';
    }
    const referralCode = _get(state, 'ApplicationFlow.referral.referralCode', '');
    if (!_isEmpty(referralCode)) {
      urlParam = urlParam ? `${urlParam}&referral=${referralCode}` : `?referral=${referralCode}`;
    }
    const redirectUrl = `/${modelUrl}/confirmation/${confirmationRN}${urlParam}`;
    dispatch({ type: LOADER_START });
    let normalizedLocale = locale;
    if (locale === 'en_US' || locale === 'zh_CN') {
      normalizedLocale = '';
    }
    window.location = localizeUrl(redirectUrl, {
      base_url: '',
      locale: normalizedLocale,
      delimiter: '_',
    });
  };
}

function resetRedirect(dispatch, errorCode = undefined) {
  dispatch({
    type: LOADER_FINISH,
  });
  dispatch(clearRedirectDetails());
  if (errorCode) {
    dispatch({
      type: POST_ORDER_FAIL,
      error: { code: errorCode },
    });
  }
  if (!isReservationOrder()) {
    dispatch(setConfigurationRN(''));
  }
}

function finalizeRedirectPayment(action) {
  return (dispatch, getState) => {
    const state = getState();
    if (action.isComplete) {
      return dispatch(redirectToDeliveryFlow());
    }
    const errorCode = action.transactionNumber ? PAYMENT_FAILED : null;
    if (errorCode) {
      const { App } = state;
      const { isLayoutMobile, isLayoutTablet } = App;
      const isDesktop = !isLayoutMobile && !isLayoutTablet;
      smoothScrollToError(errorCode, isDesktop);
    }
    resetRedirect(dispatch, errorCode);
  };
}

function processConnect(args, url) {
  return {
    type: PROCESS_CONNECT,
    args,
    url,
  };
}

function sessionCheckComplete(key, token) {
  return {
    type: SESSION_SET,
    key,
    token,
  };
}

function sessionCheckFailed() {
  return {
    type: SESSION_FAILED,
  };
}

export const triggerNoop = () => dispatch => {
  dispatch({
    type: 'NOOP',
  });
};

export const nonBrandNewConsentPendingRedirectConfirmation = () => {
  Cookie.remove(NON_BRAND_NEW_CONSENT_PENDING_COOKIE_KEY);
  Cookie.set(NON_BRAND_NEW_CONSENT_PENDING_COOKIE_KEY, true, { expires: 1 / 48 });
  return completeRedirectOrderFlow(false, '');
};

export const handleQueryCurrentEsignStatus = (expectedStatus = '', isPooling = false) => {
  return (dispatch, getState) => {
    const state = getState();
    const { App } = state;
    const { routes = {} } = App;
    const { checkEsignStatus = '/' } = routes;
    const referenceNumber = _get(state, 'Configuration.rn');
    !isPooling && dispatch(updateESignPending(true));
    request
      .get(checkEsignStatus)
      .query({ referenceNumber, expectedStatus })
      .then(res => {
        const responseData = res?.body?.data;
        const statusMatch = !!responseData?.status_match;
        if (statusMatch) {
          dispatch(updateESignStatus(expectedStatus, responseData?.link));
          if (expectedStatus === ESIGN_STATUS_SIGNED) {
            dispatch(placePaymentSignOrderFlow());
          }
        } else {
          if (expectedStatus === ESIGN_STATUS_SIGNED) {
            if (!isPooling) {
              dispatch(nonBrandNewConsentPendingRedirectConfirmation());
              return;
            }
            dispatch(updateESignStatus(ESIGN_STATUS_SENT));
          } else if (expectedStatus === ESIGN_STATUS_SENT) {
            dispatch(nonBrandNewConsentPendingRedirectConfirmation());
          }
        }
      })
      .catch(err => {
        dispatch(nonBrandNewConsentPendingRedirectConfirmation());
      })
      .finally(() => {
        !isPooling && dispatch(updateESignPending(false));
      });
  };
};

export const handleCheckAndProcessEsignFlow = payload => {
  return (dispatch, getState) => {
    const state = getState();
    const { App } = state;
    const { routes = {} } = App;
    const { sendEsignMessage = '/' } = routes;
    const schema = {
      ...getProcessEsignFlowPayload(state),
      ...payload,
    };
    dispatch(updateESignPending(true));
    request
      .post(sendEsignMessage)
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('Accept', 'application/json')
      .send(schema)
      .then(res => {
        dispatch(updateESignPending(false));
        dispatch(queryCurrentEsignStatus(ESIGN_STATUS_SENT));
      })
      .catch(err => {
        dispatch(nonBrandNewConsentPendingRedirectConfirmation());
      });
  };
};

export function checkSession() {
  return (dispatch, getState) => {
    const state = getState();
    // Note: inventory & reservation order flows are not cached
    if (!isInventory(state) && !isReservationOrder(state)) {
      const { App, OMS } = state;
      const { routes = {} } = App;
      const { sesscheck = '/configurator/api/v1/sesscheck' } = routes;
      const { lexicon = {} } = OMS;
      const { base_configuration = [], effective_date, name, product, updated_date, groups_dictionary } = lexicon || {};
      const { configurator = {} } = groups_dictionary || {};
      const { TRIM: trimGroup = {} } = configurator;
      const { options: trimOpts = [] } = trimGroup || {};
      dispatch({ type: START_SESSION });
      request
        .post(sesscheck)
        .send({
          base_configuration,
          effective_date,
          name,
          product,
          updated_date,
          trim_options: trimOpts,
          referer: window?.location?.href,
        })
        .set('X-Requested-With', 'XMLHttpRequest')
        .set('Accept', 'application/json')
        .end((error, response) => {
          if (error) {
            dispatch(sessionCheckFailed());
          } else {
            const csrfKey = _get(response, 'body.csrf_key', '');
            const csrfToken = _get(response, 'body.csrf_token', '');
            window.teslaConfig.csrf_key = csrfKey;
            window.teslaConfig.csrf_token = csrfToken;
            dispatch(sessionCheckComplete(csrfKey, csrfToken));
          }
        });
    }
  };
}

export const showCombinedSavings = flag => dispatch => {
  dispatch({
    type: SHOW_COMBINED_SAVINGS,
    flag,
  });
};

export const openFinanceModalStandalone = (props = {}) => dispatch => {
  if(props.id) {
    dispatch(selectTab(props.id));
  }

  return dispatch(openModal(
      FINANCIAL_MODAL_STANDALONE,
      {
        genericWrapper: true,
        size: MODAL_MEDIUM,
        isMega: true,
        ...props
      }
  ));
}

export const getReferralData = () => {
  return (dispatch, getState) => {
    const state = getState();
    const { App, OMS, ApplicationFlow, Payment, ReviewDetails } = state;
    const { routes, countryCode, isEnterpriseOrder, isPostOrderSwap } = App;
    const { referral = {} } = ApplicationFlow;
    const queryParameters = getQueryParameters();
    const { referral: referralFromQuery } = queryParameters || {};
    const { referralFromCookieEnabled } = ReviewDetails;
    const { serverErrors: paymentServerErrors } = Payment;
    const referralActive = _get(referral, 'isValid', false);
    const { oms_params = {} } = OMS;
    const { model } = oms_params || {};
    const { getReferralData } = routes;
    const referralCode = referralFromCookieEnabled ? getReferralCookie() : '';
    const isUsedInventoryVehicle = isUsedInventory(state);
    const referralCodeSource = referralFromQuery || referralCode;
    // COIN-14289: Check on referer source
    if (referralFromQuery && isRefererFromGoog()) {
      return;
    }
    if (
      !getReferralData ||
      referralActive ||
      isEnterpriseOrder ||
      isPostOrderSwap ||
      isUsedInventoryVehicle ||
      !referralCodeSource
    ) {
      return;
    }
    request
      .get(getReferralData)
      .query({ country: countryCode, models: model, referral: referralCodeSource })
      .then(res => {
        const { body } = res;
        const { isValid, products = {} } = body || {};
        if (isValid) {
          // Modify body for the model
          const modelData = products[model] || {};
          dispatch({
            type: UPDATE_REFERRAL,
            data: { ...body, ...modelData },
          });
          if (_isEmpty(paymentServerErrors)) {
            dispatch(
              openModal(REFERRAL_MODAL, {
                className: 'session-time-out--container',
                genericWrapper: false,
              })
            );
          }
        }
      })
      .catch(err => {
        console.warn('getReferralData err', err);
      });
  };
};

export const updateConfigByRules = action => {
  const { rules = [], asset: code = '' } = action;
  return (dispatch, getState) => {
    const state = getState();
    const { Configuration, ReviewDetails } = state;
    const { option_codes: config = [] } = Configuration;
    const { inventoryUpgrades } = ReviewDetails;
    const { option } = rules.find(rule => parseSelectedBy(rule.selected_by, config)) || {};
    if (option) {
      dispatch(setOptionExtended(option));
    } else {
      const setOption = _get(inventoryUpgrades, `${code}[0].monroney_difference.added`, []);
      if (setOption.length) {
        dispatch(setOptionExtended({ set: setOption }));
      }
    }
  };
};

export const loadReservationFlow = action => {
  const { optionToSet: set = [], redirectTo } = action || {};
  return (dispatch, getState) => {
    const state = getState();
    const {
      Configuration: { option_codes: config = [] } = {},
      FeatureListModal: { isCarouselOpen } = {},
    } = state || {};
    if (set?.length) {
      dispatch(storeUserConfig(config));
      dispatch(setOptionExtended({ set }));
    }
    if (redirectTo) {
      dispatch(navigationSelectKey({ key: redirectTo }));
      dispatch(deselectAccessories());
    }
    if (isCarouselOpen) {
      dispatch(closeFeatureModal());
    }
  };
};

export function testGetEstimateDeliveryDate() {
  return (dispatch, getState) => {
    const data = getEstimateDeliveryDate(getState());
    dispatch({ type: TEST_EDD_DATA, data });
  };
}

const dispatchActionsPostSession = () => ({
  type: ACTIONS_POST_SESSION,
});

export const updateApplicationFlowState = (action$, state$) =>
  action$.pipe(
    filter(action =>
      [
        PLACE_ORDER_FLOW,
        PLACE_PUBLIC_ORDER_FLOW,
        PLACE_PAYMENT_SIGN_ORDER_FLOW,
        SAVE_CONFIG_FLOW,
        POST_ORDER_SUCCESS,
        POST_SAVE_CONFIG_SUCCESS,
        CANCEL_UPDATE_ORDER,
        POST_ORDER_FAIL,
        NAVIGATION_SELECT_KEY,
        NAVIGATION_SELECT_NEXT_KEY,
        ADDRESS_UPDATE,
        SESSION_TIME_OUT,
        BATTERY_SAVINGS_MODAL,
        RANGE_INFO_MODAL,
        REQUEST_CALLBACK_MODAL,
        REFERRAL_RN_FLOW_REDIRECT,
        SHOW_SAVINGS_MODAL,
        SHOW_RANGE_MODAL,
        SHOW_REQUESTCALLBACK_MODAL,
        SHOW_INTERIOR_MODAL,
        SHOW_FEATURE_LIST_MODAL,
        SHOW_ACCELERATION_MODAL,
        POST_PAYMENT_COMPLETE,
        GEOIP_LOCATION_CHANGED,
        SHOW_FINANCE_MODAL,
        SHOW_FINANCE_MODAL_CN,
        SHOW_BANNER_MODAL,
        TRIGGER_LOCATION_MODAL,
        TRIGGER_INSPECTION_MODAL,
        APPLE_PAY_VALIDITY,
        TRIGGER_CONFIGURATION_CHANGE,
        SHOW_INCENTIVES_MODAL,
        UPDATE_CONFIG_BY_RULES,
        CLEAR_LOCAL_STORAGE,
        SHOW_INSPECTION_MODAL,
        NAVIGATE_INVENTORY_LINK,
        NAVIGATE_GET_UPDATES_LINK,
        SHOW_GAS_SAVINGS_MODAL,
        LOAD_RESERVATION_FLOW,
        CHECK_AND_PROCESS_ESIGN_FLOW,
        QUERY_CURRENT_ESIGN_STATUS,
        UPDATE_ESIGN_STATUS,
        SET_FINANCE_MODAL_TAB_TRADEIN,
        SHOW_COMBINED_SAVINGS_MODAL,
        SHOW_FINANCIAL_MODAL_STANDALONE,
        SESSION_SET,
        SESSION_FAILED,
      ].includes(action.type)
    ),
    map(action => {
      const state = state$.value;
      const { App = {}, Modal = {}, ReviewDetails = {}, Configuration = {}, Banner = {} } = state;
      const { base_url: baseUrl = '', locale, isPreferredAddressEnabled, countryCode, isVerificationProgramEnabled, isLayoutMobile, isLayoutTablet } = App;
      const isDesktop = !isLayoutMobile && !isLayoutTablet;
      const { bannerContentParsed = [] } = Banner;
      const { open: isModalOpen = false } = Modal;
      const cookieKey = _get(Configuration, 'loadLatestLexiconCookieKey', '');
      const orderStatus = _get(state, 'ApplicationFlow.orderStatus', '');
      const isCN = countryCode === 'CN';

      switch (action.type) {
        case SESSION_SET:
          configureStore.store.dispatch(getReferralData());
          if (isVerificationProgramEnabled) {
            configureStore.store.dispatch(checkVerificationStatus());
          }
          return dispatchActionsPostSession();
        case SESSION_FAILED:
          if (isCN) {
            configureStore.store.dispatch(getReferralData());
          }
          return dispatchActionsPostSession();
        case PLACE_ORDER_FLOW:
          configureStore.store.dispatch(
            removeAlert({ message: i18n('common.errors__genericOrderProcessing') })
          );
          return postMktConfigWithPayment(action?.paymentObj);
        case SHOW_SAVINGS_MODAL:
          Analytics.fireTagEvent({
            event: Analytics.event,
            interaction: action.interaction,
            'cfg-type': Analytics.cfgType,
          });
          return openModal(BATTERY_SAVINGS_MODAL, { genericWrapper: true, size: MODAL_XSMALL });
        case SHOW_RANGE_MODAL:
          return openModal(RANGE_INFO_MODAL, { genericWrapper: true, size: MODAL_XSMALL });

        case SHOW_BANNER_MODAL:
          let propsMod = _get(action, 'props', {});
          if (!action?.props?.content) {
            // get default dynamic banner content
            const dynamicBannerContent = bannerContentParsed?.filter(x => x?.description) || [];
            if (!!dynamicBannerContent.length) {
              propsMod.content = dynamicBannerContent;
            }
          }
          return openModal(BANNER_MODAL, {
            genericWrapper: true,
            size: MODAL_XSMALL,
            props: propsMod,
          });
        case SHOW_INTERIOR_MODAL:
        case SHOW_ACCELERATION_MODAL:
          return openModal(RECOMMENDED_INFO_MODAL, {
            genericWrapper: true,
            size: MODAL_XSMALL,
            props: _get(action, 'props', {}),
          });
        case SHOW_FEATURE_LIST_MODAL:
          return openModal(FEATURE_LIST_MODAL, {
            genericWrapper: true,
            size: MODAL_MEDIUM,
            props: _get(action, 'props', {}),
          });
        case PLACE_PUBLIC_ORDER_FLOW:
          configureStore.store.dispatch(
            removeAlert({ message: i18n('common.errors__genericOrderProcessing') })
          );
          configureStore.store.dispatch(
            removeAlert({ message: i18n('common.errors__checkYourAccount') })
          );
          configureStore.store.dispatch(
            removeAlert({ message: i18n('common.error__invalidEmailRegion') })
          );
          configureStore.store.dispatch(
            removeAlert({ message: i18n('common.errors__unorderable') })
          );
          return postOrderWithPayment(action?.paymentObj);
        case PLACE_PAYMENT_SIGN_ORDER_FLOW:
          return postPaymentSignWithPayment(action?.paymentObj);
        case SAVE_CONFIG_FLOW:
          configureStore.store.dispatch(
            removeAlert({ message: i18n('common.errors__genericOrderProcessing') })
          );
          return postMktConfig(action?.paymentObj);
        case TRIGGER_CONFIGURATION_CHANGE:
          const optionCode = _get(state, `${action?.content_path}`);
          const optionToSet = optionCode ? { set: [optionCode] } : {};
          return setOptionExtended({
            ..._get(action, 'option', optionToSet),
            interaction: action.interaction,
            panelName: action.panelName,
          });
        case CHECK_AND_PROCESS_ESIGN_FLOW:
          return handleCheckAndProcessEsignFlow(action.payload);
        case QUERY_CURRENT_ESIGN_STATUS:
          return handleQueryCurrentEsignStatus(action.expectedStatus, action.isPooling);
        case UPDATE_ESIGN_STATUS:
          if (action.status === ESIGN_STATUS_TIMEOUT) {
            return openModal(ESIGN_FLOW_EXCEPTION_MODAL, {
              genericWrapper: true,
              size: MODAL_SMALL,
              type: MODAL_REQUIRED,
              isOrderCanceledFlow: true,
              header: {
                display: true,
                close: false,
                logo: false,
              },
            });
          }
        case UPDATE_CONFIG_BY_RULES:
          return updateConfigByRules(action);
        case POST_ORDER_SUCCESS:
          {
            const isPaymentInteractionRequired = _get(
              state,
              'Payment.isPaymentInteractionRequired',
              false
            );

            const rn = _get(action, 'response.body.referenceNumber', '');
            const payment_type = _get(state, 'Payment.PaymentDetail.PaymentType');
            if (isRN(rn) || rn.match(/^EO\d{6,}$/)) {
              configureStore.store.dispatch(setConfigurationRN(rn));
              configureStore.store.dispatch(setTimeOfOrderPlacedSuccess(new Date().getTime()));
              Analytics.transaction(state, rn);

              if (state.Payment.PaymentDetail.RedirectPaymentName) {
                // For redirect payements, we need to send some extra information
                // to the Payment component. Upon setting this information
                // the redirect payment flow should start
                const token = _get(action, 'response.body.token');
                const returnUrl = _get(action, 'response.body.returnUrl');
                // const esignStatus = ESIGN_STATUS_SENT;
                if (token != null) {
                  if (countryCode === 'CN') {
                    if (isEsignFlowEnabled(state)) {
                      configureStore.store.dispatch(updateESignPending(true));
                      configureStore.store.dispatch(checkAndProcessEsignFlow());
                    }
                    return updatePaymentInteractionRequired(true);
                  }
                  return setRedirectDetails({ token, returnUrl });
                }
                // This used to be the old offline payment flow which has been replaced
                // with the token flow above
                return addAlert({ message: i18n('common.errors__genericOrderProcessing') });
              }
            }
            const url = _get(action, 'response.body.redirectUrl');
            const connectArgs = _get(action, 'response.body.connectArgs');
            if (connectArgs) {
              return processConnect(connectArgs, url);
            }
            const usePaymentQRcodeNewFlow = _get(
              action,
              'response.body.qrpayment.useNewFlow',
              false
            );

            if (isCN && isPaymentInteractionRequired) {
              configureStore.store.dispatch({
                type: LOADER_FINISH,
              });
              return true;
            }
            return redirectToDeliveryFlow(url);
          }
          break;
        case POST_PAYMENT_COMPLETE:
          if (isCN) {
            return redirectToDeliveryFlow();
          }
          return finalizeRedirectPayment(action);
        // kwkd1: update and cancel functions
        case POST_SAVE_CONFIG_SUCCESS:
          if (!_isEmpty(state?.Configuration?.pricingChangeConfirmed)) {
            Cookie.remove(cookieKey);
          }
          if (window?.ReactNativeWebView !== undefined) {
            let message;
            if (isCN) {
              message = orderStatus === PAYMENT_PENDING ? 'payment_pending_save_config_confirm' : 'confirm';
            } else {
              const fetchProductList = JSON.stringify({
                action: 'fetchProductList',
              });
              window?.ReactNativeWebView?.postMessage(fetchProductList);

              const navToProductHomeScreen = JSON.stringify({
                action: MOBILE_APP_ACTION_NAV_PRODUCT_HOME_SCREEN,
              });
              message = navToProductHomeScreen;
            }


            return window?.ReactNativeWebView?.postMessage(message);
          }
          configureStore.store.dispatch({ type: LOADER_START });
          return redirectToProfilePage(state);
        case CANCEL_UPDATE_ORDER:
          if (!_isEmpty(state?.Configuration?.pricingChangeConfirmed)) {
            Cookie.remove(cookieKey);
          }
          const { isEnterprisePurchaseOrder = false } = App;
          if (!isEnterprisePurchaseOrder) {
            configureStore.store.dispatch(cancelEditOrder(false));
          }
          if (window?.ReactNativeWebView !== undefined) {
            let message;
            if (isCN) {
              message = orderStatus === PAYMENT_PENDING ? 'payment_pending_save_config_cancel' : 'cancel';
            } else {
              const navToProductHomeScreen = JSON.stringify({
                action: MOBILE_APP_ACTION_NAV_PRODUCT_HOME_SCREEN,
              });
              message = navToProductHomeScreen;
            }
            return window?.ReactNativeWebView?.postMessage(message);
          }
          configureStore.store.dispatch({ type: LOADER_START });
          return redirectToProfilePage(state);
        case POST_ORDER_FAIL:
          const isSwap = _get(state, 'App.isInventorySwapEnabled', false);

          if (action.error.code === CITY_OUT_OF_STOCK) {
            return openModal(CONFIRM_CITY_OUT_OF_STOCK_MODAL, {
              genericWrapper: true,
              size: MODAL_SMALL,
              type: MODAL_REQUIRED,
            });
          }
          let dispatchAction = null;
          switch (action.error.code) {
            case PICKUP_LOCATION_OUT_OF_STOCK:
              const errorData = action?.error?.data || {};
              const pickupLocation = errorData.pickup_location || [];
              dispatchAction = {
                type: UPDATE_PICKUP_LOCATIONS_BY_AVAILABILITY,
                data: pickupLocation,
              };
              break;
            case PAYMENT_FAILED:
              Analytics.paymentFailure(state);
              dispatchAction = {
                type: PAYMENT_FAILED,
              };
              break;
            case FIELD_REQUIRED:
              dispatchAction = {
                type: FIELD_REQUIRED,
                field: action.error.field,
              };
              break;
            case ERROR_WHILE_PROCESSING:
              dispatchAction = addAlert({ message: i18n('common.errors__checkYourAccount') });
              break;
            case INVALID_CONFIG:
              dispatchAction = addAlert({
                message: i18n('common.errors__configExpired', { TRIGGER: CLEAR_LOCAL_STORAGE }),
                withTrigger: true,
              });
              break;
            case ACCESSORIES_ORDER_CREATE_ERROR:
              dispatchAction = deselectAccessories(action.error.code);
              break;
            case INVALID_HOST_FOR_EMAIL:
              dispatchAction = addAlert({ message: i18n('common.error__invalidEmailRegion') });
              break;
            case INVALID_FIRST_NAME:
            case INVALID_LAST_NAME:
              dispatchAction = addAlert({ message: i18n('common.errors__firstLastNameNotValid') });
              break;
            case INVALID_EMAIL:
              dispatchAction = addAlert({ message: i18n('common.errors__emailNotValid') });
              break;
            case INVALID_PHONE_NUMBER:
              dispatchAction = addAlert({ message: i18n('common.errors__phonenumberNotValid') });
              break;
            case ERROR_VIEW_ONLY:
              dispatchAction = addAlert({ message: i18n('common.errors__unorderable') });
              break;
            case ERROR_VIN_IS_NOT_FOUND:
              if (isSwap) {
                dispatchAction = setInvalidPickupLocation();
              } else if (getIsFalconDeliverySelectionEnabled(state)) {
                Analytics.fireTagEvent({
                  event: Analytics.event,
                  interaction: 'show-sold-out-modal',
                  'cfg-type': Analytics.cfgType,
                });
                return openModal(VEHICLE_UNAVAILABLE_MODAL, {
                  genericWrapper: true,
                  size: MODAL_SMALL,
                });
              }
              break;
            default:
              dispatchAction = addAlert({ message: i18n('common.errors__genericOrderProcessing') });
              break;
          }

          if (!dispatchAction) {
            dispatchAction = addAlert({ message: i18n('common.errors__genericOrderProcessing') });
          }
          // Scroll to alerts
          setTimeout(() => {
            smoothScrollToError(action.error.code, isDesktop);
          }, 500);
          return dispatchAction;

        // TODO once we have working BE endpoints
        case POST_PROCESS_SWAP:
        case POST_PROCESS_SWAP_COMPLETE:
        case POST_PROCESS_SWAP_FAIL:
          return;

        case NAVIGATION_SELECT_NEXT_KEY:
        case NAVIGATION_SELECT_KEY:
          // navigate to delivery-intent page instead
          if (action.key === NAVIGATION_SECTION_KEY_REGISTRATION) {
            return redirectToDeliveryFlow();
          }

          if (App.closeOpenModalsOnNavigation && _get(state, 'Modal.open', false)) {
            return closeAllModals();
          }

          return navigationModalPass();

        case SESSION_TIME_OUT:
          if (action.error.code === CONFIG_SAVE_TIME_OUT) {
            return openModal(SESSION_TIME_OUT_MODAL, {
              className: 'session-time-out--container',
              genericWrapper: false,
              size: MODAL_FULLSCREEN,
            });
          }
          break;
        case BATTERY_SAVINGS_MODAL:
          return openModal(BATTERY_SAVINGS_MODAL, { genericWrapper: false, size: MODAL_MEDIUM });

        case REFERRAL_RN_FLOW_REDIRECT:
          return triggerReferralDashboardFlow();

        case GEOIP_LOCATION_CHANGED:
          if (!isPreferredAddressEnabled) {
            updateLocationCookie(_get(action, 'location', {}));
          }
          return triggerNoop();

        case RANGE_INFO_MODAL:
          return openModal(RANGE_INFO_MODAL, { genericWrapper: false, size: MODAL_MEDIUM });

        case REQUEST_CALLBACK_MODAL:
          return openModal(REQUEST_CALLBACK_MODAL, { genericWrapper: false, size: MODAL_MEDIUM });

        case SHOW_FINANCE_MODAL_CN:
          return openModal(FINANCIAL_MODAL_CN, {
            genericWrapper: true,
            size: MODAL_MEDIUM,
            classes: 'tds-link',
            isMega: true,
          });
        case SHOW_FINANCE_MODAL:
          Analytics.fireTagEvent({
            event: Analytics.event,
            'cfg-type': 'financing',
            interaction: 'cost-comparison',
          });

          return openFinanceModal({ selectedView: FINANCE_MODAL_TAB_OPTIONS });
        case SHOW_INCENTIVES_MODAL:
          return openFinanceModal({ selectedView: FINANCE_MODAL_TAB_INCENTIVES });
        case SHOW_INCENTIVES_SAVINGS_MODAL:
          return openFinanceModal({ selectedView: FINANCE_MODAL_TAB_INCENTIVES_SAVINGS });
        case CLEAR_LOCAL_STORAGE:
          setTimeout(() => {
            Storage.remove(LOCAL_STORAGE_KEY);
            window.location = updateUriWithQueryParams({ return: Date.now() });
          }, 300);
          return triggerNoop();
        case APPLE_PAY_VALIDITY:
          const { userHasApplePay = false } = action;
          const { isDeliveryDetailsRequired, DeliveryDetails = {} } = ReviewDetails;
          const { PostalCode = '' } = DeliveryDetails;
          const { isPickupOnlyEnabled = false } = App;
          const checkDeliveryDetails =
            !PostalCode && isDeliveryDetailsRequired && !isPickupOnlyEnabled;
          if (checkDeliveryDetails && userHasApplePay) {
            return openModal(USER_LOCATION_MODAL, {
              className: 'session-time-out--container',
              genericWrapper: true,
              size: MODAL_SMALL,
              type: MODAL_REQUIRED,
              header: {
                display: true,
                close: false,
                logo: false,
              },
            });
          }
          return triggerNoop();
        case SHOW_INSPECTION_MODAL:
          if (!isModalOpen) {
            Analytics.fireTagEvent({
              event: Analytics.event,
              'cfg-type': Analytics.cfgType,
              interaction: 'text-cosmetic-mechanical-standards',
            });
            return openModal(INSPECTION_MODAL, {
              analyticsInteraction: INSPECTION_MODAL_ANALYTICS,
              genericWrapper: true,
              size: MODAL_LARGE,
              form: INSPECTION_MODAL,
              classes: 'tds-btn--blue tds-o-btn--blue',
            });
          }
          return triggerNoop();
        case NAVIGATE_INVENTORY_LINK:
          const { analytics = {} } = action;
          const {
            OMS,
            Navigation: { trimFamily = {} },
          } = state;
          const { siblingBase: sibling } = App;
          const { oms_params = {} } = OMS;
          const { model: modelCode } = oms_params || {};
          const { inventoryLocation = {} } = ReviewDetails;
          const { postalCode = '' } = inventoryLocation || {};
          let queryParams = postalCode ? { zip: postalCode } : {};
          const optCode = action.optCode || null;
          if (optCode) {
            const optFamilyCode = getOptionFamilyCode(optCode);
            if (optFamilyCode) {
              const { key: optKey, value: optVal } = optFamilyCode || {};
              queryParams = {
                ...queryParams,
                [optKey]: optVal,
              };
            }
          }
          const trimCode = getTrimCode(state);
          if (trimFamily[trimCode]) {
            queryParams['TRIM'] = trimFamily[trimCode];
          }
          const inventoryLink = getInventoryLink({ sibling, modelCode, locale, queryParams });
          Analytics.fireTagEvent({
            event: Analytics.event,
            interaction: 'click-see-limited-inventory-for-trim',
            'cfg-type': Analytics.cfgType,
            ...analytics,
          });
          window.open(inventoryLink, '_blank');
          return triggerNoop();

        case NAVIGATE_GET_UPDATES_LINK: {
          let normalizedLocale = locale === 'en_US' ? '' : locale;
          const updatesLink = localizeUrl('/updates', {
            baseUrl,
            locale: normalizedLocale,
            delimiter: '_',
          });
          window.open(updatesLink, '_blank');
          return triggerNoop();
        }

        case SHOW_GAS_SAVINGS_MODAL:
          Analytics.fireTagEvent({
            event: Analytics.event,
            'cfg-type': Analytics.cfgType,
            interaction: 'learn-more-gas-savings',
          });
          return openFinanceModal({ selectedView: FINANCE_MODAL_TAB_GAS_SAVINGS });

        case LOAD_RESERVATION_FLOW:
          return loadReservationFlow(action);
        case SET_FINANCE_MODAL_TAB_TRADEIN:
          return selectFinanceView(FINANCE_MODAL_TAB_TRADEIN);
        case SHOW_COMBINED_SAVINGS_MODAL:
          return showCombinedSavings();
        case SHOW_FINANCIAL_MODAL_STANDALONE:
          Analytics.fireInteractionEvent('edit-terms-savings');
          return openFinanceModalStandalone(action?.props);
        default:
      }
      return {
        type: 'UNHANDLED_ACTION'
      };
    })
  );

export const logActivitySession = () => {
  return (dispatch, getState) => {
    const state = getState();
    const { App, OMS } = state;
    const { routes = {} } = App;
    const { lexicon = {} } = OMS;
    const { base_configuration = [], effective_date, name, product, updated_date, groups_dictionary } = lexicon || {};
    const { configurator = {} } = groups_dictionary || {};
    const { TRIM: trimGroup = {} } = configurator;
    const { options: trimOpts = [] } = trimGroup || {};
    const { getLogActivitySession } = routes;
    request
      .post(getLogActivitySession)
      .send({
        base_configuration,
        effective_date,
        name,
        product,
        updated_date,
        trim_options: trimOpts,
        referer: window?.location?.href,
      })
      .then(res => {
      });
  }
};

/**
 * Check verification status from session
 **/
export const checkVerificationStatus = () => {
  return (dispatch, getState) => {
    const state = getState();
    const { App } = state;
    const { routes } = App;
    const { checkVerificationStatus } = routes;
    const verifyCookie = getVerifyCookie();
    if (verifyCookie !== 'Y' || !checkVerificationStatus) {
      return;
    }
    request
      .post(checkVerificationStatus)
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('Accept', 'application/json')
      .then(res => {
        dispatch(updateVerificationStatus(res?.body));
      })
      .catch(err => {
        dispatch(updateVerificationStatus({}));
      });
  };
};
