import React from 'react';
import _get from 'lodash/get';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { string, number, bool, shape, arrayOf } from 'prop-types';
import {
  i18n,
  getVehicleLocationString,
  getDeliveryAndRegistrationDisclaimer,
  isEMEAMarket,
  getYear,
  getFormattedModelYear,
} from 'utils';
import { ODOMETER_THRESHOLD, CPO_MARKETS, Models } from 'dictionary';
import {
  isInventory,
  getTransportationFeeAmount,
  getDepositAmount,
  getModel,
  getVehicleMappedForLocation,
  getModelName,
  getDeliveryRegionDisclaimer,
  getConfigurationOptions,
  getInventorySteeringWheelDisclaimer,
} from 'selectors';

import InfoBlock from '../InfoBlock';
import Specs from './Specs';
import InventoryEmissions from './InventoryEmissions';
import NotificationBanner from './NotificationBanner';
import InfoMessage from './InfoMessage';
import FederalIncentiveChip from './FederalIncentiveChip';

import { formatNumber } from '@tesla/coin-common-components';

const InventorySummary = ({
  isComboInventory,
  isUsedInventory,
  isWinterWheelsIncluded,
  inventoryData,
  modelName,
  showVehicleLocation,
  showVehicleLocationDisclaimer,
  showModelYearOverviewPage,
  showModelYearVehicleDetails,
  showFormattedModelYear,
  showLocationPrefix,
  hideVehicleTypeLabel,
  isInventoryVehicle,
  isTransportFeeEnabled,
  transportFeeAmount,
  depositAmount,
  modelCode,
  isPickupOnlyEnabled,
  showDeliveryAndRegistrationDisclaimer,
  deliveryAndRegistrationDisclaimer,
  deliveryDisclaimer,
  vehicleMapped,
  vehicleSummaryProps,
  showVehicleDetailsLineItems,
  showOdometer,
  showInTransitStatusMetro,
  isPostOrderSwap,
  isEnterpriseOrder,
  featureOptions,
  additionalInfoAfterButton,
  showDemoVin,
  enableCyberpunk,
  includeFutureYear,
}) => {
  const {
    IsDemo,
    Odometer,
    TrimName,
    DemoVin,
    VIN,
    Year,
    CountryCode,
    FactoryGatedDate,
    IsFactoryGated,
    ManufacturingYear,
    ChargePortType,
    Price,
  } = inventoryData;
  const contents = [];
  const contentsAfterFeatures = [];
  const additionalInfoBeforeButton = [];
  const isEMEA = isEMEAMarket(CountryCode);

  // Vehicle Type
  if (!hideVehicleTypeLabel && IsDemo) {
    contents.push(i18n('Inventory.common.demoVehicle'));
  } else if (isUsedInventory) {
    const usedVehicleKey = CPO_MARKETS.includes(CountryCode) ? 'Inventory.common.cpoVehicle': 'Inventory.common.usedVehicle';
    contents.push(i18n(usedVehicleKey));
  } else if (!hideVehicleTypeLabel) {
    contents.push(i18n('Inventory.common.newVehicle'));
  }

  if (showModelYearVehicleDetails) {
    const futureYear = includeFutureYear ? 1 : 0;
    const currentYear = getYear(new Date()) + futureYear;
    if (!isUsedInventory && currentYear !== Year) {
      contents.push(i18n('Inventory.common.modelYear', {YEAR: getFormattedModelYear({year: Year})}));
    }
  }

  // Odometer
  const mileageUnit = i18n('Inventory.common.mileageUnit');
  if (showOdometer || Odometer >= ODOMETER_THRESHOLD || IsDemo) {
    if (Odometer >= ODOMETER_THRESHOLD || isUsedInventory || IsDemo) {
      contents.push(
        i18n('Inventory.common.odometerMileage', {
          DISTANCE: formatNumber(Odometer),
          UNITS: mileageUnit,
        })
      );
    } else {
      contents.push(
        i18n('Inventory.common.odometerThreshold', {
          DISTANCE: formatNumber(ODOMETER_THRESHOLD),
          UNITS: mileageUnit,
        })
      );
    }
  }

  // VIN
  if (!isComboInventory && VIN) {
    contents.push(`VIN ${VIN}`);
  } else if (showDemoVin && DemoVin) {
    contents.push(`VIN ${DemoVin}`);
  }

  const isFactoryGated = FactoryGatedDate || IsFactoryGated;
  if (!isFactoryGated) {
    contents.push(i18n('Inventory.common.inProduction'));
  }

  // Vehicle Location
  const isCnNewInventoryComboVehicle = CountryCode === 'CN' && isComboInventory && !isUsedInventory;
  const isCN = CountryCode === 'CN';
  if ((showVehicleLocation || showInTransitStatusMetro) && !isCnNewInventoryComboVehicle) {
    const locationString = getVehicleLocationString({
      market: CountryCode,
      vehicle: vehicleMapped,
      showLocationPrefix,
      showVehicleLocationDisclaimer,
      isPickupOnlyEnabled,
      isEMEA,
      showInTransitStatusMetro,
      inventoryData,
      isUsedInventory,
      isCN
    });
    if (deliveryDisclaimer) {
      additionalInfoBeforeButton.push(deliveryDisclaimer);
    } else if (locationString) {
      contents.push(locationString);
    }
  }

  if (showVehicleDetailsLineItems?.length && ManufacturingYear) {
    showVehicleDetailsLineItems.map(itm => {
      return contents.push(
        i18n(`Inventory.vehicleDetails.${itm}.description`, {
          MANUFACTURE_YEAR: getYear(ManufacturingYear),
        })
      );
    });
  }

  if (ChargePortType) {
    contents.push(
      i18n('Inventory.vehicleDetails.chargePort', {
        TYPE: ChargePortType,
      })
    );
  }

  if (isWinterWheelsIncluded) {
    contentsAfterFeatures.push(i18n('Wheels.summerWinterSetsIncluded'));
  }

  let mergedContents = featureOptions?.length ? [...contents, ...featureOptions, ...contentsAfterFeatures] : [...contents, ...contentsAfterFeatures]
  const filteredContentGroups = ['CONNECTIVITY', 'AUTOPILOT_TRIAL']
  mergedContents = mergedContents?.filter(content => {
    return !filteredContentGroups?.includes(content?.group) 
  }) || [];

  const formattedYear = getFormattedModelYear({ year: Year, showFormattedModelYear });
  const title = showModelYearOverviewPage ? `${formattedYear} ${modelName}` : modelName;

  return (
    <div className="vehicle-summary-container">
      <InfoBlock
        name="vehicleName"
        title={enableCyberpunk ? TrimName : title}
        titleClass="tds-text--h1-alt tds-o-no_padding_bottom"
        secondaryTitle={enableCyberpunk ? '' : TrimName}
        centered
        secondaryTitleClass="tds-text--h6"
      />

      <InfoMessage />
      <InventoryEmissions />
      <NotificationBanner />
      <Specs />
      <FederalIncentiveChip />
      <InfoBlock
        name="vehicleSummary"
        contents={mergedContents}
        centered
        isInventoryVehicle={isInventoryVehicle}
        isTransportFeeEnabled={isTransportFeeEnabled}
        transportFeeAmount={transportFeeAmount}
        depositAmount={depositAmount}
        modelCode={modelCode}
        itemClass="tds-text_color--black"
        isPickupOnlyEnabled={isPickupOnlyEnabled}
        showDeliveryAndRegistrationDisclaimer={showDeliveryAndRegistrationDisclaimer}
        deliveryAndRegistrationDisclaimer={deliveryAndRegistrationDisclaimer}
        additionalInfoBeforeButton={additionalInfoBeforeButton}
        additionalInfoAfterButton={additionalInfoAfterButton}
        {...(enableCyberpunk ? {
          itemClass: "tds--vertical_padding-bottom--1x",
          isTransportFeeEnabled: false,
          contentsClass: "tds--vertical_padding",
        } : vehicleSummaryProps)}
      />
    </div>
  );
};

InventorySummary.propTypes = {
  isComboInventory: bool.isRequired,
  isUsedInventory: bool.isRequired,
  isWinterWheelsIncluded: bool,
  inventoryData: shape({
    IsDemo: bool,
    Odometer: number,
    TrimName: string,
    VehicleRegion: string,
    VIN: string,
    Year: number,
    CountryCode: string,
    IsFactoryGated: bool,
    IsAtLocation: bool,
  }).isRequired,
  modelName: string.isRequired,
  showVehicleLocation: bool.isRequired,
  showVehicleLocationDisclaimer: bool,
  showModelYearOverviewPage: bool,
  showFormattedModelYear: bool,
  showLocationPrefix: bool,
  hideVehicleTypeLabel: bool,
  isInventoryVehicle: bool.isRequired,
  isTransportFeeEnabled: bool.isRequired,
  transportFeeAmount: number,
  depositAmount: number.isRequired,
  modelCode: string.isRequired,
  isPickupOnlyEnabled: bool,
  showDeliveryAndRegistrationDisclaimer: bool,
  deliveryAndRegistrationDisclaimer: string,
  deliveryDisclaimer: string,
  vehicleMapped: shape({}),
  vehicleSummaryProps: shape({}),
  showVehicleDetailsLineItems: arrayOf(string),
  showOdometer: bool.isRequired,
  showInTransitStatusMetro: bool.isRequired,
  isPostOrderSwap: bool.isRequired,
  hideCrossBorderInTransitStatusMetro: bool.isRequired,
  isEnterpriseOrder: bool.isRequired,
  isEMEA: bool.isRequired,
  additionalInfoAfterButton: arrayOf(string),
  showModelYearVehicleDetails: bool.isRequired,
  showDemoVin: bool,
  includeFutureYear: bool,
};

InventorySummary.defaultProps = {
  isWinterWheelsIncluded: false,
  showVehicleLocationDisclaimer: false,
  showLocationPrefix: false,
  showModelYearOverviewPage: true,
  showFormattedModelYear: false,
  hideVehicleTypeLabel: false,
  transportFeeAmount: 0,
  isPickupOnlyEnabled: false,
  showDeliveryAndRegistrationDisclaimer: false,
  deliveryAndRegistrationDisclaimer: '',
  deliveryDisclaimer: '',
  vehicleMapped: null,
  vehicleSummaryProps: {},
  showVehicleDetailsLineItems: [],
  additionalInfoAfterButton: [],
  showDemoVin: false,
  includeFutureYear: false,
};

const mapStateToProps = state => {
  const { App, ReviewDetails } = state;
  const {
    isDeliverySelectionEnabled,
    isPickupOnlyEnabled,
    showRegistrationAndDeliveryDisclaimer,
    isPostOrderSwap,
    isEnterpriseOrder,
    isLayoutMobile,
    isLayoutTablet,
    enableCyberpunk,
  } = App;
  const {
    isWinterWheelsIncluded = false,
    product,
    showVehicleLocationDisclaimer,
    showDeliverySelectionLocation,
    showModelYearOverviewPage = true,
    showFormattedModelYear = false,
    showLocationPrefix,
    hideVehicleTypeLabel,
    isTransportFeeEnabled,
    showVehicleDetailsSection,
    showVehicleDetailsLineItems,
    showOdometer = true,
    showInTransitStatusMetro = false,
    showModelYearVehicleDetails = false,
    showDemoVin = false,
  } = ReviewDetails;
  const { isComboInventory, isUsedInventory, data: inventoryData } = product || {};
  const { Model: modelCode } = inventoryData;
  const autopilotOpts = _get(state, 'CustomGroups.AUTOPILOT_PACKAGE.options', []);
  const configOptions = _get(inventoryData, 'OptionCodeSpecs.C_OPTS.options', []);
  const showCCSFeature = _get(state, 'ReviewDetails.showCCSFeature', false);
  const selectedOptions = getConfigurationOptions(state);
  const { optCodes = [], copy: inventoryOptionsDisclaimer } = getInventorySteeringWheelDisclaimer(state);

  const filterArr = [];
  const resultArr = configOptions.reduce((acc, item) => {
    const { code: itemCode, name: itemName = '' } = item;
    const found = autopilotOpts.includes(itemCode);
    if (found) {
      filterArr.push(item);
    } else {
      const itemMod = inventoryOptionsDisclaimer && optCodes.includes(itemCode);
      acc.push({
        ...item,
        name: itemMod ? `${itemName}*` : itemName,
      });
    }
    return acc;
  }, []);
  const featureOptions = resultArr.concat(filterArr);
  if (showCCSFeature) {
    const ccsFeatureLabel = i18n('Inventory.ccsChargingOption');
    featureOptions.push({
      code: 'CCS',
      name: ccsFeatureLabel,
      long_name: '',
      description: ccsFeatureLabel,
    });
  }
  const { MODEL_3, MODEL_Y } = Models || {};

  return {
    isComboInventory,
    isUsedInventory,
    isWinterWheelsIncluded,
    inventoryData,
    modelName: getModelName(state),
    showVehicleLocation: !isEnterpriseOrder && (!isDeliverySelectionEnabled || showDeliverySelectionLocation),
    showVehicleLocationDisclaimer,
    showModelYearOverviewPage,
    showModelYearVehicleDetails,
    showFormattedModelYear,
    showLocationPrefix,
    showDemoVin,
    hideVehicleTypeLabel,
    isInventoryVehicle: isInventory(state),
    isTransportFeeEnabled,
    transportFeeAmount: getTransportationFeeAmount(state),
    depositAmount: getDepositAmount(state),
    modelCode: getModel(state),
    isPickupOnlyEnabled,
    showDeliveryAndRegistrationDisclaimer:
      !isEnterpriseOrder && showRegistrationAndDeliveryDisclaimer,
    deliveryAndRegistrationDisclaimer: getDeliveryAndRegistrationDisclaimer({
      state,
      vehicle: inventoryData,
    }),
    deliveryDisclaimer: getDeliveryRegionDisclaimer(state),
    vehicleMapped: getVehicleMappedForLocation(state),
    vehicleSummaryProps: showVehicleDetailsSection
      ? {
          centered: false,
          unordered: true,
          titleSection: i18n('Inventory.vehicleDetails.label'),
          titleSectionClass: 'tds-text--h2 tds--vertical_padding',
          isTransportFeeEnabled: isDeliverySelectionEnabled ? false : isTransportFeeEnabled,
          isEnterpriseOrder,
        }
      : {},
    showVehicleDetailsLineItems,
    showOdometer,
    showInTransitStatusMetro: !(isEnterpriseOrder || isDeliverySelectionEnabled) && showInTransitStatusMetro,
    isPostOrderSwap,
    isEnterpriseOrder,
    featureOptions: isPostOrderSwap ? selectedOptions : featureOptions,
    additionalInfoAfterButton: inventoryOptionsDisclaimer ? [
      (
        <div className={classnames('tds-text--caption', {'tds--vertical_padding-bottom--4x': isLayoutMobile || isLayoutTablet})}>
          <sup>*</sup>{inventoryOptionsDisclaimer}
        </div>
      )] : [],
    enableCyberpunk,
    includeFutureYear: [MODEL_3, MODEL_Y].includes(modelCode),
  };
};

export default connect(mapStateToProps)(InventorySummary);
