import React from 'react';
import { connect } from 'react-redux';
import { bool, shape, string, func } from 'prop-types';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import {
  placePublicOrderFlow,
  placeOrderFlow,
  placePaymentSignOrderFlow,
  updateBillingInfo,
  updatePaymentDetails,
  togglePaymentForm,
  setPaymentFormValidFlag,
  setPaymentInstrumentType,
  completeRedirectOrderFlow,
  clearServerError,
  setSummaryPanelRegion,
  userHasApplePay,
  updateAccountDetails,
  removeLoader,
  startLoader,
  updateAccessoryShipToAddress,
  updateAccessoriesTax,
  inventory_stateToOrderWithPaymentSchema,
  stateToOrderWithPaymentSchema,
  updatePaymentInteractionRequired,
  processPostOrderSwap,
} from 'actions';
import {
  PAYMENT_TYPE_CC,
  PAYMENT_TYPE_WT,
  PAYMENT_TYPE_POS,
  SHOW_DISCLAIMER_OPT_IN_PAYX,
  CONTEXT_DEFAULT,
  CONTEXT_COMBO_NEW_INVENTORY,
  CONTEXT_CONFIGURATOR,
  CONTEXT_RESERVATION,
  CYBERHOG_IMAGE,
} from 'dictionary';
import { GIO_TAG_ENABLE } from 'gioStatistics';
import { WEB_DESIGN_PAYMENT_SUBMIT } from '../../common/gioStatistics';
import {
  getModelCode,
  getFinanceType,
  getDepositAmount,
  useModelYContent,
  getTransportationFeeAmount,
  isAccessoriesSelected,
  getCommerceTaxEndpoint,
  getCommerceTaxPayload,
  getAccessoriesTotal,
  getAccessoriesRawTotal,
  isTaxInclusive,
  isNoPostalCodeMarket,
  isCountryWithStateCode,
  isInventory,
  isUsedInventory as isUsedInventoryVehicle,
  isComboInventory,
  isEarlyDesignSelected,
  getOrderDisclaimerCopySource,
  isAccessoriesEligibleForTax,
  isReservationOrder,
  getPluginStyle,
} from 'selectors';
import { i18n, getOrderDisclaimerSource, isPreOrder, getOrderAgreementUrl, constructUrl } from 'utils';
import Payx from './payx';
import cx from 'classnames';
import Analytics from 'analytics';

const PaymentTypes = ({
  isDm,
  isNativePaymentEnabled,
  assetBaseUrl,
  legalConsentValid,
  state,
  isInventory,
  isComboInventory,
  isUsedInventory,
  isPostOrderSwap,
  showLegalConsentPostOrderSwap,
  onProcessPostOrderSwap,
  market,
  swapReservationNumber,
  ...props
}) => {
  const { locale, sibling } = props;
  const handleNativePayment = async () => {
    let schema = {};
    if (isEarlyDesignSelected(state) || isInventory) {
      schema = (await inventory_stateToOrderWithPaymentSchema({ state })) || '';
      schema.scenario = CONTEXT_COMBO_NEW_INVENTORY;
      delete schema.AccountDetails;
    } else if (isReservationOrder()) {
      schema = (await stateToOrderWithPaymentSchema({ state })) || '';
      schema.scenario = CONTEXT_RESERVATION;
    } else {
      schema = (await stateToOrderWithPaymentSchema({ state })) || '';
      schema.scenario = CONTEXT_CONFIGURATOR;
    }
    schema.isAccessoriesEligibleForTax = isAccessoriesEligibleForTax(state);
    const { registration, showInvoiceOption, charityInvoiceTypes, displayBillingBeforeShipping } =
      state?.Payment || {};
    const pickupLocations = _get(state, 'Payment.PickupLocations', []);
    const pickupLocationsFormatted = _get(state, 'Payment.PickupLocationsFormatted', []);
    const pickupLocationsProvinceFormatted = _get(
      state,
      'Payment.PickupLocationsProvinceFormatted',
      []
    );
    schema.pickupLocation = {
      pickupLocations: pickupLocations,
      pickupLocationsFormatted: pickupLocationsFormatted,
      pickupLocationsProvinceFormatted: pickupLocationsProvinceFormatted,
    };
    schema.ReviewDetails = {
      displayBillingBeforeShipping,
      registration,
      showInvoiceOption,
      charityInvoiceTypes,
    };
    schema.referralCode = _get(state, 'ApplicationFlow.referral.referralCode', '');

    schema.injections = {
      cybertruckConfirmationImage: assetBaseUrl + CYBERHOG_IMAGE,
    }

    const str = JSON.stringify(schema);
    window.ReactNativeWebView.postMessage(str);
  };

  const handleDiscardChanges = () => {
    Analytics.postSwapTagEvent('review-changes-summary:discard-changes');
    if (window?.ReactNativeWebView) {
      const message = {
        action: 'back',
        payload: true
      }
      const messageStr = JSON.stringify(message)
      window?.ReactNativeWebView?.postMessage(messageStr)
    } else {
      const swapSearchUri = `inventory/${swapReservationNumber}`;
      const url = constructUrl(swapSearchUri, sibling, locale);
      window.location = url;
    }
  }


  return (
    <>
      <Choose>
        <When condition={isPostOrderSwap}>
          <div className="group-container">
            <div className="tds-btn_group tds-btn_group--vertical">
              <button
                type="button"
                className="tds-btn tds-btn--full tds-btn--blue"
                onClick={onProcessPostOrderSwap}
                disabled={(market === 'CN' || showLegalConsentPostOrderSwap) && !legalConsentValid}
              >
                {market === 'CN'
                  ? i18n('Review.confirm_select_inventory')
                  : i18n('common.confirmChanges')}
              </button>
              <button
                type="button"
                className="tds-btn tds-btn--full tds-btn--tertiary"
                onClick={handleDiscardChanges}
              >
                {i18n('common.discard_changes')}
              </button>
            </div>
          </div>
        </When>
        <When condition={window?.ReactNativeWebView && isNativePaymentEnabled}>
          <button
            disabled={!legalConsentValid}
            type="button"
            className={cx('tds-btn tds-btn--blue tds--vertical_margin--fieldset', {
              disabled: !legalConsentValid,
            })}
            data-gio-track={GIO_TAG_ENABLE}
            data-gio-eventname={WEB_DESIGN_PAYMENT_SUBMIT}
            onClick={handleNativePayment}
          >
            {i18n('common.continue')}
          </button>
        </When>
        <Otherwise>
          <Payx {...props} isDm={isDm} market={market} isInventory={isInventory} />
        </Otherwise>
      </Choose>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    App: {
      isLayoutMobile,
      isLayoutTablet,
      enableCyberpunk,
      isTransportFeeCollectionEnabled = false,
      isDm,
      isNativePaymentEnabled,
      isPostOrderSwap,
    },
    ApplePay,
    Payment,
    Alert: { alerts },
    ReviewDetails: { isTransportFeeEnabled = false, AccountDetail, vehicleDesign, showLegalConsentPostOrderSwap },
    Accessories: { grossTotal = 0, shipToAddress },
    ApplicationFlow: { isPaymentInProgress = false, isReservationToOrderFlow = false } = {},
    Assets: { baseURL: assetBaseUrl } = {},
  } = state;

  const market = _get(state, 'OMS.oms_params.market');
  const { enabled: ap_enabled } = ApplePay;
  const isReservedPreOrder = _get(state, 'ApplicationFlow.isReservedPreOrder', false);
  const isBookedPreOrder = _get(state, 'ApplicationFlow.isBookedPreOrder', false);
  const isHideProfilePagePaymentType = market === 'CN' && (isReservedPreOrder || isBookedPreOrder);
  const { formOpen, serverErrors, showOptinDisclaimer } = Payment || {};
  let { PaymentTypes: paymentTypes = [] } = Payment;
  paymentTypes = isHideProfilePagePaymentType
    ? paymentTypes.filter(
        paymentType => [PAYMENT_TYPE_POS, PAYMENT_TYPE_WT].indexOf(paymentType) < 0
      )
    : paymentTypes;
  const { CompanyProvince = '', CompanyDistrict = '' } = AccountDetail || {};

  const modelOption = getModelCode(state);
  const modifyOrder = _get(state, 'ApplicationFlow.canModifyOrder');
  const orderPageDisclaimerCopySrc = getOrderDisclaimerCopySource(state);
  const modelCode = _get(state, 'OMS.oms_params.model');
  const showOrderPlacementDisclaimerCash = _get(
    state,
    'ReviewDetails.showOrderPlacementDisclaimerCash'
  );
  const loanType = _get(state, 'SummaryPanel.loanType', null);
  const selectedFinanceType = getFinanceType(state);
  const orderDisclaimerCash = !!(
    selectedFinanceType === 'cash' && showOrderPlacementDisclaimerCash
  );
  let orderDisclaimerSource = getOrderDisclaimerSource(orderPageDisclaimerCopySrc, modelCode, {
    orderDisclaimerCash,
    loanType,
    modifyOrder,
  });

  const isUsedInventory = isUsedInventoryVehicle(state);
  const hasTitleStatusTranslation =
    i18n(
      `SummaryPanel.disclaimers.${orderDisclaimerSource}${isUsedInventory ? '__used' : ''}`,
      null,
      null,
      {
        returnNullWhenEmpty: true,
      }
    ) !== null;

  if (hasTitleStatusTranslation) {
    orderDisclaimerSource = `${orderDisclaimerSource}${isUsedInventory ? '__used' : ''}`;
  }
  const orderFee = getDepositAmount(state, false, CONTEXT_DEFAULT);
  const transportFeeAmount = getTransportationFeeAmount(state);
  const transportFee =
    isTransportFeeEnabled && isTransportFeeCollectionEnabled ? transportFeeAmount : 0;
  const depositAmount = orderFee + transportFee;
  const accessoriesAmountWithTax = getAccessoriesTotal(state);
  const accessoriesAmount = getAccessoriesRawTotal(state);
  const totalAmount = depositAmount + accessoriesAmountWithTax;
  const rn = _get(state, 'Configuration.rn', null);
  const swapReservationNumber = _get(state, 'ReviewDetails.product.data.ReservationNumber', '');
  const { isEarlyDesignSelected, totalSwapConfig = 0, interactionTypeForSwap } = vehicleDesign || {};


  return {
    enableCyberpunk,
    orderDisclaimerSource,
    market,
    locale: _get(state, 'App.locale'),
    sibling: _get(state, 'App.sibling'),
    isWeChatMiniEnv: _get(state, 'App.isWeChatMiniEnv'),
    isWeChatBrowserEnv: _get(state, 'App.isWeChatBrowserEnv'),
    modelCode,
    model: _get(state, `OMS.lexicon.options.${modelOption}`, {}),
    isPreSale: _get(state, 'ReviewDetails.isPreSale.source', false),
    serverErrors,
    formOpen,
    payment: Payment,
    isProfileExists: _get(ownProps, 'isProfileExists', false),
    paymentTypes,
    paymentAmount: Number(parseFloat(totalAmount).toFixed(2)) || 0,
    depositAmount,
    accessoriesAmountWithTax,
    accessoriesAmount,
    currentPaymentType: _get(state, 'Payment.PaymentDetail.PaymentType', null),
    redirectPaymentDetail: _get(state, 'Payment.RedirectPaymentDetail'),
    currencyCode: _get(state, 'Payment.PaymentDetail.CurrencyCode', null),
    acceptedCreditCards: _get(state, 'Payment.acceptedCardTypes', []),
    userEmail: _get(state, 'ReviewDetails.AccountDetail.Email', null),
    accountDetail: _get(state, 'ReviewDetails.AccountDetail', {}),
    isLayoutMobile,
    isLayoutTablet,
    splitPayment: _get(state, 'Payment.splitPayment', []),
    isCCTextOnly: _get(state, 'Payment.isCCTextOnly', false),
    isShowSMSField: _get(state, 'Payment.isShowSMSField', false),
    skipPaymentInProgressCheck: _get(state, 'Payment.skipPaymentInProgressCheck', false),
    rn,
    swapReservationNumber,
    showLegalDataTable: _get(state, 'ReviewDetails.showLegalDataTable', false),
    isWTEnabled:
      isPreOrder() && _get(state, 'Payment.hideWireTransferForPreOrder', true)
        ? false
        : paymentTypes.includes(PAYMENT_TYPE_WT),
    isCCEnabled: paymentTypes.includes(PAYMENT_TYPE_CC),
    displayPaymentDisclaimer: _get(state, 'Payment.displayPaymentDisclaimer', false),
    showOptinDisclaimer,
    displayOtherEUDisclaimer: _get(state, 'Payment.displayOtherEUDisclaimer', false),
    ecoBonus: Math.abs(
      _get(
        state,
        'Pricing.calculatorResult.data.apiResults.incentives.available.government.total',
        0
      )
    ),
    billingInfo: _get(state, 'Payment.PaymentDetail.BillingInfoDetail', null),
    useProvinceFromBillingInfo: _get(state, 'Payment.useProvinceFromBillingInfo', false),
    isBurstMode: _get(state, 'App.isBurstMode', false),
    showTextForPaymentTypes: _get(state, 'Payment.showTextForPaymentTypes', false),
    useModelYContent: useModelYContent(state),
    isOldVersionOrderAgreement: _get(state, 'ReviewDetails.isOldVersionOrderAgreement', false),
    oldOrderAgreementVersion: _get(state, 'ReviewDetails.oldOrderAgreementVersion', ''),
    showVat: _get(state, 'ReviewDetails.showVAT', false),
    ap_enabled,
    hasAlerts: !!alerts.length,
    isOneTouchEnabled: ap_enabled,
    isLoaderActive: _get(state, 'Loader.active', false),
    isRnHolderFlow: !!rn && !_get(state, 'ApplicationFlow.canModifyOrder', false),
    billingAddressDetailsPayX: _get(state, 'Payment.billingAddressDetailsPayX'),
    isCryptoAllowed: _get(state, 'ApplicationFlow.isCryptoAllowed', false),
    userHasApplePay: _get(state, 'ApplePay.userHasApplePay', false),
    accountType: _get(state, 'ReviewDetails.AccountDetail.AccountType', null),
    isBusinessVerificationEnabled: _get(
      state,
      'ReviewDetails.isBusinessVerificationEnabled',
      false
    ),
    isDm,
    isPostOrderSwap,
    showLegalConsentPostOrderSwap,
    isNativePaymentEnabled,
    assetBaseUrl,
    routes: _get(state, 'App.routes', {}),
    accessoriesGrossTotal: grossTotal,
    countryCode: _get(state, 'Payment.PaymentDetail.CountryCode', null),
    hasAccessories: isAccessoriesSelected(state),
    taxUrl: getCommerceTaxEndpoint(state),
    taxPayload: getCommerceTaxPayload(state),
    orderFee,
    state,
    shipToAddress: _isEmpty(shipToAddress)
      ? {}
      : {
          ...shipToAddress,
          country: market,
        },
    isTaxInclusive: isTaxInclusive(state),
    isNoPostalCodeMarket: isNoPostalCodeMarket(state),
    isCountryWithStateCode: isCountryWithStateCode(state),
    billingDistrict: CompanyDistrict,
    billingProvince: CompanyProvince,
    orderAgreementUrl: getOrderAgreementUrl(state),
    isUsedInventory,
    isInventory: isInventory(state),
    isComboInventory: isComboInventory(state),
    totalSwapConfig,
    isEarlyDesignSelected,
    interactionTypeForSwap,
    isPaymentInProgress,
    isReservationToOrderFlow,
    isTaxApplicableForAccessories: isAccessoriesEligibleForTax(state),
    pluginStyle: getPluginStyle(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    clearServerError: () => {
      dispatch(clearServerError());
    },
    onPlacePublicOrder: (paymentObj = null) => {
      dispatch(placePublicOrderFlow(paymentObj));
    },
    onPlaceOrder: (paymentObj = null) => {
      dispatch(placeOrderFlow(paymentObj));
    },
    onPlacePaymentSignOrder: (paymentObj = null) => {
      dispatch(placePaymentSignOrderFlow(paymentObj));
    },
    setValidFlag: (valid) => {
      dispatch(setPaymentFormValidFlag(valid));
    },
    setPamentType: (type) => {
      dispatch(setPaymentInstrumentType(type));
    },
    updateBillingInfo: payload => {
      dispatch(updateBillingInfo(payload));
    },
    updatePaymentDetails: payload => {
      dispatch(updatePaymentDetails(payload));
    },
    updateAccountDetails: payload => {
      dispatch(updateAccountDetails(payload));
    },
    setSummaryPanelRegion: regionCode => {
      dispatch(setSummaryPanelRegion(regionCode));
    },
    togglePaymentForm(flag) {
      dispatch(togglePaymentForm(flag));
    },
    onCompleteRedirectFlow: (isCompleted, transactionNumber) => {
      dispatch(completeRedirectOrderFlow(isCompleted, transactionNumber));
    },
    dispatchUserHasApplePay: canMakePayments => {
      dispatch(userHasApplePay(canMakePayments));
    },
    stopLoader() {
      dispatch(removeLoader());
    },
    restartLoader() {
      dispatch(startLoader());
    },
    updateShipToAddress: payload => {
      dispatch(updateAccessoryShipToAddress(payload));
    },
    updateTaxData: payload => {
      dispatch(updateAccessoriesTax(payload));
    },
    updateInteractionRequired: payload => {
      dispatch(updatePaymentInteractionRequired(payload));
    },
    onProcessPostOrderSwap: () => {
      dispatch(processPostOrderSwap());
    },
  };
};

PaymentTypes.propTypes = {
  isDm: bool,
  isPostOrderSwap: bool.isRequired,
  showLegalConsentPostOrderSwap: bool,
  isNativePaymentEnabled: bool,
  assetBaseUrl: string,
  invitedOrderButtonDisabled: bool,
  legalConsentValid: bool,
  state: shape({}),
  isUsedInventory: bool,
  isInventory: bool,
  isComboInventory: bool,
  market: string,
  swapReservationNumber: string,
  isPayxPaymentOptionSelected: func,
};

PaymentTypes.defaultProps = {
  showLegalConsentPostOrderSwap: false,
  isPayxPaymentOptionSelected: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentTypes);
