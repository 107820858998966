import { UPDATE_INCENTIVES } from 'dictionary';
import { toggleTrimSavingsPrice } from 'actions';

export const updateIncentives = value => {
  return (dispatch, getState) => {
    const state = getState();
    const { showAfterSavingsPriceForTrims } = state?.ReviewDetails || {};
    const { userSelectedIncentives, incentives } = state?.Financial || {};
    const currentIncentives = incentives?.current ?? {};

    // When updating incentives, find out which ones are included in price and do not override them
    const includedInPriceIncentives = Object.values(currentIncentives).reduce((acc, incentive = []) => {
      const incentivesItem = incentive[0];
      return (incentivesItem.period === 'includedInPurchasePrice') ? [...acc, incentivesItem.incentiveType] : acc
    }, [])

    const currentTypes = (showAfterSavingsPriceForTrims && currentIncentives) || {};
    const selected = [...(!userSelectedIncentives ? Object.keys(currentTypes) : userSelectedIncentives), ...includedInPriceIncentives];

    const data = Array.isArray(value)
      ? value
      : selected.includes(value)
        ? selected.filter(v => v !== value)
        : [...selected, value];

    dispatch({
      type: UPDATE_INCENTIVES,
      incentives: data,
    });

    if (data?.length && Object.keys(data)?.length === Object.keys(incentives?.currentAvailable || {})?.length && !showAfterSavingsPriceForTrims) {
      return dispatch(toggleTrimSavingsPrice(true));
    } else if(!data?.length && showAfterSavingsPriceForTrims) {
      return dispatch(toggleTrimSavingsPrice(false));
    }
  };
};
