import {
  FINANCIAL_MODAL_STANDALONE,
  FINANCIAL_MODAL_CN,
  FIN_MODAL_CUSTOMIZE,
  MODAL_MEDIUM,
  FinanceTypes,
} from 'dictionary';
import { bool, number, shape, string } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import {
  getFinanceTabs,
  getSelectedFinanceProduct,
  getPricingSummary,
  getVATAmount,
} from 'selectors';
import { i18n } from 'utils';
import FinanceDisclaimer from '../../GroupComponents/FinanceDisclaimer';
import ModalTrigger from '../../ModalTrigger';
import PricingDisclaimers from './PricingDisclaimers';
import BannerModalTrigger from './BannerModalTrigger';
import { formatCurrency } from '@tesla/coin-common-components';
import { getFinanceType, getPrice } from '../../../common/selectors';
import BriefCalculatedResult from './BriefCalculatedResult';
import classNames from 'classnames';
import _get from 'lodash/get';

const FinanceTabs = ({
  incentives,
  paymentLabel,
  formattedPurchasePrice,
  formattedMonthlyPaymentPrice,
  formattedTotalPriceWithoutIncentives,
  financeProductId,
  vatDisclaimer,
  reviewRefactor,
  financeType,
}) => {
  const componentName = reviewRefactor ? FINANCIAL_MODAL_CN : FINANCIAL_MODAL_STANDALONE;
  return (
    <div className="tab-content">
      <div className="pricing-container tds-o-padding_bottom-24">
        <div
          className={classNames('price-block', {
            'tds-o-flex-direction--column tds-flex-gap--8': !reviewRefactor,
          })}
        >
          <Choose>
            <When condition={reviewRefactor}>
              <div className="finance-section--wrapper tds-o-padding_bottom-8">
                <ul className="tds-list">
                  <li
                    className={cx('tds-list-item', {
                      'tds-text--h4': financeType === FinanceTypes.CASH,
                    })}
                  >
                    <span className="left tds-text_color--10 tds-o-padding_right-8">
                      {i18n('common.purchasePrice')}
                    </span>
                    <span className="tds-text--end tds-text_color--10">
                      {formattedTotalPriceWithoutIncentives}
                    </span>
                  </li>
                  <If condition={financeType !== FinanceTypes.CASH}>
                    <li className="tds-list-item tds-text--h4">
                      <span className="left tds-text--medium tds-text_color--10 tds-o-padding_right-8">
                        {paymentLabel}
                      </span>
                      <span className="tds-text--end tds-text--medium tds-text_color--10">
                        {formattedMonthlyPaymentPrice}
                      </span>
                    </li>
                  </If>
                  <If condition={incentives?.key}>
                    <li className="tds-list-item" data-id="tax-credit-line-item">
                      <span className="left tds-text--caption tds-o-padding_right-8">
                        <BannerModalTrigger
                          contentSource={incentives?.key}
                          label={incentives?.label}
                        />
                      </span>
                      <span className="tds-text--caption">{incentives?.formattedPrice}</span>
                    </li>
                  </If>
                </ul>
                <PricingDisclaimers />
                <If condition={vatDisclaimer && !financeProductId}>
                  <p className="tds-text--caption">{vatDisclaimer}</p>
                </If>
              </div>
              <If condition={financeType !== FinanceTypes.CASH}>
                <BriefCalculatedResult financeType={financeType} />
              </If>
            </When>
            <Otherwise>
              <div className="finance-section--wrapper">
                <ul className="tds-list">
                  <li className="tds-list-item tds-text--h4">
                    <span className="left tds-text--medium tds-text_color--10 tds-o-padding_right-8">
                      {paymentLabel}
                    </span>
                    <span className="tds-text--end tds-text--medium tds-text_color--10">
                      {formattedPurchasePrice}
                    </span>
                  </li>
                  <If condition={incentives?.key && incentives?.label}>
                    <li
                      className="tds-list-item tds-o-margin_bottom"
                      data-id="tax-credit-line-item"
                    >
                      <span className="left tds-text--caption tds-o-padding_right-8">
                        <BannerModalTrigger
                          contentSource={incentives?.key}
                          label={incentives?.label}
                        />
                      </span>
                      <span className="tds-text--caption">{incentives?.formattedPrice}</span>
                    </li>
                  </If>
                </ul>
                <PricingDisclaimers />
                <If condition={vatDisclaimer && !financeProductId}>
                  <p className="tds-text--caption">{vatDisclaimer}</p>
                </If>
              </div>
              <FinanceDisclaimer className="tds-text--caption finance-disclaimer--condensed" />
            </Otherwise>
          </Choose>

          <ModalTrigger
            analyticsInteraction={FIN_MODAL_CUSTOMIZE}
            options={{
              props: {
                componentName: componentName,
                props: {
                  genericWrapper: true,
                  size: MODAL_MEDIUM,
                  classes: 'tds-link',
                  isMega: true,
                },
              },
            }}
          >
            <p className="tds-text--caption">{i18n('Review.explore_financing')}</p>
          </ModalTrigger>
          <If condition={reviewRefactor}>
            <FinanceDisclaimer
              className="tds-text--caption finance-disclaimer--condensed"
              reviewRefactor={reviewRefactor}
              financeType={financeType}
            />
          </If>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const financeTabs = getFinanceTabs(state);
  const selectedTab = getSelectedFinanceProduct(state);
  const financeType = getFinanceType(state);
  const { financeProductId } = financeTabs.find(tab => tab?.id === selectedTab) || {};
  const {
    ReviewDetails: { showVAT = false, showMyVAT = false, reviewRefactor = false },
  } = state;
  const pricingSummaryObj = getPricingSummary(state);

  let paymentLabel = pricingSummaryObj?.isMonthly
    ? i18n('SummaryPanel.est_payment_label__finplat', null, null, {
        specificOverride: financeProductId,
      })
    : i18n('Review.est_purchase_price');

  let vatDisclaimer =
    showVAT &&
    i18n('SummaryPanel.disclaimers.vatAmount', {
      VAT_AMOUNT: formatCurrency(getVATAmount(state)),
    });
  if (showMyVAT) {
    vatDisclaimer = i18n('SummaryPanel.disclaimers.myVatAmount');
  }

  const formattedTotalPriceWithoutIncentives = formatCurrency(_get(state, 'Pricing.total', 0));
  let formattedMonthlyPaymentPrice;
  if (reviewRefactor) {
    if (financeType === FinanceTypes.LEASE) {
      paymentLabel = i18n('SummaryPanel.payment__lease');
      formattedMonthlyPaymentPrice = getPrice(state, 'lease');
    }
    if (financeType === FinanceTypes.LOAN) {
      paymentLabel = i18n('SummaryPanel.payment__loan');
      formattedMonthlyPaymentPrice = getPrice(state, 'loan');
    }
  }

  return {
    paymentLabel,
    financeProductId,
    vatDisclaimer,
    financeType,
    reviewRefactor,
    formattedMonthlyPaymentPrice,
    formattedTotalPriceWithoutIncentives,
    ...pricingSummaryObj,
  };
}

FinanceTabs.propTypes = {
  paymentLabel: string,
  financeProductId: string,
  formattedPurchasePrice: string.isRequired,
  formattedMonthlyPaymentPrice: string,
  formattedTotalPriceWithoutIncentives: string,
  incentives: shape({
    price: number,
    key: string,
    label: string,
  }),
  vatDisclaimer: string,
  financeType: string,
  reviewRefactor: bool,
};

FinanceTabs.defaultProps = {
  paymentLabel: '',
  financeProductId: null,
  monthlyPayment: {
    price: 0,
    key: '',
    label: '',
  },
  vatDisclaimer: '',
  financeType: FinanceTypes.CASH,
  reviewRefactor: false,
};

export default connect(mapStateToProps)(FinanceTabs);
