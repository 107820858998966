import React from 'react';
import { connect } from 'react-redux';
import { IncentivesModal, IncentivesSavings } from '@tesla/coin-common-components';
import {
  getEligibleIncentives,
  getRegionName,
  getPostalCode,
  getFuelDistance,
  getFuelPrice,
  getElectricityPrice,
  getFuelEfficiency,
  getFuelYears,
  getModelName,
  hasDistanceField,
  getFinanceType
} from 'selectors';
import { UPDATE_INCENTIVES, FinanceTypes } from 'dictionary';
import { showCombinedSavings, getGeoLocation, setSummaryPanelFuelParams } from 'actions';
import { isNAMarket } from 'utils';

const IncentivesSavingsContainer = ({ standalone, ...props }) => {

  const Component = standalone ? IncentivesSavings : IncentivesModal;

  return <Component {...props} />
};

function mapStateToProps(state) {
  const { incentives, showCombinedSavings } = state?.Financial || {};
  const { gasSavingsCap = {}, showGasSavingsFuelYear } = state?.FinancingOptions || {};
  const { current } = incentives || {};
  const { strings } = state?.Locale?.i18n?.find_my_tesla || {};
  const { market } = state?.OMS?.oms_params || {};
  const { IncentivesSavings, GasSavings } = strings || {};
  const { locale } = state?.App || {};
  const descriptionParams = state?.Pricing?.calculatorResult?.data?.apiResults?.incentives?.current?.fuel?.data?.[0]?.variables?.disclaimer;
  const financeType = getFinanceType(state);

  return {
    incentives: getEligibleIncentives(state, { incentives: ['fuel'] }),
    selectedIncentives: Object.keys(current),
    open: showCombinedSavings,
    showCombinedSavings,
    regionName: getRegionName(state),
    postalCode: getPostalCode(state),
    incentivesCriteria: state?.Banner?.bannerContentParsed || [],
    i18nStrings: {
      IncentivesSavings,
      GasSavings,
    },
    market,
    locale,
    gasSavingsCap,
    selectedDistanceValue: getFuelDistance(state),
    selectedFuelPriceValue: getFuelPrice(state),
    selectedElectricityPriceValue: getElectricityPrice(state),
    selectedFuelEfficiencyValue: getFuelEfficiency(state),
    numYears: getFuelYears(state),
    modelName: getModelName(state),
    showMonthlyFuel: financeType !== FinanceTypes.CASH || !showGasSavingsFuelYear,
    showDistanceInput: !hasDistanceField(state),
    isNAMarket: isNAMarket(market),
    descriptionParams,
  };
}

const mapDispatchToProps = dispatch => ({
  onChange: incentives =>
    dispatch({
      type: UPDATE_INCENTIVES,
      incentives,
    }),
  onClose: () => dispatch(showCombinedSavings()),
  onPostalChange: zipCode => dispatch(getGeoLocation(zipCode)),
  setFuelSavings: params => dispatch(setSummaryPanelFuelParams(params)),
});

IncentivesSavingsContainer.propTypes = {};

IncentivesSavingsContainer.defaultProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(IncentivesSavingsContainer);
