const { isAccessoriesEnabled = false, showRecommendedAccessories = false, App = {} } = window?.tesla || {};
const { isCoinReloaded = false } = App || {};

export const InitialState = {
  // reservation flow
  reservation: {
    allowedSections: ['payment'],
    section: 'payment',
    main: [
      {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main.BATTERY_AND_DRIVE',
        group: 'BATTERY_AND_DRIVE',
        sections: ['payment'],
      },
      {
        component: 'PaymentOverview',
        key: 'PAYMENT',
        sections: ['payment'],
      },
    ],
    gallery: [
      {
        component: 'MainGallery',
        key: 'MainGallery:gallery.EXTERIOR',
        group: 'EXTERIOR_GALLERY',
        sections: ['payment'],
      },
    ],
  },
  // reservation to order flow
  order: {
    allowedSections: ['overview', 'payment'],
    section: 'overview',
    activeGroupId: isCoinReloaded ? 'BATTERY_AND_DRIVE' : 'BATTERY_AND_DRIVE_MOTOR_GROUP',
    main: isCoinReloaded ? [
      {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main.BATTERY_AND_DRIVE',
        group: 'BATTERY_AND_DRIVE',
        sections: ['overview'],
      },
      (isAccessoriesEnabled ? {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main.ELECTRONIC_ACCESSORIES',
        group: 'ELECTRONIC_ACCESSORIES',
        sections: ['overview'],
      } : {}),
      {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main.WHEELS',
        sections: ['overview'],
        group: 'WHEELS',
      },
      {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main.INTERIOR',
        sections: ['overview'],
        group: 'INTERIOR'
      },
      {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main.AUTOPILOT_PACKAGE',
        sections: ['overview'],
        group: 'AUTOPILOT_PACKAGE',
      },
      {
        component: 'AccessoriesGroup',
        key: 'CHARGING_ACCESSORIES',
        group: 'CHARGING_ACCESSORIES',
        sections: ['overview'],
        props: {
          showAsset: true,
          classes: 'tds--align_center',
          productType: 'ACCESSORY',
        },
      },
      {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main.POWER_YOUR_HOME',
        sections: ['overview'],
        group: 'POWER_YOUR_HOME'
      },
      (showRecommendedAccessories ? {
        component: 'AccessoriesGroup',
        key: 'RECOMMENDED_ACCESSORIES',
        group: 'RECOMMENDED_ACCESSORIES',
        sections: ['overview'],
        props: {
          classes: 'tds--align_center',
          listType: 'recommended',
          productType: 'ACCESSORY',
        },
      } : {}),
      {
        component: 'PaymentOverviewTrigger',
        key: 'OPTIONS',
        sections: ['overview'],
        props: {
          classes: 'tds--align_center',
        },
      },
      {
        component: 'PaymentSummary',
        group: 'ORDER',
        key: 'PAYMENT',
        sections: ['payment'],
      }
      ] : [
      {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main.BATTERY_AND_DRIVE_MOTOR_GROUP',
        group: 'BATTERY_AND_DRIVE_MOTOR_GROUP',
        sections: ['overview'],
      },
      (isAccessoriesEnabled ? {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main.ELECTRONIC_ACCESSORIES',
        group: 'ELECTRONIC_ACCESSORIES',
        sections: ['overview'],
        props: {
          classes: "tds-theme--replicant-dotted-bg",
        }
      } : {}),
      {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main.VEHICLE_FEATURES',
        sections: ['overview'],
        group: 'VEHICLE_FEATURES',
        props: {
          classes: "tds-theme--replicant-dotted-bg",
        }
      },
      {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main.EXTERIOR',
        sections: ['overview'],
        group: 'EXTERIOR',
        props: {
          classes: "tds-theme--replicant-dotted-bg",
        }
      },
      {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main.WHEELS',
        sections: ['overview'],
        group: 'WHEELS',
        props: {
          classes: "tds-theme--replicant-dotted-bg",
        }
      },
      {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main.INTERIOR',
        sections: ['overview'],
        group: 'INTERIOR',
        props: {
          classes: "tds-theme--replicant-dotted-bg",
        }
      },
      {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main.AUTOPILOT_PACKAGE',
        sections: ['overview'],
        group: 'AUTOPILOT_PACKAGE',
        props: {
          classes: "tds-theme--replicant-dotted-bg",
        }
      },
      {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main.SIGNATURE_PACKAGE',
        sections: ['overview'],
        group: 'SIGNATURE_PACKAGE',
        props: {
          classes: "tds-theme--replicant-dotted-bg",
        }
      },
      {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main.ACCESSORIES_PACKAGE',
        sections: ['overview'],
        group: 'ACCESSORIES_PACKAGE',
        props: {
          classes: "tds-theme--replicant-dotted-bg",
        }
      },
      {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main.LIGHTBAR',
        sections: ['overview'],
        group: 'LIGHTBAR',
        props: {
          classes: "tds-theme--replicant-dotted-bg",
        }
      },
      {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main:CHARGING',
        sections: ['overview'],
        group: 'CHARGING',
        props: {
          classes: "tds-theme--replicant-dotted-bg",
        }
      },
      {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main.POWER_YOUR_HOME',
        sections: ['overview'],
        group: 'POWER_YOUR_HOME',
        props: {
          classes: "tds-theme--replicant-dotted-bg",
        }
      },
      {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main:PAYMENT_OVERVIEW',
        sections: ['overview'],
        group: 'PAYMENT_OVERVIEW',
      },
      {
        component: 'EditDesignLink',
        key: 'EditDesignLink:main.PAYMENT',
        sections: ['payment'],
        props: {
          classes: 'tds-content_container tds--padding tds--no_vertical_padding',
        },
      },
      {
        component: 'AssetLoader',
        key: 'PAYMENT.asset',
        sections: ['payment'],
        props: {
          asset: {
            bkba: 4,
            type: 'image-compositor',
            view: 'REAR34',
            crop: [1400, 800, 230, 130],
          },
          className: 'group-main-content--full-asset ',
          isAsset: true,
        },
        selected_by: {
          or: [
            {
              on: ['tablet', 'mobile'],
            },
          ],
        },
      },
      {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main.REVIEW_ORDER',
        group: 'REVIEW_ORDER',
        sections: ['payment'],
        props: {
          classes: 'cf-content_container',
        },
      },
      {
        component: 'PaymentOverview',
        key: 'PAYMENT',
        sections: ['payment'],
        props: {
          classes: 'tds-content_container'
        }
      },
    ],
    gallery: isCoinReloaded ? [
        {
          component: 'MainGallery',
          key: 'MainGallery:gallery.FSD',
          group: 'FSD_GALLERY',
          excludeGroup: 'BATTERY_AND_DRIVE',
          sections: ['overview', 'payment'],
        },
        {
          component: 'MainGallery',
          key: 'MainGallery:gallery.EXTERIOR',
          group: 'EXTERIOR_GALLERY',
          sections: ['overview', 'payment'],
        },
      ] : [
      {
        component: 'MainGallery',
        key: 'MainGallery:gallery.FSD',
        group: 'FSD_GALLERY',
        excludeGroup: 'BATTERY_AND_DRIVE_MOTOR_GROUP',
        sections: ['overview'],
      },
      {
        component: 'MainGallery',
        key: 'MainGallery:gallery.EXTERIOR',
        group: 'EXTERIOR_GALLERY',
        sections: ['overview'],
      },
      {
        component: 'MainGallery',
        key: 'MainGallery:gallery.EXTERIOR',
        group: 'EXTERIOR_GALLERY',
        gallery: {
          group: 'EXTERIOR_GALLERY',
          section: 2,
        },
        sections: ['payment'],
      },
    ],
  },
  
};
export default InitialState;
