import React from 'react';
import classnames from 'classnames';
import { string, arrayOf, bool, node, shape, oneOfType, func } from 'prop-types';
import { MODAL_SMALL } from 'dictionary';
import _get from 'lodash/get';
import ModalTrigger from '../ModalTrigger';
import TextLoader from '../TextLoader';
import DeliveryLocationLink from '../DeliveryLocationLink';
import IconCustom from '../GroupComponents/Icon';
import { Icon, Card } from '@tesla/design-system-react';
import { iconPdf } from '@tesla/design-system-icons';

const InfoBlock = ({
  additionalInfoAfterButton,
  additionalInfoBeforeButton,
  buttonClass,
  buttonHandler,
  buttonLabel,
  link,
  linkClass,
  linkLabel,
  showButtonFirst,
  showLinkFirst,
  grid,
  centered,
  children,
  componentName,
  containerClass,
  contents,
  contentsClass,
  groupName,
  itemClass,
  name,
  secondaryTitle,
  secondaryTitleClass,
  title,
  titleClass,
  type,
  unordered,
  analyticsInteraction,
  isInventoryVehicle,
  isPickupOnlyEnabled,
  isTransportFeeEnabled,
  showDeliveryAndRegistrationDisclaimer,
  deliveryAndRegistrationDisclaimer,
  titleSection,
  titleSectionClass,
  cardTitle,
  cardTitleLink,
  additionalInfoAfterButtonClass,
  isEnterpriseOrder,
}) => {
  // TODO: set orderpayment copy for non transportation fee countries
  return (
    <div
      className={classnames(`${name}-info-block option-widget--container`, {
        [containerClass]: containerClass,
      })}
    >
      {/* Title Section */}
      <If condition={title}>
        <TextLoader
          data={title}
          tag={{ component: 'h1' }}
          className={classnames(`tds-text--center tds-o-vertical_padding-top ${name}-main-title`, {
            'tds-text--h2': !titleClass,
            [titleClass]: titleClass,
          })}
        />
      </If>
      <If condition={titleSection}>
        <TextLoader
          data={titleSection}
          tag={{ component: 'h2' }}
          className={classnames(`tds-text--center tds-o-vertical_padding-top ${name}-main-title`, {
            'tds-text--h2': !titleClass,
            [titleSectionClass]: titleSectionClass,
          })}
        />
      </If>
      <If condition={secondaryTitle}>
        <TextLoader
          data={secondaryTitle}
          className={classnames(
            `tds-text--center tds-o-vertical_padding--1x ${name}-secondary-title`,
            {
              'tds-text--h5': !secondaryTitleClass,
              [secondaryTitleClass]: secondaryTitleClass,
            }
          )}
        />
      </If>

      {/* Content Section */}
      <div
        className={classnames(`info-block-content tds-list`, {
          'tds-text--center': centered,
          [contentsClass]: contentsClass,
        })}
      >
        <If condition={contents}>
          <If condition={typeof contents === 'string'}>{contents}</If>
          <If condition={Array.isArray(contents)}>
            <ol className={classnames('tds-list', { 'tds-list--unordered': unordered })}>
              {contents.map(content => {
                if (typeof content === 'string') {
                  return (
                    <li
                      className={classnames('tds-list-item', {
                        [itemClass]: itemClass,
                      })}
                      key={Math.random()}
                    >
                      <TextLoader data={content} />
                    </li>
                  );
                }
                if (typeof content === 'object') {
                  const {
                    class: classes = '',
                    name: itemName = '',
                    description = '',
                    text = '',
                    code = '',
                    componentName: modalName,
                    analytics = '',
                    href = '',
                    id = '',
                    onClick,
                    link = '',
                  } = content || {};
                  const longName = _get(content, 'long_name.content') || _get(content, 'long_name') ;
                  return (
                    <li
                      className={classnames('tds-list-item', {
                        [itemClass]: itemClass,
                        [classes]: classes,
                      })}
                      key={code}
                    >
                      <Choose>
                        <When condition={href}>
                          <a
                            className="tds-link"
                            target="_blank"
                            id={id}
                            tabIndex={0}
                            href={href}
                            rel="noopener noreferrer"
                            onClick={onClick}
                          >
                            {text}
                          </a>
                        </When>
                        <When condition={modalName}>
                          <ModalTrigger
                            groupName={groupName}
                            options={{
                              props: {
                                componentName: modalName,
                                props: {
                                  genericWrapper: true,
                                  size: MODAL_SMALL,
                                  content,
                                },
                              },
                            }}
                            analyticsInteraction={analytics}
                          >
                            <TextLoader
                              data={text}
                              className="tds-text--start tds-text--regular tds-text_color--30"
                            />
                          </ModalTrigger>
                        </When>
                        <When condition={link}>
                          <TextLoader data={link} analyticsInteraction={analytics} />
                        </When>
                        <Otherwise>{longName || itemName || description || text}</Otherwise>
                      </Choose>
                    </li>
                  );
                }
                return null;
              })}
            </ol>
          </If>
        </If>
        <If condition={!isEnterpriseOrder && isInventoryVehicle && isTransportFeeEnabled}>
          <div>
            <If condition={!isPickupOnlyEnabled}>
              <p className="tds-text_color--black tds--vertical_padding-bottom">
                <DeliveryLocationLink />
              </p>
            </If>
          </div>
        </If>

        <If condition={showDeliveryAndRegistrationDisclaimer && deliveryAndRegistrationDisclaimer}>
          <div className="tds--vertical_padding deliveryAndRegistrationDisclaimer">
            <IconCustom id="tds-info" className="deliveryAndRegistrationDisclaimerIcon" />
            <p className="tds-text--caption tds-text_color--black deliveryAndRegistrationDisclaimerText">
              {deliveryAndRegistrationDisclaimer}
            </p>
          </div>
        </If>

        <If condition={children}>{children}</If>
      </div>
      {/* Footer Section */}
      <If condition={additionalInfoBeforeButton}>
        <div className="info-block-additional-info tds-o-flex_no-wrap">
          {additionalInfoBeforeButton.map(info => (
            <>
              <IconCustom id="tds-info" style={{ width: '30px', height: '35px' }} />
              <p className="tds-text--caption tds-text_color--black" key={Math.random()}>
                {info}
              </p>
            </>
          ))}
        </div>
      </If>
      <div className={classnames('info-block-buttons', { 'info-block-grid': grid })}>
        <If condition={(type && groupName) || (buttonLabel && componentName)}>
          <ModalTrigger
            type={type}
            groupName={groupName}
            options={{
              props: {
                componentName,
                props: {
                  genericWrapper: true,
                  size: MODAL_SMALL,
                  containerCss: 'tds-flex tds-flex--justify-center tds--vertical_padding',
                  isTriggerFromButton: true,
                },
              },
            }}
            analyticsInteraction={analyticsInteraction}
          >
            <span className={classnames('tds-btn', { [buttonClass]: buttonClass })}>
              {buttonLabel}
            </span>
          </ModalTrigger>
        </If>
        <If condition={buttonLabel && buttonHandler}>
          <div
            className={classnames(
              'tds-flex tds-flex--justify-center',
              'tds-flex--center',
              'tds--vertical_padding',
              { 'grid-item-1': showButtonFirst }
            )}
          >
            <button
              type="button"
              className={classnames('tds-btn', { [buttonClass]: buttonClass })}
              aria-label={buttonLabel}
              onClick={buttonHandler}
            >
              {buttonLabel}
            </button>
          </div>
        </If>
        <If condition={link && linkLabel}>
          <div
            className={classnames(
              'tds-flex',
              'tds-flex--justify-center',
              'tds-flex--center',
              'tds--vertical_padding',
              { 'grid-item-1': showLinkFirst }
            )}
          >
            <a
              className={classnames('tds-btn', 'tds-btn--medium', {
                [linkClass]: linkClass,
              })}
              aria-label={linkLabel}
              href={link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {linkLabel}
            </a>
          </div>
        </If>
        <If condition={cardTitleLink && cardTitle}>
          <div className={classnames('tds--vertical_padding')}>
            <Card variant="full-width" icon={<Icon data={iconPdf} />}>
              <a
                className="tds-link tds-text--medium"
                href={cardTitleLink}
                target="_blank"
                role="button"
                rel="noopener noreferrer"
              >
                {cardTitle}
              </a>
            </Card>
          </div>
        </If>
      </div>

      <If condition={additionalInfoAfterButton}>
        <div className="info-block-additional-info">
          {additionalInfoAfterButton.map(info => (
            <p
              className={classnames({
                [additionalInfoAfterButtonClass]: additionalInfoAfterButtonClass,
              })}
              key={Math.random()}
            >
              {info}
            </p>
          ))}
        </div>
      </If>
    </div>
  );
};

InfoBlock.propTypes = {
  additionalInfoAfterButton: arrayOf(string),
  additionalInfoBeforeButton: arrayOf(string),
  buttonClass: string,
  buttonHandler: func,
  buttonLabel: string,
  grid: bool,
  link: string,
  linkClass: string,
  linkLabel: string,
  showButtonFirst: bool,
  showLinkFirst: bool,
  centered: bool,
  children: node,
  componentName: string,
  containerClass: string,
  contents: arrayOf(oneOfType([string, shape({})])),
  contentsClass: string,
  groupName: string,
  itemClass: string,
  name: string,
  secondaryTitle: string,
  secondaryTitleClass: string,
  title: string,
  titleClass: string,
  type: string,
  unordered: bool,
  analyticsInteraction: string,
  isInventoryVehicle: bool,
  isPickupOnlyEnabled: bool,
  isTransportFeeEnabled: bool,
  showDeliveryAndRegistrationDisclaimer: bool,
  deliveryAndRegistrationDisclaimer: string,
  titleSection: string,
  titleSectionClass: string,
  cardTitle: string,
  cardTitleLink: string,
  additionalInfoAfterButtonClass: string,
  isEnterpriseOrder: bool,
};

InfoBlock.defaultProps = {
  additionalInfoAfterButton: null,
  additionalInfoBeforeButton: null,
  buttonClass: '',
  buttonHandler: null,
  buttonLabel: '',
  grid: false,
  link: '',
  linkClass: '',
  linkLabel: '',
  showButtonFirst: false,
  showLinkFirst: false,
  centered: false,
  children: null,
  componentName: '',
  containerClass: '',
  contents: null,
  contentsClass: '',
  groupName: '',
  itemClass: '',
  name: '',
  secondaryTitle: '',
  secondaryTitleClass: '',
  title: '',
  titleClass: '',
  type: '',
  unordered: false,
  analyticsInteraction: '',
  isInventoryVehicle: false,
  isPickupOnlyEnabled: false,
  isTransportFeeEnabled: false,
  showDeliveryAndRegistrationDisclaimer: false,
  deliveryAndRegistrationDisclaimer: '',
  titleSection: '',
  titleSectionClass: '',
  cardTitle: '',
  cardTitleLink: '',
  additionalInfoAfterButtonClass: '',
  isEnterpriseOrder: false,
};

export default InfoBlock;
