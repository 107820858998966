import React from 'react';
import { string, number, shape, arrayOf, bool, oneOfType, func } from 'prop-types';
import { connect } from 'react-redux';
import { formatCurrency } from '@tesla/coin-common-components';
import {
  getDepositAmount,
  getDestinationAndDocFee,
  getModelCode,
  getExtraPricingContextAmounts,
  getExtraCurrencyAmount,
  getAmountInOtherCurrencies,
  getAcquisitionFee,
  getInspectionFee,
  getLuxuryTax,
  getFeesTotal,
  getRegionalTireFee,
  getDeliveryCredit,
  displayTransportationFeeAmount,
  getTransportationFeeAmount,
  getDeliveryPostalCode,
  getPurchasePriceIncentive,
  getGovernmentIncentives,
  getRegionalIncentiveInPurchasePrice,
  getDeliveryFee,
  getReservationCredit,
  getOrderFeeFromFinancialSvc,
  getTaxCredit,
  getVeteranCredit,
} from 'selectors';
import { i18n } from 'utils';
import _get from 'lodash/get';
import { ORDER_FEE_TYPE, FINANCE_MODAL_TAB_TRADEIN, FINANCE_CAROUSEL } from 'dictionary';
import TaxesAndFeesDisclaimer from './TaxesAndFeesDisclaimer';
import CurrencyDisclaimer from '../../../components/Forms/FinancingOptions/CurrencyDisclaimer';
import PriceValidDisclaimer from './PriceValidDisclaimer';
import cx from 'classnames';
import { selectTab } from 'actions';
import ModalTrigger from '../../../components/ModalTrigger';

const VehiclePricePlusFees = ({
  destinationAndDocFee,
  orderFee,
  vehiclePrice,
  modelName,
  extraCurrency,
  firstRegistrationTax,
  extraPricingContextAmount,
  amountInOtherCurrencies,
  includeOrderFeeInVehicleTotal,
  acquisitionFee,
  inspectionFee,
  luxuryTax,
  feesTotal,
  yourModelPriceLabel,
  region,
  tireFee,
  formatWithPrecision,
  deliveryCredit,
  transportationFee,
  displayTransportFee,
  deliveryPostalCode,
  purchasePriceIncentive,
  govtIncentive,
  regionalIncentiveInPurchasePrice,
  referral,
  creditCash,
  deliveryFee,
  enableCyberpunk,
  reservationCredit,
  grossPriceAfterTaxCredit,
  vehiclePriceWithoutDiscounts,
  inventoryDiscount,
  isInventoryPriceAdjustmentsEnabled,
  isPostOrderSwap,
  taxCredit,
  tradeInAmount,
  selectFinanceTab,
  vehicleYear,
  vehicleMake,
  vehicleModel,
  veteranCredit,
  vehiclePriceWithVeteranCredit,
}) => {
  const orderFeeAmount = amountInOtherCurrencies.length
    ? amountInOtherCurrencies.map(amn => amn.amount)[0]
    : orderFee;
  const orderFeeAmountWithCurrency = extraPricingContextAmount
    ? ` ${extraPricingContextAmount}`
    : formatCurrency(orderFee);
  const yourModelPrice =
    vehiclePrice +
    orderFeeAmount +
    feesTotal +
    transportationFee +
    purchasePriceIncentive -
    creditCash +
    reservationCredit;
  const yourModelPriceWithCredit = yourModelPrice + deliveryCredit;
  const validityDisclaimer =
    i18n('Referral.criteria.validity', null, null, {
      returnNullWhenEmpty: true,
    }) ?? '';

  return (
    <div className={cx('tds--vertical_padding--large pricing-container')}>
      <div className="price-block">
        <ul className="tds-list">
          <If condition={vehiclePrice !== yourModelPrice}>
            <div className="pricing--separater-after">
              <Choose>
                <When condition={isInventoryPriceAdjustmentsEnabled && inventoryDiscount}>
                  <li
                    className="tds-list-item tds-text_color--10"
                    data-id="vehicle-price-line-item"
                  >
                    <span className="left">
                      {i18n('Review.vehicle_price')}
                      <If condition={veteranCredit}>
                        <br />
                        <span className="tds-text--contrast-low">
                          {i18n('Review.discount_included__veteran')}
                        </span>
                      </If>
                    </span>
                    <span>{formatCurrency(vehiclePriceWithoutDiscounts)}</span>
                  </li>
                  <li className="tds-list-item tds-text_color--10">
                    <span className="left">{i18n('Review.price_adjustment_label')}</span>
                    <span>{formatCurrency(-inventoryDiscount)}</span>
                  </li>
                </When>
                <When condition={veteranCredit}>
                  <li
                    className="tds-list-item tds-text_color--10"
                    data-id="vehicle-price-line-item"
                  >
                    <span className="left">{i18n('Review.vehicle_price')}
                    <If condition={veteranCredit}>
                        <br />
                        <span className="tds-text--contrast-low tds-text--caption tds-text--body">
                          {i18n('Review.discount_included__veteran')}
                        </span>
                      </If>
                    </span>
                    <span>{formatCurrency(vehiclePriceWithVeteranCredit)}</span>
                  </li>
                </When>
                <Otherwise>
                  <Choose>
                    <When condition={isInventoryPriceAdjustmentsEnabled && inventoryDiscount}>
                      <li
                        className="tds-list-item tds-text_color--10"
                        data-id="vehicle-price-line-item"
                      >
                        <span className="left">{i18n('Review.vehicle_price')}</span>
                        <span>{formatCurrency(vehiclePriceWithoutDiscounts)}</span>
                      </li>
                      <li className="tds-list-item tds-text_color--10">
                        <span className="left">{i18n('Review.price_adjustment_label')}</span>
                        <span>{formatCurrency(-inventoryDiscount)}</span>
                      </li>
                    </When>
                    <Otherwise>
                      <li
                        className="tds-list-item tds-text_color--10"
                        data-id="vehicle-price-line-item"
                      >
                        <span className="left">{i18n('Review.vehicle_price')}</span>
                        <span>{formatCurrency(vehiclePrice)}</span>
                      </li>
                    </Otherwise>
                  </Choose>
                </Otherwise>
              </Choose>
              <If condition={firstRegistrationTax}>
                <li className="tds-list-item tds-text_color--10">
                  <span className="left">
                    {i18n('SummaryPanel.disclaimers.estFirstRegistrationTax')}
                  </span>
                  <span>{formatCurrency(firstRegistrationTax)}</span>
                </li>
              </If>
              <If condition={destinationAndDocFee}>
                <li
                  className="tds-list-item tds-text_color--10"
                  data-id="destination-and-doc-fee-line-item"
                >
                  <span className="left">{i18n('SummaryPanel.destination_fee')}</span>
                  <span>{formatCurrency(destinationAndDocFee)}</span>
                </li>
              </If>
              <If condition={deliveryFee}>
                <li
                  className="tds-list-item tds-text_color--10"
                  data-id="destination-and-doc-fee-line-item"
                >
                  <span className="left">{i18n('SummaryPanel.delivery_fee')}</span>
                  <span>{formatCurrency(deliveryFee)}</span>
                </li>
              </If>
              <If condition={acquisitionFee}>
                <li
                  className="tds-list-item tds-text_color--10"
                  data-id="air-conditioning-tax-item"
                >
                  <span className="left">{i18n('SummaryPanel.disclaimers.aquisitionFee')}</span>
                  <span>{formatCurrency(acquisitionFee)}</span>
                </li>
              </If>
              <If condition={inspectionFee}>
                <li className="tds-list-item tds-text_color--10" data-id="state-fee-line-item">
                  <span className="left">
                    {i18n('SummaryPanel.disclaimers.inspectionFee', null, null, {
                      specificOverride: [region],
                      returnNullWhenEmpty: true,
                      specificOverrideOperator: 'OR',
                    })}
                  </span>
                  <span>{formatCurrency(inspectionFee, { useDynamicRounding: true })}</span>
                </li>
              </If>
              <If condition={tireFee}>
                <li className="tds-list-item tds-text_color--10">
                  <span className="left tds-o-margin_right-16">
                    {i18n('SummaryPanel.disclaimers.tireFee')}
                  </span>
                  <span>{formatCurrency(tireFee)}</span>
                </li>
              </If>
              <If condition={luxuryTax}>
                <li className="tds-list-item tds-text_color--10" data-id="luxury-tax-line-item">
                  <span className="left tds-o-margin_right-16">
                    {i18n('SummaryPanel.disclaimers.luxuryTax')}
                  </span>
                  <span>{formatCurrency(luxuryTax, { useDynamicRounding: true })}</span>
                </li>
              </If>
              <If condition={orderFeeAmount}>
                <li className="tds-list-item tds-text_color--10">
                  <span className="left">
                    {i18n(
                      isPostOrderSwap ? 'SummaryPanel.order_fee_paid' : 'SummaryPanel.order_fee'
                    )}
                  </span>
                  <span>{orderFeeAmountWithCurrency}</span>
                </li>
              </If>
              <If condition={reservationCredit}>
                <li
                  className="tds-list-item tds-text_color--10"
                  data-id="reservation-credit-line-item"
                >
                  <span className="left">{i18n('SummaryPanel.reservation_credit')}</span>
                  <span>{formatCurrency(reservationCredit)}</span>
                </li>
              </If>
              <If condition={displayTransportFee && transportationFee}>
                <li className="tds-list-item">
                  <span className="left tds-text--regular">
                    {i18n('DeliveryLocation.estimate_transport_fee_label_short', {
                      POSTAL_CODE: deliveryPostalCode,
                    })}
                  </span>
                  <span className="tds-text--end tds-text--regular">
                    {formatCurrency(transportationFee)}
                  </span>
                </li>
              </If>
              <If condition={referral && creditCash}>
                <li className="tds-list-item tds-text_color--10">
                  <span className="left">
                    {i18n('Referral.referrer_name', { NAME: referral?.referrerFirstName })}
                    <If condition={validityDisclaimer?.length}>
                      <p className="tds-text--caption">{validityDisclaimer}</p>
                    </If>
                  </span>
                  <span>{formatCurrency(-creditCash)}</span>
                </li>
              </If>
              <Choose>
                <When condition={purchasePriceIncentive}>
                  <If condition={govtIncentive}>
                    <li className="tds-list-item tds-text_color--10">
                      <span className="left tds-o-margin_right-16">
                        {i18n('Incentives.purchasePriceIncentive')}
                      </span>
                      <span>{formatCurrency(govtIncentive)}</span>
                    </li>
                  </If>
                  <If condition={regionalIncentiveInPurchasePrice?.amount}>
                    <li className="tds-list-item tds-text_color--10">
                      <span className="left tds-o-margin_right-16">
                        {i18n('Incentives.purchasePriceRegionalIncentive', {
                          REGION: regionalIncentiveInPurchasePrice?.longName,
                        })}
                      </span>
                      <span>{formatCurrency(regionalIncentiveInPurchasePrice?.amount)}</span>
                    </li>
                  </If>
                </When>
              </Choose>
            </div>
          </If>
          <li
            className="tds-list-item tds-text_color--10 tds-text--medium"
            data-id="purchase-price-line-item"
          >
            <span className="left">{i18n(yourModelPriceLabel, { MODEL: modelName })}</span>
            <span>
              {formatCurrency(yourModelPrice, {
                useDynamicRounding: formatWithPrecision,
              })}
            </span>
          </li>
          <If condition={taxCredit && grossPriceAfterTaxCredit}>
            <li className="tds-list-item" data-id="tax-credit-line-item">
              <span className="left tds-text--caption">
                {i18n('Incentives.federalIncentive.savingsAfterTaxCredit')}
                <sup>*</sup>
              </span>
              <span className="tds-text--caption">{formatCurrency(grossPriceAfterTaxCredit)}</span>
            </li>
          </If>
          <If condition={deliveryCredit}>
            <div className="pricing--separater-after">
              <li className="tds-list-item tds-text_color--10 tds-o-vertical_padding-top">
                <span className="left">{i18n('SummaryPanel.deliveryCredit')}</span>
                <span>{formatCurrency(deliveryCredit)}</span>
              </li>
            </div>
            <li className="tds-list-item tds-text_color--10 tds-text--medium">
              <span className="left">{i18n(yourModelPriceLabel, { MODEL: modelName })}</span>
              <span>
                {formatCurrency(yourModelPriceWithCredit, {
                  useDynamicRounding: formatWithPrecision,
                })}
              </span>
            </li>
            <p className="tds-text--caption tds-o-padding_bottom-16">
              {i18n(`SummaryPanel.disclaimers.deliveryCreditDisclaimer`)}
            </p>
          </If>
          <If condition={tradeInAmount > 0}>
            <li className="tds-list-item tds-text_color--10">
              <p className="left">
                {i18n('TradeIn.estimate_credit')}
                <p className="tds-o-padding_top-4 tds-text--caption">
                  {vehicleYear ? `${vehicleYear}` : ''}&nbsp;
                  {vehicleMake?.toLowerCase()}&nbsp;
                  {vehicleModel?.toLowerCase()}
                </p>
              </p>
              <span>
                <ModalTrigger
                  type={FINANCE_CAROUSEL}
                  selectedView={FINANCE_MODAL_TAB_TRADEIN}
                  options={{
                    props: {
                      componentName: 'FinanceContainer',
                      props: {
                        classes: 'tds-link',
                      },
                    },
                  }}
                >
                  {formatCurrency(tradeInAmount)}
                </ModalTrigger>
              </span>
            </li>
          </If>
          <If condition={extraCurrency}>
            {extraCurrency.map(({ currency, totalPrice = 0 }) => (
              <li
                className="tds-list-item tds-align--end"
                key={`extraContextPriceCash_${currency}`}
              >
                <span className="tds-text--end tds-text--medium tds-text_color--30">
                  {formatCurrency(totalPrice, {
                    currency,
                    useDynamicRounding: true,
                  })}
                </span>
              </li>
            ))}
          </If>
        </ul>
        <TaxesAndFeesDisclaimer />
        <PriceValidDisclaimer />
        <CurrencyDisclaimer className="tds-text--caption" />
      </div>
    </div>
  );
};

VehiclePricePlusFees.propTypes = {
  destinationAndDocFee: number,
  orderFee: number,
  vehiclePrice: number,
  modelName: string,
  conversionRate: shape({
    factor: number,
  }),
  extraCurrency: arrayOf(
    shape({
      currency: string,
    })
  ),
  firstRegistrationTax: number,
  extraPricingContextAmount: string,
  amountInOtherCurrencies: arrayOf(shape({ amount: number })),
  yourModelPriceLabel: string,
  includeOrderFeeInVehicleTotal: bool,
  acquisitionFee: number,
  inspectionFee: number,
  luxuryTax: number,
  feesTotal: number,
  region: string,
  tireFee: number,
  formatWithPrecision: bool,
  deliveryCredit: number,
  displayTransportFee: bool,
  transportationFee: number,
  deliveryPostalCode: oneOfType([string, number]),
  purchasePriceIncentive: number,
  govtIncentive: number,
  regionalIncentiveInPurchasePrice: shape({ amount: number, longName: string }),
  enableCyberpunk: bool,
  taxCredit: number,
  isInventoryPriceAdjustmentsEnabled: bool,
  isPostOrderSwap: bool,
  tradeInAmount: number,
  selectFinanceTab: func.isRequired,
  vehicleYear: number,
  vehicleMake: string,
  vehicleModel: string,
};

VehiclePricePlusFees.defaultProps = {
  destinationAndDocFee: 0,
  orderFee: 0,
  vehiclePrice: 0,
  modelName: '',
  extraCurrency: null,
  firstRegistrationTax: 0,
  extraPricingContextAmount: '',
  amountInOtherCurrencies: [],
  yourModelPriceLabel: '',
  includeOrderFeeInVehicleTotal: false,
  acquisitionFee: 0,
  inspectionFee: 0,
  luxuryTax: 0,
  feesTotal: 0,
  region: '',
  tireFee: 0,
  formatWithPrecision: false,
  deliveryCredit: 0,
  displayTransportFee: false,
  transportationFee: 0,
  deliveryPostalCode: '',
  purchasePriceIncentive: 0,
  govtIncentive: 0,
  regionalIncentiveInPurchasePrice: {},
  enableCyberpunk: false,
  taxCredit: 0,
  vehiclePriceWithoutDiscounts: 0,
  isInventoryPriceAdjustmentsEnabled: false,
  isPostOrderSwap: false,
  tradeInAmount: undefined,
  vehicleMake: '',
  vehicleModel: '',
  vehicleYear: null,
};

const mapStateToProps = state => {
  const { ReviewDetails, Pricing, OMS, SummaryPanel, App, ApplicationFlow, TradeIn } = state;
  const modelOption = getModelCode(state);
  const {
    includeOrderFeeInVehicleTotal = true,
    yourModelPriceLabel,
    formatWithPrecision = false,
  } = ReviewDetails;
  const { region_code: region } = SummaryPanel;
  const { enableCyberpunk, isInventoryPriceAdjustmentsEnabled = false, isPostOrderSwap } = App;
  const { referral } = ApplicationFlow || {};
  const veteranCredit = getVeteranCredit(state) || 0;
  const { orderPayment = 0, paymentSourceSubType = '' } = getDepositAmount(state, true) || {};
  const isOrderFee = paymentSourceSubType?.includes(ORDER_FEE_TYPE);
  const includeOrderFeeInVehicleTotalFlag = isOrderFee ? includeOrderFeeInVehicleTotal : false;
  const displayTransportFee = displayTransportationFeeAmount(state);
  const transportationFee = displayTransportFee ? getTransportationFeeAmount(state) : 0;
  const orderFeeFinancialSvc = getOrderFeeFromFinancialSvc(state);
  const {
    grossPriceAfterTaxCredit = 0,
    vehiclePriceAfterTaxCredit = 0,
    vehiclePriceWithoutDiscounts = 0,
  } = Pricing?.cash || {};
  const inventoryDiscount = _get(ReviewDetails, 'product.data.Discount', 0);
  const isUsedInventory = _get(ReviewDetails, 'product.isUsedInventory', false);
  const isUsedFalconTaxEligible =
    isUsedInventory && _get(ReviewDetails, 'product.data.FederalIncentives.IsTaxIncentiveEligible');
  const priceAfterTaxCredit = isUsedFalconTaxEligible
    ? vehiclePriceAfterTaxCredit + transportationFee
    : grossPriceAfterTaxCredit;
  const { tradeInAmount, vehicleDetails } = TradeIn || {};
  const { year: vehicleYear, make: vehicleMake, model: vehicleModel } = vehicleDetails || {};

  return {
    destinationAndDocFee: getDestinationAndDocFee(state),
    orderFee: orderFeeFinancialSvc || (includeOrderFeeInVehicleTotalFlag ? orderPayment : 0),
    vehiclePrice: Pricing?.total,
    modelName: OMS?.lexicon?.options?.[modelOption]?.long_name,
    extraCurrency: getExtraPricingContextAmounts(state) || [],
    firstRegistrationTax: _get(
      state,
      'Pricing.calculatorResult.data.variables.fees.first_registration_tax.total',
      0
    ),
    extraPricingContextAmount: getExtraCurrencyAmount(state, modelOption),
    amountInOtherCurrencies: includeOrderFeeInVehicleTotalFlag
      ? getAmountInOtherCurrencies(state, modelOption)
      : 0,
    includeOrderFeeInVehicleTotal: includeOrderFeeInVehicleTotalFlag,
    acquisitionFee: getAcquisitionFee(state),
    inspectionFee: getInspectionFee(state),
    luxuryTax: getLuxuryTax(state),
    feesTotal: getFeesTotal(state),
    yourModelPriceLabel,
    region,
    tireFee: getRegionalTireFee(state),
    formatWithPrecision,
    deliveryCredit: getDeliveryCredit(state),
    displayTransportFee,
    transportationFee,
    deliveryPostalCode: getDeliveryPostalCode(state),
    purchasePriceIncentive: getPurchasePriceIncentive(state),
    govtIncentive: getGovernmentIncentives(state),
    regionalIncentiveInPurchasePrice: getRegionalIncentiveInPurchasePrice(state),
    referral,
    creditCash: referral?.cash?.value || 0,
    deliveryFee: getDeliveryFee(state),
    enableCyberpunk,
    reservationCredit: getReservationCredit(state),
    grossPriceAfterTaxCredit: priceAfterTaxCredit,
    vehiclePriceWithoutDiscounts: vehiclePriceWithoutDiscounts + veteranCredit,
    inventoryDiscount,
    isInventoryPriceAdjustmentsEnabled,
    isPostOrderSwap,
    taxCredit: getTaxCredit(state),
    tradeInAmount,
    vehicleYear: vehicleYear !== null && vehicleYear !== undefined && vehicleYear,
    vehicleMake,
    vehicleModel,
    veteranCredit,
    vehiclePriceWithVeteranCredit: Pricing?.total + veteranCredit,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectFinanceTab: tabId => dispatch(selectTab(tabId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VehiclePricePlusFees);
