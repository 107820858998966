import React, { useEffect, useState } from 'react';
import { string, arrayOf, bool, oneOfType, shape, number } from 'prop-types';
import { i18n, htmlToReact, isPriceAcceptance } from 'utils';
import {
  FIN_MODAL_FOOTER,
  FinanceTypes,
  CASH,
  FINANCE_CAROUSEL,
  FINANCIAL_MODAL_STANDALONE,
  MODAL_MEDIUM,
  FIN_MODAL_FOOTER_TRIGGER,
  FINANCIAL_MODAL_CN,
} from 'dictionary';
import classnames from 'classnames';
import { formatPercent, formatCurrency } from '@tesla/coin-common-components';
import { Button, Text, Icon } from '@tesla/design-system-react';
import { iconChevron180, iconChevronSmall } from '@tesla/design-system-icons';
import ModalTrigger from '../ModalTrigger';
import { formatFinplatNumber } from 'selectors';
import { throttle } from 'lodash';
import Analytics from '../../common/analytics';
import { WEB_CONFIGURATOR_FINANCING_CALCULATOR } from '../../common/gioStatistics';

const FooterRepresentation = ({
  financeType,
  beforeSavingsPrice,
  beforeSavingCashPrice,
  afterSavingsPrice,
  hideAfterSavingsPrice,
  reversePricingOrder,
  afterSavingsLabel,
  purchasePriceLabel,
  showSavingsAmount,
  savingsAmount,
  savingsAmountLabel,
  hidePaymentModalTrigger,
  showBeforeSavingsPriceFinancingLabel,
  isReservation,
  depositAmount,
  showFooterFinanceLink,
  effectiveRate: effectiveInterestRate,
  interestRate,
  showFinanceProductName,
  modelCode,
  showAsterixAtPriceEnd,
  showIncludesVATinFooter,
  monthlyLeasePayment,
  financeProductId,
  showFuelMonthlySavings,
  fuelSavingsPerMonth,
  isLoading,
  tradeInType,
  showVehiclePricePlusFees,
  showAmountIncludingFeesInModal,
  extraPricingContextAmounts,
  displayDoubleCurrency,
  isLayoutMobile,
  isLayoutTablet,
  leaseMonthlyPaymentInIntlCurrency,
  downPayment,
  residualAmount,
  coeBidAmount,
  appContext,
  showPurchasePriceOnFooter,
  shouldHideFooter,
  loanType,
  productType,
  hideFinanceProduct,
  enableCyberpunk,
  countryCode,
  showFinanceFieldsInFooter,
  isCoinReloaded,
  showAfterSavingsPriceForTrims,
  isInventory,
  isEnterpriseOrder,
  goToPayment,
  isEditDesign,
  showPaymentOverview,
  hasPriceTotal,
  showAfterSavingsPriceInFooter,
  pricePostfix,
  paymentError,
  isTotalPriceInFooter,
  showFooterCashWithMonthly,
  navigationSection,
  footerMonthlyPaymentPostFix,
}) => {
  // Todo: Move this to a common util
  // inquire whether we can reduce these small in component price labels to one common helper

  if (isPriceAcceptance() || shouldHideFooter) {
    return null;
  }


  const props = {
    props: {
      analyticsPrefix: FIN_MODAL_FOOTER_TRIGGER,
    },
  };

  const getPriceLabel = () => {
    if (coeBidAmount) {
      return i18n('Review.purchase_price_with_coe');
    }

    if (financeType !== FinanceTypes.CASH) {
      const label = i18n(`SummaryPanel.netPrice__${financeType}__${financeProductId}`, null, null, {
        specificOverride: appContext,
        returnNullWhenEmpty: true,
      });
      if (label) {
        return label;
      }
    }
    if (showVehiclePricePlusFees && !showAmountIncludingFeesInModal) {
      return i18n('Review.vehicle_price');
    }

    const includesVATLabel = i18n('FinancingOptions.includesVAT', null, null, {
      returnNullWhenEmpty: true,
    });

    if (
      showIncludesVATinFooter &&
      includesVATLabel &&
      !/operational_lease/.test(financeProductId)
    ) {
      if (financeType === FinanceTypes.CASH) {
        return includesVATLabel;
      }
      return `${purchasePriceLabel} ${includesVATLabel}`;
    }

    if (showPurchasePriceOnFooter) {
      return i18n('Review.purchase_price');
    }

    return purchasePriceLabel;
  };
  const financeLabel = getPriceLabel();

  const footerLinkTxt = financeType !== FinanceTypes.CASH ? 'footerLink' : 'linkToFinance';
  const hideMonthlyLeasePrice =
    !(countryCode === 'CN' ? monthlyLeasePayment >= 0 : monthlyLeasePayment) && financeType === 'lease';

  const financeFields = showFinanceFieldsInFooter?.map(field => {
    const isFinplat = financeType === FinanceTypes.FINPLAT;
    const formatFinplatRate = (key, value) => `${formatFinplatNumber(key, value)}%`;

    if (/operational_lease/.test(financeProductId)) {
      return null;
    }

    return (
      {
        interestRate: {
          key: loanType
            ? `FinancingOptions.aprLabel__${loanType}`
            : 'FinancingOptions.interestRateLabel',
          value: isFinplat
            ? formatFinplatRate('interestRate', interestRate)
            : formatPercent(interestRate, 2),
        },
        effectiveRate: {
          key: 'FinancingOptions.effectiveRateLabel',
          value: isFinplat
            ? formatFinplatRate('effectiveInterestRate', effectiveInterestRate)
            : formatPercent(effectiveInterestRate, 2),
        },
        downPayment: {
          key: 'FinancingOptions.downpaymentLabel',
          value: downPayment,
        },
        residualAmount: {
          key: 'FinancingOptions.lease__residualAmount',
          value: residualAmount,
        },
      }[field] ?? null
    );
  });

  const isFinanceTypeWithFlip = countryCode === 'US' && !financeProductId?.includes('LEASE');
  const reversePricingOrderOverride = isFinanceTypeWithFlip
    ? isFinanceTypeWithFlip
    : reversePricingOrder;
  const pricingOrder = [
    <div
      key="footer-pricing--before"
      className="tds-flex-item tds-flex--col_1of5 tds-flex--center tds-flex--justify-center"
    >
      <div
        className={classnames(
          { 'tds-o-color--white': enableCyberpunk },
          { 'tds-flex tds-text--body tds-text_color--10': !enableCyberpunk },
          'summary-panel--item beforeSavingsPrice tds-text--medium'
        )}
        key="beforeSavingsPrice"
      >
        <span className={classnames(`finance-type finance-type--${financeType}`)}>
          {beforeSavingsPrice}
          <If condition={displayDoubleCurrency}>
            <If condition={financeType === FinanceTypes.CASH}>
              {extraPricingContextAmounts?.map(extraPricingContextAmount => (
                <span
                  className="tds-text--start double-currency"
                  key={`extraContextPriceBeforeSavings_${extraPricingContextAmount.currency}`}
                >
                  {formatCurrency(extraPricingContextAmount.grossPrice, {
                    currency: extraPricingContextAmount.currency,
                    useDynamicRounding: true,
                  })}
                </span>
              ))}
            </If>
            <If condition={financeType === FinanceTypes.LEASE}>
              <span className="tds-text--start double-currency">
                {leaseMonthlyPaymentInIntlCurrency}
              </span>
            </If>
          </If>
        </span>
        <If condition={showAsterixAtPriceEnd && financeType === 'loan'}>*</If>
        <If
          condition={
            showBeforeSavingsPriceFinancingLabel ||
            (!showBeforeSavingsPriceFinancingLabel && !['lease', 'loan'].includes(financeType)) ||
            showFinanceProductName
          }
        >
          <span className={classnames('pricing-label', `pricing-label--${financeProductId}`)}>
            {financeLabel}
          </span>
        </If>
      </div>
    </div>,
    <If condition={isReservation} key="footer-pricing--next">
      <div className="tds-flex-item tds-flex--col_1of5 tds-flex--justify-center">
        <div className="tds-flex summary-panel--item" key="depositAmount">
          <span className={classnames(`finance-type--${financeType}`)}>{depositAmount}</span>
          <span>
            {i18n('SummaryPanel.disclaimers.orderPaymentShort', {
              DEPOSIT_TYPE: i18n('SummaryPanel.disclaimers.reserveDeposit'),
            })}
          </span>
        </div>
      </div>
    </If>,
    <If condition={!hideAfterSavingsPrice && !financeFields?.length} key="footer-pricing--after">
      <div
        className={classnames('tds-flex-item tds-flex--col_1of5 tds-flex--justify-center', {
          'tds-text--medium': reversePricingOrderOverride,
        })}
      >
        <div
          className={classnames('tds-flex summary-panel--item tds--no_vertical_padding', {
            'tds-o-color-ct-h2 tds-o-text_no-wrap': enableCyberpunk,
          })}
          key="afterSavingsPrice"
        >
          <span className={classnames(`finance-type--${financeType}`)}>{afterSavingsPrice}</span>
          <span className="pricing-label">{afterSavingsLabel}</span>
        </div>
      </div>
    </If>,
  ];

  const summaryPanelContent = () => {
    return (
      <div key="footer-main-content" className="tds-flex-item summary-options">
        <Choose>
          <When condition={hideFinanceProduct}>
            <div className="tds--horizontal_padding">
              {i18n(`FinancingOptions.unavailable__${productType}.header`, null, null, {
                returnNullWhenEmpty: true,
              })}
              <ModalTrigger
                type={FINANCE_CAROUSEL}
                analyticsInteraction={FIN_MODAL_FOOTER}
                options={{ props }}
              >
                <span className="tds-link">{i18n('common.seeDetails')}</span>
              </ModalTrigger>
            </div>
          </When>
          <When condition={hideMonthlyLeasePrice}>
            {htmlToReact(
              i18n('SummaryPanel.lease__undefined', null, null, {
                specificOverride: financeProductId,
              })
            )}
          </When>
          <Otherwise>
            {pricingOrder}
            <If condition={!(displayDoubleCurrency && isLayoutMobile)}>
              <If condition={showSavingsAmount}>
                <div className="tds-flex-item tds-flex--col_1of5 tds-flex--justify-center">
                  <div className="tds-flex summary-panel--item tds-flex--align-center">
                    <span className={classnames(`finance-type--${financeType}`)}>
                      {savingsAmount}
                    </span>
                    <span className="pricing-label">{savingsAmountLabel}</span>
                  </div>
                </div>
              </If>
              <If condition={showFuelMonthlySavings}>
                <div className="tds-flex-item tds-flex--col_1of5 tds-flex--justify-center">
                  <div className="tds-flex summary-panel--item">
                    <span className={classnames(`finance-type--${financeType}`)}>
                      {fuelSavingsPerMonth}
                    </span>
                    <span className="pricing-label">{savingsAmountLabel}</span>
                  </div>
                </div>
              </If>
              {financeFields?.filter(Boolean).map(({ key, value }) => (
                <div
                  key={key}
                  className="tds-flex-item tds-flex--col_1of5 tds-flex--justify-center"
                >
                  <div className="tds-flex summary-panel-rate">
                    <span className="tds-text--medium">{value}</span>
                    <span>{i18n(key)}</span>
                  </div>
                </div>
              ))}
            </If>
          </Otherwise>
        </Choose>
      </div>
    );
  };

  if (reversePricingOrderOverride) {
    pricingOrder.reverse();
  }

  const footerDisclaimer = i18n(
    `SummaryPanel.footerDisclaimer__${financeProductId}`,
    { DOWN_PAYMENT: downPayment },
    null,
    { returnNullWhenEmpty: true }
  );

  const footerButtonText =
    isEditDesign ? i18n('common.continue') :
    isEnterpriseOrder && !isInventory ? i18n('common.add_to_order') :
    i18n('SummaryPanel.orderNow');

  const [hideFooter, setHideFooter] = useState(false);

  // hides footer when payment overview scrolls into view
  const onIntersect = (entries, observer) => {
    entries.forEach(entry => {
      // only entry should be footer target
      const { top = 0, height = 0 } = footerTarget.getBoundingClientRect();
      const windowHeight = window?.innerHeight;
      if (height && windowHeight && top <= windowHeight) {
        setHideFooter(true);
        return;
      }
      setHideFooter(false);
    });
  };

  let footerTarget; // footer hides when target scrolls into view
  if (isEnterpriseOrder && !isInventory) {
    footerTarget = document.getElementsByClassName('cf-payment-overview__title')[0];
  }
  else if (isInventory && !showPaymentOverview) {
    footerTarget = document.getElementById('delivery-selection');
  } else {
    footerTarget = document.getElementsByClassName('cf-summary_container')[0];
  }

  useEffect(() => {
    if (footerTarget && !isLoading) {
      const observer = new IntersectionObserver(onIntersect, {
        root: null,
        threshold: 0,
      });

      observer.observe(footerTarget);

      return () => {
        observer.unobserve(footerTarget);
      };
    }
  }, [footerTarget, showPaymentOverview, isLoading, navigationSection]);

  const onOrderButtonClick = throttle(() => {
    Analytics.fireInteractionEvent('footer-order-now-click');

    if (isEnterpriseOrder && !isInventory) {
      goToPayment();
      return;
    }

    let scrollTarget = footerTarget; // error state needs to change scroll target without changing footer target
    // prioritize scroll to error message if it exists
    const errorEl = document.getElementsByClassName('alerts-container')[0] || null;
    const paymentErrorEl = paymentError
      ? document.getElementsByClassName('payment-website')[0]
      : null;
    if (errorEl) {
      scrollTarget = errorEl;
    } else if (paymentErrorEl) {
      scrollTarget = paymentErrorEl;
    }
    if (scrollTarget) {
      const header =
        isLayoutMobile || isLayoutTablet
          ? document.getElementsByClassName('gallery')[0]
          : document.getElementById('tds-site-header');
      const { bottom: headerBottom = 0 } = header?.getBoundingClientRect() || {};
      const { top: targetTop = 0 } = scrollTarget?.getBoundingClientRect() || {};
      const y = targetTop + window.scrollY - headerBottom - 5; // adding 5 px to account for box shadow on tablet and mobile, looks good on desktop
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }, 1000);

  const previewPrice = (() => {
    if (showAfterSavingsPriceForTrims && showAfterSavingsPriceInFooter) {
      return {
        value: afterSavingsPrice,
        label: afterSavingsLabel
      }
    }

    return {
      value: beforeSavingsPrice,
      label: (showFooterCashWithMonthly && ![null, undefined, FinanceTypes.CASH].includes(financeProductId))
        ? [beforeSavingCashPrice, i18n('Review.purchase_price')].join(' ')
        : financeLabel
    }
  })();

  const componentName = countryCode === 'CN' ? FINANCIAL_MODAL_CN : FINANCIAL_MODAL_STANDALONE;
  return (
    <Choose>
      <When condition={isCoinReloaded}>
        <div
          className={classnames('summary-panel--aside-footer', {
            'summary-panel--footer__loading': isLoading,
            'tds-clip-top-right': enableCyberpunk,
            'summary-panel--aside-footer__hide': hideFooter,
            'tds--padding_inline-36': isLayoutTablet,
          })}
          key="footer-modal-trigger"
          data-id="finance-footer"
        >
          <ModalTrigger
            analyticsInteraction={FIN_MODAL_FOOTER}
            options={{
              props: {
                componentName: componentName,
                props: {
                  genericWrapper: true,
                  size: MODAL_MEDIUM,
                  isMega: true,
                },
              },
            }}
          >
            <div className="tds-o-margin_right-16 tds-text--start">
              <div className="tds-flex--as-rows tds-flex--center tds-o-flex_no-wrap">
                <Choose>
                  <When condition={hideMonthlyLeasePrice}>
                    <div className={classnames('tds--no_padding tds-text--h6', {
                      'coin-skeleton--loader': !hasPriceTotal,
                    })}>
                      {htmlToReact(
                        i18n('SummaryPanel.lease__undefined', null, null, {
                          specificOverride: financeProductId,
                        })
                      )}
                    </div>
                  </When>
                  <Otherwise>
                    <Text
                      is="div"
                      className={classnames('tds--no_padding tds-text--h3', {
                        'coin-skeleton--loader': !hasPriceTotal,
                      })}
                    >
                      <span>{previewPrice.value}</span>
                      <If condition={showAfterSavingsPriceForTrims && pricePostfix}>
                        <span>{pricePostfix}</span>
                      </If>
                      <If condition={footerMonthlyPaymentPostFix && financeType !== FinanceTypes.CASH}>
                        <span>{footerMonthlyPaymentPostFix}</span>
                      </If>
                    </Text>
                  </Otherwise>
                </Choose>
                <Icon inline data={iconChevron180} />
              </div>
              <span className="tds-text--caption">
                <Choose>
                  <When condition={isTotalPriceInFooter}>
                    {financeType !== FinanceTypes.CASH
                      ? `${i18n('common.purchasePrice')}${beforeSavingCashPrice}`
                      : ''}
                  </When>
                  <Otherwise>
                    {previewPrice.label}
                  </Otherwise>
                </Choose>
              </span>
            </div>
          </ModalTrigger>
          <Button onClick={onOrderButtonClick} className="aside-footer--button">
            {footerButtonText}
          </Button>
        </div>
      </When>
      <Otherwise>
        <div
          className={classnames('footer-action-block', 'summary-panel--footer', {
            'summary-panel--footer__loading': isLoading,
            'tds-clip-top-right': enableCyberpunk,
          })}
          key="footer-modal-trigger"
          data-id="finance-footer"
        >
          <Choose>
            <When condition={!hidePaymentModalTrigger}>
              <ModalTrigger
                triggerType="iconTrigger"
                type={FINANCE_CAROUSEL}
                options={{ props }}
                analyticsInteraction={FIN_MODAL_FOOTER}
                data-gio-eventname={WEB_CONFIGURATOR_FINANCING_CALCULATOR}
              >
                <div className="footer-modal-trigger">
                  <Icon data={iconChevronSmall} />
                </div>
                {summaryPanelContent()}
              </ModalTrigger>
            </When>
            <Otherwise>{summaryPanelContent()}</Otherwise>
          </Choose>
          <If condition={footerDisclaimer}>
            <div className="footer-disclaimer">{htmlToReact(footerDisclaimer)}</div>
          </If>
          <div className="tds-flex footer-summary--disclaimer">
            <If condition={tradeInType}>
              <div className="tds-text--caption tds--horizontal_padding">
                {i18n(`Inventory.vehicleDetails.${tradeInType}`)}
              </div>
            </If>
            <If condition={showFooterFinanceLink}>
              <div className="footer-action-link">
                <ModalTrigger
                  type={FINANCE_CAROUSEL}
                  analyticsInteraction={FIN_MODAL_FOOTER}
                  options={{ props }}
                >
                  <span className="tds-link">{i18n(`SummaryPanel.${footerLinkTxt}`)}</span>
                </ModalTrigger>
              </div>
            </If>
          </div>
        </div>
      </Otherwise>
    </Choose>
  );
};

FooterRepresentation.propTypes = {
  financeType: string,
  beforeSavingsPrice: string,
  beforeSavingCashPrice: string,
  afterSavingsPrice: string,
  hideAfterSavingsPrice: bool,
  reversePricingOrder: bool,
  afterSavingsLabel: string,
  purchasePriceLabel: string,
  showSavingsAmount: bool,
  savingsAmount: string,
  savingsAmountLabel: string,
  hidePaymentModalTrigger: bool.isRequired,
  showBeforeSavingsPriceFinancingLabel: bool,
  isReservation: bool,
  depositAmount: string,
  downPayment: string,
  residualAmount: oneOfType([string, number]),
  showFooterFinanceLink: bool,
  effectiveRate: number,
  interestRate: number,
  showOtherLoanTerms: bool,
  showFinanceProductName: bool,
  modelCode: string,
  showAsterixAtPriceEnd: bool,
  showIncludesVATinFooter: bool,
  totalAmtPaid: number,
  monthlyLeasePayment: number,
  financeProductId: string,
  isLoading: bool,
  tradeInType: string,
  showFuelMonthlySavings: bool,
  fuelSavingsPerMonth: string,
  showVehiclePricePlusFees: bool,
  showAmountIncludingFeesInModal: bool,
  extraPricingContextAmounts: arrayOf(
    shape({
      currency: string,
      grossPrice: number,
      vatAmount: number,
    })
  ),
  displayDoubleCurrency: bool,
  isLayoutMobile: bool,
  isLayoutTablet: bool,
  leaseMonthlyPaymentInIntlCurrency: string,
  coeBidAmount: number,
  appContext: string,
  showPurchasePriceOnFooter: bool,
  loanType: string,
  showFinanceFieldsInFooter: oneOfType([arrayOf(string), shape({}), bool]),
  isCoinReloaded: bool,
  showAfterSavingsPriceForTrims: bool,
  showAfterSavingsPriceInFooter: bool,
  pricePostfix: string,
  paymentError: string,
  isTotalPriceInFooter: bool,
  navigationSection: string,
};

FooterRepresentation.defaultProps = {
  hideAfterSavingsPrice: false,
  reversePricingOrder: false,
  showSavingsAmount: false,
  afterSavingsLabel: '',
  purchasePriceLabel: '',
  savingsAmountLabel: '',
  isLayoutMobile: false,
  isLayoutTablet: false,
  savingsAmount: 0,
  extraPricingContextAmounts: null,
  showBeforeSavingsPriceFinancingLabel: true,
  financeType: '',
  beforeSavingsPrice: '',
  beforeSavingCashPrice: '',
  afterSavingsPrice: '',
  isReservation: false,
  depositAmount: '',
  showFooterFinanceLink: false,
  effectiveRate: 0,
  interestRate: 0,
  showOtherLoanTerms: false,
  showFinanceProductName: false,
  modelCode: '',
  showAsterixAtPriceEnd: false,
  showIncludesVATinFooter: false,
  totalAmtPaid: null,
  downPayment: null,
  residualAmount: null,
  monthlyLeasePayment: null,
  financeProductId: CASH,
  tradeInType: '',
  showFuelMonthlySavings: false,
  fuelSavingsPerMonth: '',
  showVehiclePricePlusFees: false,
  showAmountIncludingFeesInModal: false,
  leaseMonthlyPaymentInIntlCurrency: '',
  coeBidAmount: 0,
  appContext: '',
  showPurchasePriceOnFooter: false,
  loanType: '',
  showFinanceFieldsInFooter: null,
  isCoinReloaded: false,
  showAfterSavingsPriceForTrims: false,
  showAfterSavingsPriceInFooter: false,
  paymentError: null,
  isTotalPriceInFooter: false,
  navigationSection: '',
};

export default FooterRepresentation;
