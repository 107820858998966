import React, { useEffect } from 'react';
import { string, func, bool, shape } from 'prop-types';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import cx from 'classnames';
import { localizeUrl } from '@tesla/parse-locale';
import { i18n, htmlToReact, showLinkToInventoryPerRegion, buttonStringExtractor } from 'utils';
import Analytics from 'analytics';
import { GIO_TAG_ENABLE } from 'gioStatistics';
import {
  WEB_CHECK_CHANGE_CLICK,
  WEB_GIVE_UP_CLICK,
  WEB_DESIGN_OVERVIEW_NEXTSTEP,
} from '../../common/gioStatistics';
import { getModelCode, isInventory, getModel, getGAAutoType } from 'selectors';
import {
  navigationSelectKey,
  cancelUpdateOrder,
  getAlternateDesigns,
  setVehicleDesign,
  externalLinkClicked,
  setPaymentOverviewFlag,
} from 'actions';
import {
  NAVIGATION_VIEW_PAYMENT,
  AUTO_TYPE_CUSTOM,
  UI_DATA_IDS,
  BUTTON_START,
  BUTTON_END,
  FIELD_ZIP_CODE,
} from 'dictionary';
import InventoryUpsellSection from '../InventoryUpsellSection';
import DeliveryEstDisclaimerSection from '../DeliveryEstDisclaimerSection';
import ModalTrigger from '../../components/ModalTrigger';
import DeliveryDate from './DeliveryDate';
import { MODEL_FULL_NAME } from '../../common/dictionary';
import IRAIncentiveCopy from './IRAIncentiveCopy';
import ContactUsLink from '../../containers/PaymentOverview/Sections/ContactUsLink';
import InventoryDeliverySelection from './InventoryDeliverySelection';

/* Payment flow trigger component (last section of the overview area ) */
const PaymentOverviewTrigger = ({
  isComboInventory,
  isEditDesign,
  modelName,
  goToPayment,
  onCancelUpdateOrder,
  classes,
  showLinkToInventory,
  showDeliveryEstDisclaimer,
  isEnterpriseOrder,
  isPostOrderSwap,
  isInventoryVehicle,
  showSoldOutFlow,
  ignorePostalCode,
  isLayoutMobile,
  isLayoutTablet,
  modelCode,
  isUsedInventory,
  isInventoryFederalTaxEligible,
  market,
  showBusinessInformation,
  condition,
  useUsedInventoryDisclaimerCopy,
  isGoToPaymentDisabled,
  showBuyTips,
  showNextButton,
  locale,
  gaTitleStatus,
  isInventorySwapEnabled,
  getEarlyDeliveryDesigns,
  initialDesign,
  setCurrentDesign,
  initialConfigByGroup,
  isPaymentDisabled,
  navigateExternal,
  range,
  registrationZipCode,
  showIRABusinessEligibility,
  isEnterpriseOrderPickup,
  enterpriseSearch,
  isCentered,
  showSinglePage,
  setPaymentFlag,
  hideCTA,
  showPaymentOverview,
  internalInventoryHost,
  hasPublicInventory,
}) => {
  if (showSinglePage && !isInventoryVehicle) {
    return null;
  }

  let paymentFlowLabel = 'common.continue';
  if (isEditDesign) {
    paymentFlowLabel = 'common.review_changes';
  } else if (isEnterpriseOrder) {
    paymentFlowLabel = 'common.add_to_order';
  } else if (isPostOrderSwap) {
    paymentFlowLabel = 'common.continue';
  } else if (market === 'CN' && showNextButton) {
    paymentFlowLabel = 'common.next';
  }

  const nextStepButtonGioEventName = isEditDesign
    ? WEB_CHECK_CHANGE_CLICK
    : WEB_DESIGN_OVERVIEW_NEXTSTEP;

  const fireAnalytics = () => {
    Analytics.fireTagEvent({
      event: Analytics.event,
      interaction: 'request-a-callback',
      'cfg-type': `inventory-${modelCode}`,
      titleStatus: gaTitleStatus,
    });
  };

  const requestCallbackStringSource = `Inventory.common.requestCallbackLabel${
    useUsedInventoryDisclaimerCopy ? '_used' : ''
  }`;
  const requestCallbackString = i18n(requestCallbackStringSource, {
    MODEL_NAME: modelName,
    BUTTON_START,
    BUTTON_END,
  });
  const requestCallbackCopy = buttonStringExtractor(i18n(requestCallbackString));

  const fetchVehicleDesigns = () => {
    setCurrentDesign({
      initialDesign,
      initialConfigByGroup,
    });
    getEarlyDeliveryDesigns();
  };

  const continueToPayment = () => {
    Analytics.fireInteractionEvent('continue-to-payment');
    showSinglePage ? setPaymentFlag(true) : goToPayment();
  };

  useEffect(() => {
    if (showPaymentOverview) {
      const element = document.querySelector('.cf-summary_container');
      if (element) {
        const { top } = element.getBoundingClientRect();
        const y = top + window.scrollY
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  }, [showPaymentOverview]);

  return (
    <div
      className={cx(
        'tds--padding',
        { [classes]: classes },
        { 'tds-o-horizontal_padding-0': isInventoryVehicle && !isLayoutMobile && !isLayoutTablet },
        { 'tds--vertical_padding--6x': isLayoutMobile },
        { 'option-widget--container': isInventoryVehicle },
        { 'payment-trigger--section': showSinglePage && !hideCTA }
      )}
    >
      <If condition={!isPostOrderSwap}>
        <InventoryDeliverySelection />
        <If condition={(isEnterpriseOrderPickup && !showSoldOutFlow) || !isInventoryVehicle}>
          <h2
            className={cx(
              'text-loader--content text-loader--subtitle cf-payment-overview__title primary-copy',
              { '': isEnterpriseOrder },
              { 'tds-text--center': isCentered }
            )}
          >
            <span>
              {htmlToReact(
                i18n(
                  `common.${isPaymentDisabled ? 'yourModel' : 'orderYourModel'}`,
                  {
                    MODEL: `<span style="white-space: nowrap">${modelName}</span>`,
                  },
                  null,
                  {
                    specificOverride: modelCode,
                    returnNullWhenEmpty: true,
                  }
                )
              )}
            </span>
          </h2>
          <DeliveryDate
            group="PAYMENT"
            classes="tds-text--medium tds--vertical_padding-10"
            isCentered={isCentered}
          />
          <IRAIncentiveCopy showIRABusinessEligibility={showIRABusinessEligibility} />
        </If>
      </If>

      <If condition={isUsedInventory && isInventoryFederalTaxEligible}>
        <IRAIncentiveCopy showUsedIraEligibility={isInventoryFederalTaxEligible} />
      </If>
      <div
        className={cx('tds-btn_group tds-btn_group--vertical', {
          'tds--is_hidden': hideCTA,
        })}
      >
        <Choose>
          <When condition={showSoldOutFlow}>
            <button
              type="button"
              className="tds-btn tds-btn--secondary tds-btn--large tds-btn--width-full continue-btn"
              onClick={() =>
                navigateExternal({
                  analytics: {
                    'cfg-type': isEnterpriseOrderPickup
                      ? 'enterprise-order'
                      : `inventory-${modelCode}`,
                    interaction: 'browse-other-inventory',
                  },
                  href: localizeUrl(
                    isEnterpriseOrderPickup
                      ? enterpriseSearch
                      : `/inventory/${isUsedInventory ? 'used' : 'new'}/${modelCode}`,
                    {
                      baseUrl: hasPublicInventory && internalInventoryHost ? '' : internalInventoryHost,
                      locale,
                      delimiter: '_',
                      query: {
                        ...(ignorePostalCode ? {} : { range }),
                        [ignorePostalCode ? 'RegistrationProvince' : 'zip']: registrationZipCode,
                      },
                    }
                  ),
                  target: '_self',
                })
              }
              data-id={UI_DATA_IDS?.overviewPage?.browseOtherInventoryBtn}
              tabIndex={0}
              role="link"
            >
              {i18n('DeliveryLocation.errors__range_restricted_cta')}
            </button>
          </When>
          <When condition={showBuyTips}>
            <p className="tds-text--center tds-text--caption">
              {i18n(`common.buy_tips`, {
                LOCALE: locale,
                MODEL: MODEL_FULL_NAME[modelCode] || '',
              })}
            </p>
          </When>
          <When condition={isPaymentDisabled}>
            <button
              type="button"
              className="tds-btn tds-btn--primary tds-btn--large tds-btn--width-full continue-btn"
              onClick={() =>
                navigateExternal({
                  analytics: { 'cfg-type': `get-updates-${modelCode}`, interaction: 'expand' },
                  href: localizeUrl('/updates', {
                    baseUrl: '',
                    locale,
                    delimiter: '_',
                  }),
                })
              }
              data-id={UI_DATA_IDS?.overviewPage?.getUpdatesBtn}
              data-subtype="btn-navigation--get-updates"
              tabIndex={0}
              role="link"
            >
              {i18n('common.getUpdatesLabel')}
            </button>
          </When>
          <When condition={isInventorySwapEnabled}>
            <button
              type="button"
              className="tds-btn tds-btn--primary tds-btn--large tds-btn--width-full continue-btn"
              onClick={fetchVehicleDesigns}
              onKeyPress={fetchVehicleDesigns}
              data-id={UI_DATA_IDS?.overviewPage?.continueBtn}
              data-subtype="btn-navigation--payment"
              tabIndex={0}
              data-gio-track={GIO_TAG_ENABLE}
              data-gio-eventname={nextStepButtonGioEventName}
            >
              <span data-gio-track={GIO_TAG_ENABLE} data-gio-eventname={nextStepButtonGioEventName}>
                {i18n('common.continue')}
              </span>
            </button>
          </When>
          <Otherwise>
            <button
              type="button"
              className="tds-btn tds-btn--primary tds-btn--large tds-btn--width-full action-trigger--link continue-to-payment-btn"
              onClick={continueToPayment}
              onKeyPress={continueToPayment}
              data-id={UI_DATA_IDS?.overviewPage?.continueToPaymentBtn}
              data-subtype="btn-navigation--payment"
              tabIndex={0}
              data-gio-track={GIO_TAG_ENABLE}
              data-gio-eventname={nextStepButtonGioEventName}
              disabled={isGoToPaymentDisabled}
            >
              <span data-gio-track={GIO_TAG_ENABLE} data-gio-eventname={nextStepButtonGioEventName}>
                {i18n(paymentFlowLabel)}
              </span>
            </button>
          </Otherwise>
        </Choose>
        <If condition={isEditDesign}>
          <button
            type="button"
            className="tds-btn tds-btn--secondary tds-btn--width-full cancel-order-button"
            onClick={onCancelUpdateOrder}
            data-id="btn-order"
            data-subtype="btn-order--cancel-update-order"
            onKeyPress={onCancelUpdateOrder}
            tabIndex={0}
            data-gio-track={GIO_TAG_ENABLE}
            data-gio-eventname={WEB_GIVE_UP_CLICK}
          >
            <span>{i18n('common.discard_changes')}</span>
          </button>
        </If>
      </div>
      {showLinkToInventory && <InventoryUpsellSection />}
      {showDeliveryEstDisclaimer && <DeliveryEstDisclaimerSection market={market} />}

      <If condition={isInventoryVehicle && !isPostOrderSwap && !isEnterpriseOrder}>
        <div className={market === 'CN' ? '' : 'tds-text--center'}>
          <Choose>
            <When condition={!isUsedInventory && isComboInventory && !hideCTA && market === 'CN'}>
              <ContactUsLink />
            </When>
            <When condition={!hideCTA}>
              <p>
                <span
                  onClick={fireAnalytics}
                  onKeyPress={fireAnalytics}
                  role="link"
                  tabIndex={0}
                  key="request-a-callback"
                >
                  <span className={market === 'CN' ? 'tds-text--caption' : ''}>
                    {requestCallbackCopy.firstPart}
                  </span>
                  <ModalTrigger
                    options={{
                      props: {
                        componentName: 'RequestCallback',
                        props: {
                          genericWrapper: true,
                          size: 'MODAL_SMALL',
                          containerCss: 'tds-display--inline',
                        },
                      },
                    }}
                  >
                    <span className={cx('tds-link', { 'tds-text--caption': market === 'CN' })}>
                      {requestCallbackCopy.buttonPart}
                    </span>
                  </ModalTrigger>
                  <span>{requestCallbackCopy.lastPart}</span>
                </span>
              </p>
            </When>
          </Choose>
        </div>
      </If>
      <If condition={showBusinessInformation}>
        <div className="tds--vertical_padding">
          <p className="tds-text--caption">
            {htmlToReact(
              i18n('SummaryPanel.disclaimers.businessInformation', null, null, {
                specificOverride: condition,
              })
            )}
          </p>
        </div>
      </If>
    </div>
  );
};

function mapStateToProps(state) {
  const {
    ApplicationFlow,
    ReviewDetails,
    App,
    Pricing,
    Configuration,
    FinancingOptions,
    Payment,
  } = state;
  const { canModifyOrder = false, isReservationToOrderFlow = false } = ApplicationFlow;
  const {
    isDeliverySelectionEnabled,
    isInventorySwapEnabled,
    isPostOrderSwap,
    isPaymentDisabled,
    isEnterpriseOrderPickup,
    enableCyberpunk,
    routes = {},
    showSinglePage,
  } = App;
  const {
    showDeliveryEstDisclaimer = false,
    isDeliveryDetailsValid,
    product = {},
    DeliveryLocations = {},
    DeliveryContact: { isDeliveryContactValid } = {},
    isSoldOutFlowEnabled = false,
    ignoreFieldsForDeliveryLocation,
    hasPublicInventory,
  } = ReviewDetails;
  const { isEnterpriseOrder, isLayoutMobile, isLayoutTablet, query_params } = App;
  const { enterpriseSearch = '', internalInventoryHost = '' } = routes;
  const { option_string: options, options_by_group } = Configuration;
  const { total, grossPrice } = Pricing || {};
  const isComboInventory = product?.isComboInventory || false;
  const { errorSalesMetro, registrationZipCode, addressLookupError } = DeliveryLocations;
  const { showIRABusinessEligibility } = FinancingOptions || {};

  const modelOption = getModelCode(state);
  const showLinkToInventoryLDData = _get(window.tesla, 'showLinkToInventory', {});
  let showLinkToInventory = false;
  if (!_isEmpty(showLinkToInventoryLDData)) {
    showLinkToInventory = showLinkToInventoryPerRegion(state, showLinkToInventoryLDData);
  }

  const locale = _get(state, 'App.locale');
  const market = _get(state, 'OMS.oms_params.market');
  const isDm = _get(state, 'App.isDm', false);
  const isConfiguratorInAppEnabled = _get(state, 'App.isConfiguratorInAppEnabled', false);
  const showBuyTips =
    Array.indexOf(['CN'], market) !== -1 && !canModifyOrder && isDm && !isConfiguratorInAppEnabled;
  const isReservedPreOrder = _get(state, 'ApplicationFlow.isReservedPreOrder', false);
  const isBookedPreOrder = _get(state, 'ApplicationFlow.isBookedPreOrder', false);
  const showNextButton = market === 'CN' && (isReservedPreOrder || isBookedPreOrder);
  const { range } = query_params || {};
  const isUsedInventory = _get(state, 'ReviewDetails.product.isUsedInventory', false);
  const isInventoryFederalTaxEligible = _get(
    product,
    'data.FederalIncentives.IsTaxIncentiveEligible',
    false
  );
  const showSoldOutFlow = errorSalesMetro && isSoldOutFlowEnabled;
  const ignorePostalCode = ignoreFieldsForDeliveryLocation?.includes(FIELD_ZIP_CODE);
  const { showPaymentOverview } = Payment || {};

  return {
    isComboInventory,
    isEditDesign: canModifyOrder,
    modelName: _get(state, `OMS.lexicon.options.${modelOption}.long_name`, ''),
    showLinkToInventory,
    showDeliveryEstDisclaimer,
    isEnterpriseOrder,
    isPostOrderSwap,
    isInventoryVehicle: isInventory(state),
    isDeliverySelectionEnabled,
    showSoldOutFlow,
    ignorePostalCode,
    errorSalesMetro,
    isLayoutMobile,
    isLayoutTablet,
    modelCode: getModel(state),
    isUsedInventory,
    isInventoryFederalTaxEligible,
    market: _get(state, 'OMS.oms_params.market'),
    condition: _get(state, 'ReviewDetails.product.condition', '') || AUTO_TYPE_CUSTOM,
    showBusinessInformation: _get(state, 'ReviewDetails.showBusinessInformation', false),
    useUsedInventoryDisclaimerCopy: _get(
      state,
      'ReviewDetails.useUsedInventoryDisclaimerCopy',
      false
    ),
    isGoToPaymentDisabled:
      isDeliverySelectionEnabled &&
      !isPostOrderSwap &&
      (!isDeliveryDetailsValid || !isDeliveryContactValid),
    showBuyTips,
    showNextButton,
    locale,
    gaTitleStatus: getGAAutoType(state),
    isInventorySwapEnabled,
    initialDesign: {
      options,
      price: total,
      grossPrice,
    },
    initialConfigByGroup: options_by_group,
    isPaymentDisabled,
    range,
    registrationZipCode,
    showIRABusinessEligibility,
    isEnterpriseOrderPickup,
    enterpriseSearch,
    isReservationToOrderFlow,
    isCentered: !(enableCyberpunk && isReservationToOrderFlow),
    showSinglePage,
    showPaymentOverview,
    hideCTA: !!(showSinglePage && showPaymentOverview),
    internalInventoryHost,
    hasPublicInventory,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    goToPayment: () => {
      dispatch(navigationSelectKey({ key: NAVIGATION_VIEW_PAYMENT }));
    },
    onCancelUpdateOrder: () => {
      dispatch(cancelUpdateOrder());
    },
    getEarlyDeliveryDesigns: () => {
      dispatch(getAlternateDesigns());
    },
    setCurrentDesign: props => {
      dispatch(setVehicleDesign(props));
    },
    navigateExternal: props => {
      dispatch(externalLinkClicked(props));
    },
    setPaymentFlag: flag => {
      dispatch(setPaymentOverviewFlag(flag));
    },
  };
}

PaymentOverviewTrigger.propTypes = {
  isComboInventory: bool.isRequired,
  isEditDesign: bool.isRequired,
  modelName: string.isRequired,
  goToPayment: func.isRequired,
  onCancelUpdateOrder: func.isRequired,
  classes: string,
  showLinkToInventory: bool,
  showVATBreakdown: bool,
  showDeliveryEstDisclaimer: bool,
  isEnterpriseOrder: bool,
  isEnterpriseOrderPickup: bool,
  enterpriseSearch: string,
  isPostOrderSwap: bool.isRequired,
  isInventoryVehicle: bool.isRequired,
  isDeliverySelectionEnabled: bool.isRequired,
  showSoldOutFlow: bool,
  ignorePostalCode: bool,
  isLayoutMobile: bool,
  isLayoutTablet: bool,
  modelCode: string,
  isUsedInventory: bool,
  isInventoryFederalTaxEligible: bool,
  market: string,
  showBusinessInformation: bool,
  condition: string,
  useUsedInventoryDisclaimerCopy: bool,
  isGoToPaymentDisabled: bool,
  showBuyTips: bool,
  showNextButton: bool,
  locale: string,
  gaTitleStatus: string,
  isInventorySwapEnabled: bool,
  getEarlyDeliveryDesigns: func.isRequired,
  setCurrentDesign: func.isRequired,
  initialDesign: shape({}),
  initialConfigByGroup: shape({}),
  isPaymentDisabled: bool,
  navigateExternal: func.isRequired,
  range: string,
  registrationZipCode: string,
  showIRABusinessEligibility: bool,
  showSinglePage: bool,
  hideCTA: bool,
  internalInventoryHost: string,
  hasPublicInventory: bool,
};

PaymentOverviewTrigger.defaultProps = {
  classes: '',
  showLinkToInventory: false,
  showDeliveryEstDisclaimer: false,
  showSoldOutFlow: false,
  ignorePostalCode: false,
  isEnterpriseOrder: false,
  isEnterpriseOrderPickup: false,
  enterpriseSearch: '',
  isLayoutMobile: false,
  isLayoutTablet: false,
  modelCode: '',
  isUsedInventory: false,
  isInventoryFederalTaxEligible: false,
  market: '',
  showBusinessInformation: false,
  condition: '',
  useUsedInventoryDisclaimerCopy: false,
  isGoToPaymentDisabled: false,
  showBuyTips: false,
  showNextButton: false,
  locale: '',
  gaTitleStatus: '',
  isInventorySwapEnabled: false,
  initialDesign: {},
  initialConfigByGroup: {},
  isPaymentDisabled: false,
  range: '',
  registrationZipCode: '',
  showIRABusinessEligibility: false,
  showSinglePage: false,
  hideCTA: false,
  hasPublicInventory: true,
  internalInventoryHost: '',
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentOverviewTrigger);
