import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { string, shape, bool, arrayOf, oneOfType, number } from 'prop-types';
import classnames from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import _isNumber from 'lodash/isNumber';
import _toNumber from 'lodash/toNumber';
import _toString from 'lodash/toString';
import _isObject from 'lodash/isObject';
import _get from 'lodash/get';
import { formatNumber } from '@tesla/coin-common-components';
import { useSpring, animated } from 'react-spring';
import { htmlToReact, i18n } from 'utils';
import { getSuperRegion } from '@tesla/intl-display-names';
import TextLoader from '../TextLoader';
import { UI_DATA_IDS } from '../../common/dictionary';
import ModalTrigger from '../ModalTrigger';

const Specs = ({
  group,
  isAnimationFloatEnabled,
  useEPAunits = false,
  selectedOption = {},
  isInventory = false,
  showRangeLinkInventory,
  showPreOrderBanner,
  isUsedInventory,
  rangeStandardCopy,
  showInventoryRangeDisclosure,
}) => {
  const { formattedSpecs: batterySpecs = {} } = selectedOption;

  if (_isEmpty(batterySpecs)) {
    return null;
  }

  // Need to add performance specs to metadata section in lexicon. By this, we can remove it from Battery and drive group.
  const {
    range,
    topspeed: top_speed,
    acceleration: zero_to_road_limit,
    range_units_override,
    rawData = {},
  } = batterySpecs;

  const { towingCapacity } = selectedOption?.lexicon_specs || {};
  const {
    range_units,
    range_units_epa,
    towing_capacity_short = '',
    towing_capacity_units,
    use_units_override = false,
    use_top_speed_override = false,
    range_units_inventory,
  } = rawData;

  const unitPostfix = use_units_override ? '_override' : '';
  const topSpeedUnitPostfix = use_units_override || use_top_speed_override ? '_override' : '';
  const rangeUnitsShortSource =
    rawData[`range_units_short${unitPostfix}`] ?? batterySpecs.range_units_short ?? '';
  const topSpeedUnitsShortSource =
    rawData[`top_speed_units_short${unitPostfix}`] ?? batterySpecs.top_speed_units_short ?? '';
  const zeroToRoadLimitUnitsSource =
    rawData[`zero_to_road_limit_units${unitPostfix}`] ??
    batterySpecs.zero_to_road_limit_units ??
    '';
  const speedUnitsShortSource =
    rawData[`speed_units_short${unitPostfix}`] ?? batterySpecs.speed_units_short ?? '';
  const topSpeedUnitsSource =
    rawData[`top_speed_units${topSpeedUnitPostfix}`] ?? batterySpecs.top_speed_units ?? '';
  const decimalPoints = _toString(zero_to_road_limit).split('.')[1]?.length || 1;
  let rangeUnitLabel = range_units_inventory || (useEPAunits ? range_units_epa : range_units) || '';

  const isAnimationFloatEnabledFor = (spec = 0) =>
    isAnimationFloatEnabled && !Number.isNaN(Number(spec));

  const getRangeInterpolated = () => {
    const { rangeInterpolated } = useSpring({
      rangeInterpolated: range,
      config: { precision: 1, round: 1 },
    });
    return rangeInterpolated;
  };

  const getTopSpeedInterpolated = () => {
    const { topSpeedInterpolated } = useSpring({
      topSpeedInterpolated: top_speed,
      config: { precision: 1, round: 1 },
    });
    return topSpeedInterpolated;
  };

  const getTowingInterpolated = () => {
    const { towingCapacityInterpolated } = useSpring({
      towingCapacityInterpolated: towingCapacity,
      config: { precision: 1, round: 1 },
    });
    return towingCapacityInterpolated;
  };

  // 0-60 is a little different as the spring returns "5" instead of "5.0"
  const [zeroToRoadLimitSpringValues, zeroToRoadLimitSpringAPI] = useSpring(() => ({
    zeroToRoadLimit: ((zero_to_road_limit * 10) / 10).toFixed(decimalPoints),
    config: { precision: 0.1, round: 0.1 },
    immediate: true,
  }));

  useEffect(() => {
    zeroToRoadLimitSpringAPI.start({
      to: {
        zeroToRoadLimit: ((zero_to_road_limit * 10) / 10).toFixed(decimalPoints),
      },
    });
  }, [zero_to_road_limit]);

  if (!_isEmpty(range_units_override) && !range_units_inventory) {
    rangeUnitLabel = range_units_override || rangeUnitLabel;
  }

  return (
    <div
      className={classnames('group-block specs-block tds--vertical_padding', {
        'option-widget--container': isInventory,
      })}
    >
      <If condition={showPreOrderBanner}>
        <div className="preorder-disclaimer tds--padding tds-text--center">
          {i18n('PreOrder.disclaimer', null, null, { returnNullWhenEmpty: true })}
        </div>
      </If>
      <ol
        className="tds-list tds-list--horizontal tds-o-list--box"
        aria-live="polite"
        aria-atomic="true"
        key="specs_list_item"
      >
        <If condition={range}>
          <li className="tds-list-item tds-o-list-item" key="specs_range">
            <div
              className="tds-text--h4 tds-list-item_title tds-text_color--black tds--no_padding"
              data-id={UI_DATA_IDS?.overviewSection?.range}
            >
              <Choose>
                <When condition={isAnimationFloatEnabledFor(range)}>
                  <animated.span>{getRangeInterpolated()}</animated.span>
                  {rangeUnitsShortSource && (
                    <span className="specs--value-label" key="specs_range_label">{rangeUnitsShortSource}</span>
                  )}
                </When>
                <Otherwise>
                  <span>{range}</span>
                  {rangeUnitsShortSource && (
                    <span className="specs--value-label" key="specs_range_label">{rangeUnitsShortSource}</span>
                  )}
                </Otherwise>
              </Choose>
            </div>
            {!_isObject(rangeUnitLabel) && rangeUnitLabel && (
              <span
                data-id={UI_DATA_IDS?.overviewSection?.rangeLabel}
                key="specs_range_unit_label"
              >
                {htmlToReact(rangeUnitLabel)}
              </span>
            )}
            {_isObject(rangeUnitLabel) && rangeUnitLabel.label && (
              <span
                className="cf-range-est--container"
                data-id={UI_DATA_IDS?.overviewSection?.rangeLabel}
                key="specs_range_unit_label"
              >
                <If condition={!rangeUnitLabel.postFix}>{htmlToReact(rangeUnitLabel.label)}</If>
                <div data-id="top-speed-disclaimer-link">
                  <TextLoader
                    data={batterySpecs}
                    tag={{ component: 'button' }}
                    field="range_units_override"
                    className={classnames('text-loader--range-info', {
                      'tds-link': !showRangeLinkInventory,
                    })}
                  />
                </div>
                <If condition={rangeUnitLabel.postFix}>{htmlToReact(rangeUnitLabel.label)}</If>
              </span>
            )}
          </li>
        </If>
        <If condition={top_speed}>
          <li className="tds-list-item tds-o-list-item" key="specs_topspeed">
            <div
              className="tds-text--h4 tds-list-item_title tds-text_color--black tds--no_padding"
              data-id={UI_DATA_IDS?.overviewSection?.topSpeed}
            >
              <Choose>
                <When condition={isAnimationFloatEnabledFor(top_speed)}>
                  <animated.span>{getTopSpeedInterpolated()}</animated.span>
                  {topSpeedUnitsShortSource && (
                    <span className="specs--value-label" key="specs_topspeed_label">{topSpeedUnitsShortSource}</span>
                  )}
                </When>
                <Otherwise>
                  <span>{top_speed}</span>
                  {topSpeedUnitsShortSource && (
                    <span className="specs--value-label" key="specs_topspeed_label">{topSpeedUnitsShortSource}</span>
                  )}
                </Otherwise>
              </Choose>
            </div>
            <Choose>
              <When condition={_isObject(topSpeedUnitsSource)}>
                <TextLoader data={topSpeedUnitsSource} />
                <If condition={topSpeedUnitsSource?.withUpgrade}>
                  <span>{i18n('common.with_paid_upgrade', null, null, { returnNullWhenEmpty: true })}</span>
                </If>
              </When>
              <Otherwise>
                <span
                  data-id={UI_DATA_IDS?.overviewSection?.topSpeedLabel}
                >
                  {topSpeedUnitsSource}
                </span>
              </Otherwise>
            </Choose>
          </li>
        </If>
        <If condition={towingCapacity}>
          <li className="tds-list-item tds-o-list-item" key="specs_towing_capacity">
            <div className="tds-text--h4 tds-list-item_title tds-text_color--black tds--no_padding">
              <Choose>
                <When condition={isAnimationFloatEnabledFor(towingCapacity)}>
                  <animated.span>{getTowingInterpolated()}</animated.span>
                  {towing_capacity_short && (
                    <span className="specs--value-label">{towing_capacity_short}</span>
                  )}
                </When>
                <Otherwise>
                  <span>{towingCapacity}</span>
                  {towing_capacity_short && (
                    <span className="specs--value-label">{towing_capacity_short}</span>
                  )}
                </Otherwise>
              </Choose>
            </div>
            <span>{towing_capacity_units}</span>
          </li>
        </If>
        <If condition={zero_to_road_limit}>
          <li className="tds-list-item tds-o-list-item" key="specs_zero_to_road_limit">
            <div
              className="tds-text--h4 tds-list-item_title tds-text_color--black tds--no_padding"
              data-id={UI_DATA_IDS?.overviewSection?.acceleration}
            >
              <Choose>
                <When
                  condition={
                    isAnimationFloatEnabledFor(zero_to_road_limit) && zero_to_road_limit % 1 !== 0
                  }
                >
                  <animated.span>
                    {/* Animate float, always show decimal to 10th */}
                    {zeroToRoadLimitSpringValues.zeroToRoadLimit.to(n =>
                      formatNumber(((n * 10) / 10).toFixed(decimalPoints), {
                        precision: decimalPoints,
                      })
                    )}
                  </animated.span>
                  {speedUnitsShortSource && (
                    <span className="specs--value-label" key="specs_zero_to_road_limit_label">{speedUnitsShortSource}</span>
                  )}
                </When>
                <Otherwise>
                  <span>{_isNumber(zero_to_road_limit)
                    ? formatNumber(((zero_to_road_limit * 10) / 10).toFixed(decimalPoints), {
                        precision: decimalPoints,
                      })
                    : zero_to_road_limit}</span>
                  {speedUnitsShortSource && (
                    <span className="specs--value-label" key="specs_zero_to_road_limit_label">{speedUnitsShortSource}</span>
                  )}
                </Otherwise>
              </Choose>
            </div>
            <TextLoader
              data={selectedOption}
              field="spec_acceleration_info"
              className="text-loader--spec-info"
            />
            <span
              data-id={UI_DATA_IDS?.overviewSection?.accelerationLabel}
            >
              {zeroToRoadLimitUnitsSource ? htmlToReact(zeroToRoadLimitUnitsSource) : ''}
            </span>
          </li>
        </If>
      </ol>
      <If condition={group?.battery_specs_disclaimer}>
        <div className="battery-specs--disclaimer">
          <TextLoader data={group?.battery_specs_disclaimer} />
        </div>
      </If>

      <If condition={isUsedInventory}>
        <div className="tds--vertical_padding">
          <p className=" tds-text--caption">
            {htmlToReact(
              i18n('Inventory.common.rangeDisclaimer', {
                RANGE_STANDARD: rangeStandardCopy,
              })
            )}
          </p>
        </div>
      </If>

      <If condition={showInventoryRangeDisclosure}>
        <div className="tds--vertical_padding">
          <p className="tds-text--center">
            <span className="tds-text--caption">{htmlToReact(i18n('Inventory.rangeDisclaimer.disclaimer'))}</span>
            <ModalTrigger
              options={{
                props: {
                  componentName: 'RangeModal',
                  props: {
                    genericWrapper: true,
                    size: 'MODAL_SMALL',
                    containerCss: 'tds-display--inline tds--horizontal_margin-5 tds-link',
                  },
                },
              }}
            >
              {i18n('Inventory.common.learnMore')}
            </ModalTrigger>
          </p>
        </div>
      </If>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  const {
    App: { isAnimationFloatEnabled, showRangeLinkInventory, isPaymentDisabled, isPreOrder } = {},
    CustomGroups = {},
    FindMyTesla = {},
    ReviewDetails = {},
  } = state;

  const { product, useFalconRangeLabel } = ReviewDetails;
  const { isInventory, isUsedInventory, data: ReviewDetailsData = {} } = product ?? {};

  const { OptionCodeData = [] } = ReviewDetailsData;

  const isRangeStandard = ReviewDetailsData.IsRangeStandard ?? false;
  const inventoryTrim = isInventory ? ReviewDetailsData.TrimCode ?? '' : '';
  const isTrimInLexicon =
    !isInventory || (CustomGroups.TRIM?.options || []).includes(inventoryTrim);
  let selectedOption = (CustomGroups.TRIM?.currentSelected || []).find(opt => opt.selected) ?? {};

  const showInventoryRangeDisclosure =
    _get(state, 'ReviewDetails.showInventoryRangeDisclosure') && inventoryTrim === '$MT315';
  let rangeStandardCopy = '';
  if (isUsedInventory || !isTrimInLexicon || (isInventory && !isRangeStandard)) {
    const { options = {} } = CustomGroups.battery_group_specs ?? {};
    let rangeSpecs = OptionCodeData?.find(spec => spec.group === 'SPECS_RANGE');
    const genericSpecs = Object.values(options).find(value => value.rawData);
    const isLegacy = _get(state, 'ReviewDetails.product.data.IsLegacy', false);
    const market = _get(state, 'OMS.oms_params.market');
    const isEMEA = getSuperRegion(market)?.code === 'EMEA';
    rangeSpecs = !_isEmpty(rangeSpecs) ? rangeSpecs : _get(state, 'ReviewDetails.rangeStandard', '');
    const rangeStandard =
      rangeSpecs?.range_source_inventory_new ||
      rangeSpecs?.range_source ||
      i18n(`Inventory.common.standard.${rangeSpecs}`);
    rangeStandardCopy = isLegacy && isEMEA ? i18n('Inventory.common.standard.NEDC') : rangeStandard;
    rangeStandardCopy = useFalconRangeLabel ? rangeSpecs?.range_label_source || rangeStandardCopy : rangeStandardCopy;
    if (genericSpecs) {
      const topspeed = OptionCodeData?.find(spec => spec.group === 'SPECS_TOP_SPEED')?.value;
      const acceleration = OptionCodeData?.find(spec => spec.group === 'SPECS_ACCELERATION')?.value;
      selectedOption = {
        ...selectedOption,
        formattedSpecs: {
          ...genericSpecs,
          ...(selectedOption.lexicon_specs ?? {}),
          range: _toNumber(rangeSpecs?.value),
          topspeed: _toNumber(topspeed),
          acceleration: _toNumber(acceleration),
        },
      };
    }

    // sets override for showing different range label
    if (!_isEmpty(selectedOption?.formattedSpecs?.rawData)) {
      const specOpt = options.inventoryTrim ?? {};

      let rangeLabel = isRangeStandard
        ? i18n('Inventory.common.rangeLabel', {
            RANGE_STANDARD: rangeSpecs?.range_source_inventory_new || rangeSpecs?.range_source,
          })
        : i18n('Inventory.common.rangeEstLabel');

      rangeLabel = useFalconRangeLabel ? rangeSpecs?.range_label_source || rangeLabel : rangeLabel;

      const rangeUnitsInventoryFallback =
        !isUsedInventory && isRangeStandard && !_isEmpty(specOpt) ? '' : rangeLabel;

      selectedOption.formattedSpecs = {
        ...selectedOption.formattedSpecs,
        range_units_override:
          selectedOption?.formattedSpecs?.range_units_override ??
          specOpt.range_units_override ??
          '',
        rawData: {
          ...selectedOption.formattedSpecs.rawData,
          range_units_inventory:
            _isEmpty(specOpt) || isUsedInventory ? rangeLabel : rangeUnitsInventoryFallback,
        },
      };
    }
  }

  return {
    group: CustomGroups[ownProps.group],
    isAnimationFloatEnabled,
    useEPAunits: FindMyTesla.EPAunits?.display,
    selectedOption,
    isInventory,
    showRangeLinkInventory,
    showPreOrderBanner: isPreOrder && !isPaymentDisabled,
    isUsedInventory,
    rangeStandardCopy,
    showInventoryRangeDisclosure,
  };
}

const selectedOptionShape = {
  formattedSpecs: shape({
    range_units_override: oneOfType([
      string,
      shape({
        label: string,
        content: string,
        behavior: arrayOf(shape({})),
      }),
    ]),
    range: oneOfType([string, number]),
  }),
};

Specs.propTypes = {
  group: shape({
    asset: shape({}),
    code: string,
    extra_content: arrayOf(shape({})),
  }),
  selectedOption: oneOfType([arrayOf(shape(selectedOptionShape)), shape(selectedOptionShape)]),
  isAnimationFloatEnabled: bool.isRequired,
  useEPAunits: bool,
  isInventory: bool,
  showRangeLinkInventory: bool,
  showPreOrderBanner: bool,
};

Specs.defaultProps = {
  group: {},
  selectedOption: [],
  useEPAunits: false,
  isInventory: false,
  showRangeLinkInventory: false,
  showPreOrderBanner: false,
};

export default connect(mapStateToProps)(Specs);
