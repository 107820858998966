import React, { useState, Fragment } from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import { arrayOf, shape, string, bool, func } from 'prop-types';
import { ProductCheckoutCard, ConfirmModal, formatCurrency } from '@tesla/coin-common-components';
import { i18n } from 'utils';
import { NAVIGATION_VIEW_OVERVIEW } from 'dictionary';
import {
  getTrimTotal,
  getEnterpriseLandingUri,
  getConfigurationOptions,
  getSelectedAccessoriesList,
  getAccessoriesAssetsBase,
  getSavedConfiguration,
  getModelName,
} from 'selectors';
import { toggleAccessoryItemWithShipping, navigationSelectKey } from 'actions';
import Analytics from 'analytics';
import TextLoader from '../../components/TextLoader';
import AssetLoader from '../../components/AssetLoader';

const ConfirmProductRemoveModal = props => {
  const container = document.querySelector('#enterprise-modal-root');
  return container ? createPortal(<ConfirmModal {...props} />, container) : null;
};

const OrderOverview = ({
  items,
  landingUri,
  isEnabled,
  productCardCaption,
  isInventory,
  accessories,
  accessoryAssetsBase,
  removeAccessoryItem,
  goToOverview,
  enableCyberpunk,
}) => {
  const [isRemoveItemModalOpen, setIsRemoveItemModalOpen] = useState(false);

  if (isEnabled) {
    const navigateToLanding = () => {
      Analytics.fireTagEvent({
        event: Analytics.event,
        interaction: 'remove-order',
        'cfg-type': Analytics.cfgType,
      });
      window.location = landingUri;
    };

    const navigateToOverviewPage = () => {
      document.body.classList.remove('has-top-banner--hidden');
      goToOverview();
      Analytics.fireTagEvent({
        event: Analytics.event,
        interaction: 'edit-design',
        'cfg-type': Analytics.cfgType,
      });
    };

    const onAccessoryRemove = partNumber => {
      removeAccessoryItem({ partNumber });
    };

    const AccessoriesList = () => {
      if (!accessories || !accessories.length) {
        return null;
      }
      return (
        <div className="coin--product-checkout-card-wrapper">
          <h5>{i18n('Enterprise.orderSummary.accessories')}</h5>
          {accessories.map(({ name, options }, index) => {
            const option = options[0] || {};
            const list = [
              {
                label: option?.size || '',
                value: '',
              },
            ];
            const total = formatCurrency(option?.purchasePrice?.amount || 0);
            return (
              <Fragment key={`accessory-card-${name}-${total}-${index}`}>
                <ProductCheckoutCard
                  total={total}
                  isDarkTheme={enableCyberpunk}
                  figure={
                    <AssetLoader
                      asset={{
                        url: `${accessoryAssetsBase}${option?.primaryImage}`,
                        type: 'image',
                        absolute: true,
                        width: 150,
                        height: 150,
                      }}
                    />
                  }
                  caption={
                    <button
                      type="button"
                      className="tds-link"
                      onClick={() => onAccessoryRemove(option?.partNumber)}
                    >
                      {i18n('common.remove')}
                    </button>
                  }
                  title={name}
                  type="compact"
                  lists={[list]}
                  highlighted={false}
                />
              </Fragment>
            );
          })}
        </div>
      );
    };

    return (
      <div>
        <div className="coin--review-order">
          {items && items.length
            ? items.map(
                ({ title, asset, baseConfiguration, options, total, incentives, baseOptions }) => {
                  const adaptedOptions = options.reduce((res, option) => {
                    if (!baseOptions?.includes(option?.code)) {
                      res.push({
                        label: (
                          <TextLoader
                            data={option}
                            field="long_name"
                            tag={{
                              component: 'span',
                              props: {},
                            }}
                          />
                        ),
                        value: <span>{option.formattedPrice} </span>,
                      });
                    }
                    return res;
                  }, []);
                  return (
                    <Fragment key={`card-${title}-${total}`}>
                      <ProductCheckoutCard
                        total={total}
                        isDarkTheme={enableCyberpunk}
                        figure={<AssetLoader asset={{ ...asset, size: 400 }} />}
                        caption={
                          <div className="coin--product-actions">
                            <button
                              type="button"
                              className="tds-link"
                              onClick={navigateToOverviewPage}
                            >
                              {i18n(productCardCaption)}
                            </button>
                            <button
                              type="button"
                              className="tds-link"
                              onClick={() => setIsRemoveItemModalOpen(true)}
                            >
                              {i18n('common.remove')}
                            </button>
                          </div>
                        }
                        title={title}
                        incentives={incentives}
                        lists={
                          baseConfiguration?.length
                            ? [[...baseConfiguration, ...adaptedOptions]]
                            : [adaptedOptions]
                        }
                        highlighted={false}
                      >
                        <AccessoriesList />
                      </ProductCheckoutCard>
                    </Fragment>
                  );
                }
              )
            : null}

          <ConfirmProductRemoveModal
            open={isRemoveItemModalOpen}
            title={i18n('Enterprise.remove_product')}
            body={i18n('Enterprise.confirm_remove_message')}
            buttonLabel={i18n('Enterprise.yes_remove')}
            onContinue={navigateToLanding}
            onClose={() => setIsRemoveItemModalOpen(false)}
          />
        </div>
      </div>
    );
  }
  return null;
};

OrderOverview.propTypes = {
  items: arrayOf(shape),
  landingUri: string.isRequired,
  isEnabled: bool,
  productCardCaption: string.isRequired,
  searchUri: string,
  isInventory: bool,
  accessoryAssetsBase: string,
  removeAccessoryItem: func.isRequired,
  accessories: arrayOf(shape),
  enableCyberpunk: bool,
};

OrderOverview.defaultProps = {
  items: [],
  isEnabled: true,
  searchUri: '',
  isInventory: false,
  accessoryAssetsBase: '',
  accessories: [],
  enableCyberpunk: false,
};

const mapStateToProps = state => {
  const { Pricing, OMS, ReviewDetails, App } = state;
  const { total: totalPrice } = Pricing;
  const { routes: { enterpriseSearch = '' } = {}, enableCyberpunk } = App;
  const { product: productData } = ReviewDetails;
  const { isInventory = false } = productData || {};
  const baseConfiguration = getTrimTotal(state);
  const options = getConfigurationOptions(state);
  const asset = state?.CustomGroups?.BATTERY_AND_DRIVE?.asset;
  const modelCode = OMS.oms_params.model;
  const productCardCaption = isInventory ? 'TradeIn.editOptions' : 'Enterprise.edit_design';
  const baseOptions = baseConfiguration?.map(x => x.code) || [];

  const product = {
    title: getModelName(state),
    options,
    asset,
    total: formatCurrency(totalPrice),
    baseConfiguration,
    baseOptions,
  };

  return {
    items: [product],
    landingUri: getEnterpriseLandingUri(state),
    isEnabled: !getSavedConfiguration(state),
    modelCode,
    productCardCaption,
    isInventory,
    searchUri: enterpriseSearch,
    accessories: getSelectedAccessoriesList(state),
    accessoryAssetsBase: getAccessoriesAssetsBase(state),
    enableCyberpunk,
  };
};

const mapDispatchToProps = dispatch => ({
  removeAccessoryItem: item => dispatch(toggleAccessoryItemWithShipping(item)),
  goToOverview: () =>
    dispatch(navigationSelectKey({ key: NAVIGATION_VIEW_OVERVIEW, isEditDesign: true })),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderOverview);
