/* eslint-disable no-param-reassign */
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import _upperFirst from 'lodash/upperFirst';
import { Loader, FormFieldset, Button, Link } from '@tesla/design-system-react';
import { Form, Input } from '@tesla/informed-tds';
import { utils } from 'informed';
import { i18n, zipCodeValidator } from 'utils';
import { getPostalCode, getRegionName, getEstimateDeliveryDate } from 'selectors';
import { PAYMENT_SUMMARY } from 'dictionary';
import { getEarlyAvailability, getGeoLocation, setDeliveryDetailsValidFlag } from 'actions';
import { bool, string } from 'prop-types';
import cx from 'classnames';
import Analytics from 'analytics';
import useIntersectionObserver from '../../../hooks/useIntersectionObserver';

const DeliveryZip = ({
  isEnabled,
  getAvailability,
  postalCode,
  onPostalChange,
  market,
  regionName,
  city,
  stateCode,
  options,
  isLoading,
  deliveryTiming,
  source = '',
  setZipValidFlag,
  isDeliveryDetailsValid,
  validateOnMount,
  error,
}) => {
  const [isVisible, ref] = useIntersectionObserver({
    threshold: 0,
  });
  const [showInput, setShowInput] = useState(!postalCode || !regionName);
  const formApiRef = useRef();
  const formState = formApiRef?.current?.getFormState();
  const { values, invalid, valid } = formState || {};
  const deliveryZip = values?.postalCode || '';

  useEffect(() => {
    if (isVisible && isEnabled) {
      if (postalCode && !error) {
        getAvailability();
      } else if (isDeliveryDetailsValid) {
        setZipValidFlag(false);
      }
    }

    if (valid && deliveryZip && deliveryZip !== postalCode) {
      formApiRef?.current?.reset();
    }
  }, [isVisible, options, postalCode]);

  if (!isEnabled) {
    return null;
  }

  const { debounce } = utils;

  const onChangeZip = ({ value, valid }) => {
    if (valid && value) {
      onPostalChange(value);
    }
    setZipValidFlag(valid);
  };

  const validateInvalidZip = value => {
    return new Promise(resolve => {
      setTimeout(() => {
        if (error && value === postalCode) {
          return resolve(i18n('TradeIn.error__postalCode'));
        }
        return resolve();
      }, 0);
    });
  };

  const PostalCode = ({ label, classes }) => {
    return (
      <Input
        name="postalCode"
        id="postalCode"
        maxLength={20}
        label={label}
        validate={value => zipCodeValidator(value, market)}
        asyncValidate={validateInvalidZip}
        required
        showErrorIfError
        validateOn="change"
        data-id="delivery-postal-code-textbox"
        placeholder={i18n('DeliveryLocation.enter_delivery_zip')}
        onChange={debounce(value => onChangeZip(value), 800)}
        keep={{ value: true, touched: true }}
        autocomplete="off"
        className={classes}
      />
    );
  };

  return (
    <div
      ref={ref}
      className={cx('observer-placeholder delivery-zip--container', {
        'tds-text--center': !source,
      })}
      id="early-delivery"
      key={`early-delivery:${source}`}
    >
      <Form
        formApiRef={formApiRef}
        allowEmptyStrings
        validateOnMount={validateOnMount}
        initialValues={{ postalCode }}
        errorMessage={{
          required: _upperFirst(i18n('common.errors__required')),
        }}
        className="tds-o-horizontail-margin--4x"
        keepState
      >
        <Choose>
          <When condition={source === PAYMENT_SUMMARY}>
            <FormFieldset className="tds--vertical_padding--large">
              <PostalCode
                label={i18n('DeliveryLocation.delivery_zip')}
                classes="tds-o-font-size--16"
              />
            </FormFieldset>
          </When>
          <Otherwise>
            <Choose>
              <When condition={showInput || !isDeliveryDetailsValid}>
                <p className="tds-o-padding_bottom-4 tds-text--h3 tds-text--contrast-high">
                  {i18n('DeliveryLocation.enter_delivery_zip')}
                </p>
                <div className="tds-o-flex--no-wrap region-selector--container tds-o-padding_bottom-4 tds-o-flex--inline">
                  <div className="tds-flex-item">
                    <PostalCode />
                  </div>
                  <div className="tds-flex-item tds-flex--col_1of3">
                    <Button
                      disabled={invalid || error}
                      onClick={() => {
                        Analytics.fireInteractionEvent('update-delivery-location-payment');
                        setTimeout(() => {
                          setShowInput(false);
                        }, 300);
                      }}
                    >
                      {i18n('common.update')}
                    </Button>
                  </div>
                </div>
              </When>
              <Otherwise>
                <Loader className="assets-loader" show={isLoading} />
                <If condition={!isLoading}>
                  <div className="tds-text--h3 tds-text--contrast-high">{deliveryTiming}</div>
                </If>
                <div className="tds-o-padding_bottom-4">
                  {i18n('DeliveryLocation.delivery_zip')}
                </div>
                <Link
                  onClick={() => {
                    Analytics.fireInteractionEvent('change-delivery-location-payment');
                    setShowInput(true);
                  }}
                >
                  <span className="tds-text--contrast-low">
                    {city ? `${city}, ${stateCode}, ${postalCode}` : `${stateCode} ${postalCode}`}
                  </span>
                </Link>
              </Otherwise>
            </Choose>
          </Otherwise>
        </Choose>
      </Form>
    </div>
  );
};

function mapStateToProps(state) {
  const {
    App,
    OMS,
    DeliveryTiming: { isAvailableNow, isLoading = false } = {},
    Configuration: { option_string } = {},
    ReviewDetails: {
      isDeliveryDetailsValid,
      DeliveryDetails: { error, city, stateCode } = {},
    } = {},
  } = state;
  const { market } = OMS?.oms_params || {};
  const zipCode = getPostalCode(state);

  return {
    isEnabled: App?.isEarlyAvailabilityEnabled,
    postalCode: !isDeliveryDetailsValid && !error ? '' : zipCode,
    market,
    regionName: getRegionName(state),
    city,
    stateCode,
    options: option_string,
    isLoading,
    deliveryTiming: isAvailableNow
      ? i18n('common.available_today')
      : i18n('common.available_later_date', {
          DATE: getEstimateDeliveryDate(state)?.deliveryWindowDisplay || '',
        }),
    isDeliveryDetailsValid,
    validateOnMount: !!(zipCode && !isDeliveryDetailsValid) || !!error,
    error,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getAvailability: () => dispatch(getEarlyAvailability()),
    onPostalChange: zipCode => dispatch(getGeoLocation(zipCode, { setPreferredAddress: true })),
    setZipValidFlag: flag => dispatch(setDeliveryDetailsValidFlag(flag)),
  };
}

DeliveryZip.propTypes = {
  isEnabled: bool,
  postalCode: string,
  market: string,
  regionName: string,
  validateOnMount: bool.isRequired,
};

DeliveryZip.defaultProps = {
  isEnabled: false,
  postalCode: '',
  market: '',
  regionName: '',
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryZip);
